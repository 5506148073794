module Dsoft {
    export module View {
        export function scrollWithJqueryAnim($container: JQuery, $target: JQuery, options: any = { duration: 'slow' }) {
            if ($container.length == 0 || $target.length == 0) return;
            $container.animate({
                scrollTop: $target.offset().top - $container.offset().top + $container.scrollTop()
            }, options.duration);
        }
        export function preventWindowScroll(w: Window) {
            var callback = $.proxy((originalWindow: Window, currentWindow: Window, ev: UIEvent) => {
                currentWindow.scrollTo(originalWindow.scrollX, originalWindow.scrollY);
            }, this, new Object({ scrollX: w.scrollX, scrollY: w.scrollY }), w);
            w.addEventListener('scroll', callback);
            //w.addEventListener('touchmove', callback);
            w['DsoftPreventWindowScrollCallback'] = callback;
        }
        export function allowWindowScroll(w: Window) {
            w.removeEventListener('scroll', w['DsoftPreventWindowScrollCallback']);
            //w.removeEventListener('touchmove', w['DsoftPreventWindowScrollCallback']);
        }
        export function changePageTitle(title: string, onlyIfNotEqualToCurrent: boolean = true) {
            var currentTitle = $(document).prop('title');
            if (currentTitle !== title) {
                $(document).prop('title', title);
            }
        }
        export function injectTemplate(into: string | HTMLElement, url: string, callback: () => void) {
            var $elem: JQuery = $(into);
            var loadedContent = $.ajax({
                url: url,
                async: false,
                type: "GET"
            }).responseText;
            $elem.append(loadedContent);
            callback();
        }
        export function getSelector(elemOr$elem: HTMLElement | JQuery) : string { 
            var elem: HTMLElement = <HTMLElement>((elemOr$elem instanceof HTMLElement) ? elemOr$elem : elemOr$elem.first());
            return new CssSelectorGenerator().getSelector(elem);
        }
    }
}
interface JQueryStatic {
    scrollWithJqueryAnim($container: JQuery, $target: JQuery): JQuery;
}
interface JQuery {
    getTransform(): {x: number, y: number, z: number, angle: number };
}

(function ($) {
    $.scrollWithJqueryAnim = function ($container: JQuery, $target: JQuery, options: any = { duration: 'slow' }): JQuery {
        Dsoft.View.scrollWithJqueryAnim($container, $target, options);
        return this;
    };
    $.fn.getTransform = function (): { x: number, y: number, z: number, angle: number } {
        var el = this[0];
        var transform = window.getComputedStyle(el, null).getPropertyValue('transform');

        function rotate_degree(matrix) {
            if (matrix !== 'none') {
                var values = matrix.split('(')[1].split(')')[0].split(',');
                var a = values[0];
                var b = values[1];
                var angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
            } else {
                var angle = 0;
            }
            return (angle < 0) ? angle += 360 : angle;
        }

        var results = transform.match(/matrix(?:(3d)\(-{0,1}\d+\.?\d*(?:, -{0,1}\d+\.?\d*)*(?:, (-{0,1}\d+\.?\d*))(?:, (-{0,1}\d+\.?\d*))(?:, (-{0,1}\d+\.?\d*)), -{0,1}\d+\.?\d*\)|\(-{0,1}\d+\.?\d*(?:, -{0,1}\d+\.?\d*)*(?:, (-{0,1}\d+\.?\d*))(?:, (-{0,1}\d+\.?\d*))\))/);

        var result = [0, 0, 0];
        if (results) {
            if (results[1] == '3d') {
                result = results.slice(2, 5).map((r,i,rs)=>parseInt(r));
            } else {
                results.push("0");
                result = results.slice(5, 9).map((r, i, rs) => parseInt(r)); // returns the [X,Y,Z,1] value;
            }

            result.push(rotate_degree(transform));
        };
        return { x: result[0], y: result[1], z: result[2], angle: result[3]};
    };
})(jQuery);