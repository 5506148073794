/// <reference path="typings/hammerjs/hammerjs.d.ts" />
module DistExtensions {
    export class PinchHammer {
        containgObject: { pinchHandler: Dsoft.Mobility.PinchHandler };
        context: any;
        public pinchHandler: Dsoft.Mobility.PinchHandler;

        constructor($container: JQuery, $subContainer: JQuery, context: any = {}) {
            this.context = context;
            var hammertime = new Hammer($container[0]);
            hammertime.get('pinch').set({ enable: true });
            hammertime.on('pinch', $.proxy(function (ev: HammerListener) {
                this.pinchHandler.onPinch(this.context, { data: { scale: <number>ev['scale'] } });
            }, this));
            this.pinchHandler = new Dsoft.Mobility.PinchHandler($subContainer, {});
        }
    }
}