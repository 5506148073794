module AlHaperek {
    export class ParshanViewmodel {
        public birthYear: ko.Observable<number>;
        public entryAsHTML: ko.Observable<string>;
        public hasPic: ko.Observable<boolean>;
        public hash: ko.Computed<number>;
        public href: ko.Computed<string>;
        public id: ko.Observable<string>;
        public imageName: ko.Computed<string>;
        public name: ko.Observable<string>;
        public safeUrlName: ko.Computed<string>;
        public perushimLoadedEventHandler: EventHandler;
        public perekDataSource: PerekDataSource_V2;
        public perushViewmodels: ko.ObservableArray<PerushViewmodel>;

        constructor(parshan? : Parshan) {
            this.init(parshan);
        }

        init(parshan?: Parshan) {
            if (typeof parshan !== 'undefined') {
                this.birthYear = ko.observable(parshan.birthYear);
                this.entryAsHTML = ko.observable(parshan.entryAsHTML);
                this.hasPic = ko.observable(parshan.hasPic);
                this.id = ko.observable(parshan.id);
                this.name = ko.observable(parshan.name);
            } else {
                this.birthYear = ko.observable(6000);
                this.entryAsHTML = ko.observable('');
                this.hasPic = ko.observable(false);
                this.id = ko.observable('');
                this.name = ko.observable('אין מידע זמין');
            }

            this.hash = ko.computed(() => {
                return Dsoft.StringHelper.hashCode(this.name());
            });
            this.href = ko.pureComputed(() => {
                return Conn.localPath + "parshanim/" + this.safeUrlName();
            });
            this.imageName = ko.computed(() => { return this.id() + '.png'; });
            this.safeUrlName = ko.computed(() => { return encodeURI(this.name()); });

            //const emptyPerushVM: PerushViewmodel = <PerushViewmodel>new Object(<PerushViewmodel>{
            //    $perushText: null,
            //    hebName: ko.observable(''),
            //    dataSource: null,
            //    html: ko.observable(''),
            //    id: ko.observable(-1),
            //    name: ko.observable(''),
            //    parshan: null,
            //    parshan2: null,
            //    perekViewModel: new PerekViewModel(false, -1), // causing a lot of troubles
            //    perushLoadedEventHandler: null
            //});

            this.perushViewmodels = ko.observableArray(new Array<PerushViewmodel>());

            this.perushimLoadedEventHandler = new EventHandler();

            this.perekDataSource = new PerekDataSource_V2();
        }
        loadPerushim() {
            var perushVMs: Array<PerushViewmodel> = [];
            var perushim = ParshanimData.parshanimPerushimSefarimPerakim[this.name()];
            for (var perushName of Object.keys(perushim)) {
                var perush = PerushimData.perushim.find(perush => perush.name == perushName);
                var sefarim = perushim[perushName];
                for (var seferTanahUsName of Object.keys(sefarim)) {
                    var additional = null;
                    var seferId;
                    var foundSeferId = false;
                    for (seferId = 1; seferId <= 35; seferId++) {
                        var seferTanahUsNameOrSeries: string | Array<string> = SefarimData.sefarim[seferId]['tanahUsName'];
                        if (typeof seferTanahUsNameOrSeries !== 'string') {
                            Object.keys(seferTanahUsNameOrSeries).forEach((additionalTmp: string, i: number, arr: string[]) => {
                                if (seferTanahUsNameOrSeries[additionalTmp] == seferTanahUsName) {
                                    additional = additionalTmp;
                                    foundSeferId = true;
                                }
                            });
                        } else if (seferTanahUsNameOrSeries == seferTanahUsName) {
                            foundSeferId = true;
                        }
                        if (foundSeferId) break;
                    }
                    var perakim: Array<number> = sefarim[seferTanahUsName];
                    for (var perek of perakim) {
                        //var perekVM = new PerekViewModel(true, this.perekDataSource.perekSourceToPerekId(seferId, additional, perek));
                        var perekVM = Parshanim.ResourcesPool.ViewmodelPool.perekViewModels[this.perekDataSource.perekSourceToPerekId(seferId, additional, perek)];
                        perushVMs.push(new PerushViewmodel(perekVM, <Perush>perush));
                    }
                }
            }
            this.perushViewmodels(perushVMs);
            this.perushimLoadedEventHandler.fire(new EventArgs(this, { data: perushVMs }));
        }
        public static mapper(value: Parshan, index: number, array: Parshan[]): ParshanViewmodel { return ParshanViewmodel.modelToViewmodel(value); }
        public static modelToViewmodel(model: Parshan): ParshanViewmodel {
            var vm: ParshanViewmodel = new ParshanViewmodel();
            if (typeof model !== 'undefined') {
                vm.birthYear(model.birthYear);
                vm.entryAsHTML(model.entryAsHTML);
                vm.hasPic(model.hasPic);
                vm.id(model.id);
                vm.name(model.name);
            }
            return vm;
        }
        public static modelsToViewmodels(models: Parshan[]): ParshanViewmodel[] {
            var vms: ParshanViewmodel[];
            if (typeof models !== 'undefined')
                vms = models.map(ParshanViewmodel.mapper);
            else
                vms = [];
            return vms;
        }
    }
}