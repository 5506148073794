module Tanahpedia {
    export module Entries {
        export class EntriesMetaData {
            animals: Array<Shared.NameAndValue>;
            artifacts: Array<Shared.NameAndValue>;
            events: Array<Shared.NameAndValue>;
            persons: Array<Shared.NameAndValue>;
            places: Array<Shared.NameAndValue>;
            plants: Array<Shared.NameAndValue>;
            prophecys: Array<Shared.NameAndValue>;
            wars: Array<Shared.NameAndValue>;
        }
    }
}
