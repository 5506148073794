/// <reference path="../../../view/base-view.ts" />
module App_V3 {
    export module AppAlHaperek {
        export class ArticleView extends BaseView { 
            public articleVM: ko.Observable<AlHaperek.ArticleViewmodel>;
            public get name(): string {
                return "article";
            }

            constructor() {
                super();
                this.articleVM = ko.observable(new AlHaperek.ArticleViewmodel());
            }
            protected getAnalyticsRootScreenName() {
                return '/929/article';
            }
            onPageAfterIn(pageData: Framework7.Router.Page) {
                if (!this.binded) { // no need to bind if came from another article or from next route (I.E: after routing to article author and coming back)
                    ko.applyBindings(ResourcesPool.ViewPool.articleView, $('#articlePage')[0]);
                    this.binded = true;
                }
                this.setAnalyticsScreen(this.articleVM().id().toString());
            }
            selectRabbi(rabbiVM: AlHaperek.RabbiViewmodel) {
                ResourcesPool.ViewPool.rabbiView.selectRabbi(rabbiVM, true);
            }
            selectArticle(articleVM: AlHaperek.ArticleViewmodel) {
                this.articleVM(articleVM);
            }
            onPageBeforeRemove(pageData: Framework7.Router.Page) {
                this.unbind();
                //ko.cleanNode($('#articlePage')[0]);
                //ResourcesPool.ViewPool.articleView.binded = false;
            }

        }
    }
}