module Tanahpedia {
    export module Entries {
        export module Person {
            export class LifePointViewmodel {
                public date: string;
                public html: string;
                public place: NameAndValueViewmodel;
                public subPlace: NameAndValueViewmodel;
                public title: string;
                public type: string;
                public year: number;

                public changedEventHandler: EventHandler;

                constructor(lifePoint: LifePoint) {
                    this.changedEventHandler = new EventHandler();
                    if (typeof lifePoint == 'undefined')
                        lifePoint = new LifePoint();

                    this.date = lifePoint.date;
                    this.html = lifePoint.html;
                    this.place = NameAndValueViewmodel.modelToViewmodel(lifePoint.place);
                    this.subPlace = NameAndValueViewmodel.modelToViewmodel(lifePoint.subPlace);
                    this.title = lifePoint.title;
                    this.type = lifePoint.type;
                    this.year = lifePoint.year;
                }

                public toModel() {
                    return LifePointViewmodel.viewmodelToModel(this);
                }

                public static reverseMapper(value: LifePointViewmodel, index: number, array: LifePointViewmodel[]): LifePoint { return LifePointViewmodel.viewmodelToModel(value); }
                public static viewmodelToModel(viewmodel: LifePointViewmodel): LifePoint {
                    var model = new LifePoint();

                    model.date = viewmodel.date;
                    model.html = viewmodel.html;
                    model.place = viewmodel.place.toModel();
                    model.subPlace = viewmodel.subPlace.toModel();
                    model.title = viewmodel.title;
                    model.type = viewmodel.type;
                    model.year = viewmodel.year;

                    return model;
                }
                public static viewmodelsToModels(models: LifePointViewmodel[]): LifePoint[] {
                    if (typeof models !== 'undefined')
                        return models.map(LifePointViewmodel.reverseMapper);
                    else
                        return [];
                }
                public static mapper(value: LifePoint, index: number, array: LifePoint[]): LifePointViewmodel { return LifePointViewmodel.modelToViewmodel(value); }
                public static modelToViewmodel(model: LifePoint): LifePointViewmodel {
                    var vm = new LifePointViewmodel(model);
                    return vm;
                }
                public static modelsToViewmodels(models: LifePoint[]): LifePointViewmodel[] {
                    var vms: LifePointViewmodel[];
                    if (typeof models !== 'undefined')
                        vms = models.map(LifePointViewmodel.mapper);
                    else
                        vms = [];
                    return vms;
                }
            }
        }
    }
}