module AlHaperek {
    export class Perek {
        public id: number;
        public perekId: number;
        public additional: number;
        public dayOfWeek: number;
        public header: string;
        public hebDate: string;
        public perek: number;
        public seferId: number;
        public seferName: string;
        public seferTanachUsName: string;


        constructor(id: number, perekId: number, additional: number, dayOfWeek: number, header : string, hebDate: string, perek: number, seferId: number, seferName: string, seferTanachUsName: string) {
            this.id = id;
            this.perekId = perekId;
            this.additional = additional;
            this.dayOfWeek = dayOfWeek;
            this.header = header;
            this.hebDate = hebDate;
            this.perek = perek;
            this.seferId = seferId;
            this.seferName = seferName;
            this.seferTanachUsName = seferTanachUsName;
        }
    }

}