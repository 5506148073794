module App_V3 {
    export class PreloaderView {
        public fontLoaded: ko.Observable<boolean>;
        public applicationLoaded: ko.Observable<boolean>;
        public windowLoaded: ko.Observable<boolean>;
        public isLoaded: ko.Computed<boolean>;
        constructor() {
            $(window).bind('load',() => {
                this.windowLoaded(true);
            });
            this.applicationLoaded = ko.observable(false);
            this.fontLoaded = ko.observable(false);
            this.windowLoaded = ko.observable(false);
            this.isLoaded = ko.pureComputed(() => {
                return this.applicationLoaded() && this.fontLoaded() && this.windowLoaded();
            }, this);
            ko.applyBindings(this, $('.preloader-back')[0]);
        }
    }
}