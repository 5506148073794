class LoadingViewmodel {
    private htmlContainerElem: HTMLElement;

    public loadingAnimatorCount: number;
    public loadingAnimatorRegisrationId: number;
    public loadingAnimatorText: ko.Observable<string>;
    public loadingFinished: ko.Observable<boolean>;

    constructor(container: HTMLElement) {
        this.htmlContainerElem = container;

        this.loadingFinished = ko.observable(false);
        this.loadingAnimatorCount = 4;
        this.loadingAnimatorText = ko.observable('טוען');
        this.loadingAnimatorRegisrationId = window.setInterval($.proxy(function () {
            ++this.loadingAnimatorCount;
            var dotsCount = (this.loadingAnimatorCount) % 4 + 1;
            var dots = new Array(dotsCount).join('.');
            var spaces = new Array(dotsCount).join('&nbsp;')
            this.loadingAnimatorText(spaces + 'טוען' + dots );
        }, this), 500);


        ko.applyBindings(this, container);
    }
    finishLoading() {
        this.loadingFinished(true);
        $(this.htmlContainerElem).fadeOut("slow");
    }
}