module Tanahpedia {
    export module Entries {
        export class DoublePageView {
            entryMetaDataViewmodel: EntryMetaDataViewmodel;
            entryViewmodel: EntryViewmodel;
            private $entryPageElem: JQuery;
            private $entryExtraPageElem: JQuery;

            public entryLoadedEventHandler: EventHandler;

            public entryPageView: EntryPageView;
            public entryExtraPageView: EntryExtraPageView;

            constructor(entryMetaDataViewmodel: EntryMetaDataViewmodel, $pageElems: { $entryPageElem: JQuery, $entryExtraPageElem: JQuery }) {
                this.init(entryMetaDataViewmodel, $pageElems);
            }
            init(entryMetaDataViewmodel: EntryMetaDataViewmodel, $pageElems: { $entryPageElem: JQuery, $entryExtraPageElem: JQuery }) {
                this.entryMetaDataViewmodel = entryMetaDataViewmodel;

                this.$entryPageElem = $pageElems.$entryPageElem;
                this.$entryExtraPageElem = $pageElems.$entryExtraPageElem;

                switch (entryMetaDataViewmodel.type) {
                    case 'person':
                        this.entryViewmodel = new Person.EntryViewmodel(entryMetaDataViewmodel.name, entryMetaDataViewmodel.value);
                        break;
                    case 'place':
                        //this.entryViewmodel = new Place.EntryViewmodel(...
                            break;
                }
                this.entryPageView = new EntryPageView(this.entryViewmodel, this.$entryPageElem);
                this.entryExtraPageView = new EntryExtraPageView(this.entryViewmodel, this.$entryExtraPageElem);
                
                this.entryLoadedEventHandler = new EventHandler();
            }
            load() {
                this.entryViewmodel.load();
                this.entryPageView.load();
            }
            entryLoaded(sender: any, e: { data: Entry }) {
                var perek = e.data;

                //ko.applyBindings(, this.$perekElem);


                this.entryLoadedEventHandler.fire(new EventArgs(this, { data: perek }));
            }
        }

    }
}