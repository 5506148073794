module AlHaperek {
    export class $ElemAndIdx {
        public $elem: JQuery;
        public idx: number;

        constructor($elem : JQuery, idx : number) {
            $elem = $elem;
            idx = idx;
        }
    }
}