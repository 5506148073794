module AlHaperek {
    export interface BookViewConf {
        articleId?: number,
        perekId?: number
    }
    export class BookView {
        private $book: JQuery;
        private $dynamicCssClass: JQuery;
        private $flipbookViewport: JQuery;
        private $originalBook: JQuery;
        public $toolBar: JQuery;

        private firstTime: boolean = true;
        private loadedPagesIds: Array<number> = [];
        private perekIdChangedPageIdx: boolean = false;
        private pageIdxChangedPerekId: boolean = false;
        private pageNeeded: boolean = true;
        private pageHeight: number;
        private pageWidth: number;

        public doublePagesViews: Array<DoublePageView>;
        private fullScreenFlag: ko.Observable<boolean>;
        public pageIdx: ko.Observable<number>;
        public perekId: ko.Observable<number>;
        public soundEnabled: ko.Observable<boolean>;
        public wasPagedToToc: ko.Observable<boolean>;

        public perekLoadedEventHandler: EventHandler;

        static $evenPageTemplate: JQuery;
        static $oddPageTemplate: JQuery;
        static firstPerekPageIdx = 6;
        static flipSound;
        static pagesCount: number = 1866; // front cover(2) + first page(1) + empty(1) + toc(1) +  perek * 929 X 2(1858) + empty(1) + back cover(2) = 1866
        static pageRatio: number = 4 / 5;
        static staticPagesIdxs: Array<number> = [1, 2, 3, 4, 5, BookView.pagesCount - 2, BookView.pagesCount - 1, BookView.pagesCount];
        static staticPages = new Array<JQuery>(BookView.pagesCount);
        static tocPageIdx = 5;
        static tocStr: string;
        public static generatePage = function (pairity: Pairity, idx: number): JQuery {
            return $('<div />', { 'class': 'page', 'id': 'page-' + idx, 'data-page-idx': idx }).html((pairity == Pairity.EVEN ? BookView.$evenPageTemplate : BookView.$oddPageTemplate).html());
        }
        public static ctor() {
            BookView.staticPages[5] = $('#tocPage');

            BookView.staticPages[AlHaperek.BookView.pagesCount - 2] = $('<div>').addClass('empty');
            BookView.staticPages[AlHaperek.BookView.pagesCount - 1] = $('<div>', { 'id': 'backCoverInside', 'class': 'hard' }).append($('<div>', { 'id': 'backCoverInsideEmptyZone' }));
            BookView.staticPages[AlHaperek.BookView.pagesCount] = $('<div>', { 'id': 'backCover', 'class': 'hard' });

            BookView.$evenPageTemplate = $('<div>').append($('.even-page-view'));
            BookView.$oddPageTemplate = $('<div>').append($('.odd-page-view'));

            BookView.flipSound = new Howl({
                src: ['../media/flip.mp3', '../media/flip.ogg', '../media/flip.wav']
            });
        }
        public static convertDummyPageToPage(dummyPageIdx: number): number {
            return (dummyPageIdx * 2) + BookView.firstPerekPageIdx - 1;
        }
        public static convertPageToDummyPage(pageIdx: number): number {
            return Math.max(0, Math.ceil((pageIdx - BookView.firstPerekPageIdx + 1) / 2));
        }
        constructor(perekId?: number, articleId?: number) {
            this.init(perekId, articleId);
        }
        init(perekId?: number, articleId?: number) {
            this.fullScreenFlag = ko.observable(false);
            this.soundEnabled = ko.observable(true);
            this.wasPagedToToc = ko.observable(false)
            this.pageIdx = ko.observable(0);
            this.pageIdx.subscribe((newValue: number) => {
                var pageIdx = Number(newValue);
                if (!this.perekIdChangedPageIdx) {
                    Promise.resolve((() => {
                        this.pageIdxChangedPerekId = true;
                        var perekId = BookView.convertPageToDummyPage(pageIdx)
                        this.perekId(perekId);
                    })()).then(() => this.pageIdxChangedPerekId = false);
                }
            });
            this.perekId = ko.observable(0);
            this.perekId.subscribe((newValue: number) => {
                var perekId = Number(newValue);

                if (!this.pageIdxChangedPerekId) {
                    Promise.resolve((() => {
                        this.perekIdChangedPageIdx = true;
                        var pageIdx = BookView.convertDummyPageToPage(perekId)
                        this.pageIdx(pageIdx);
                        if (this.perekId.caller != null) {
                            this.pageToPerek();
                        }
                    })()).then(() => this.pageIdxChangedPerekId = false);
                }
            });

            this.registerWindowEvents();
            this.doublePagesViews = [];

            this.$book = $('#book');
            this.$originalBook = this.$book.clone(true, true);
            this.$flipbookViewport = $('#flipbookViewport');
            this.$toolBar = $('#bookToolbar');
            ko.applyBindings(this, this.$toolBar[0]);

            var $showedTooltips: JQuery = this.$toolBar.find('[data-toggle="tooltip"][data-show="true"]');
            setTimeout(function ($showedTooltips: JQuery) {
                $showedTooltips.tooltip("enable");
                $showedTooltips.tooltip("hide");
            }, 2000, $showedTooltips);

            this.buildBook();

            Promise.resolve(this.perekId(perekId)).then(()=>this.pageToPerek());
        }
        buildBook() {
            this.calcBookDimensions();
            this.updateStyleSheets();
            this.$book.turn({
                acceleration: true,
                autoCenter: true,
                direction: "rtl",
                duration: 1000,
                elevation: 500,
                height: this.pageHeight,
                gradients: true,
                pages: BookView.pagesCount,
                width: this.pageWidth * 2,
                when: {
                    turning: this.onPageTurning.bind(this),
                    turned: this.onPageTurned.bind(this)
                }
            });
        }
        calcBookDimensions() {
            switch (Dsoft.Mobility.getScreenOrientation()) {
                case Dsoft.Mobility.Orientation.LANDSCAPE:
                    this.pageHeight = this.$flipbookViewport.height() - (this.$toolBar.position().top + this.$toolBar.height() + 6);
                    this.pageWidth = Math.min(this.pageHeight * BookView.pageRatio, this.$flipbookViewport.width() / 2 - 20);
                    break;
                case Dsoft.Mobility.Orientation.PORTRAIT:
                case Dsoft.Mobility.Orientation.UNKNOWN:
                default:
                    this.pageWidth = this.$flipbookViewport.width() / 2 - 20;
                    this.pageHeight = Math.min(this.pageWidth / BookView.pageRatio, this.$flipbookViewport.height() - (this.$toolBar.position().top + this.$toolBar.height() + 6));
                    break;
            }
        }
        closeBook() {
            this.$book.turn('page', 1);
        }
        pageBackward() {
            this.$book.turn('previous');
        }
        pageForward() {
            this.$book.turn('next');
        }
        pageToPerek(perekId : number = this.perekId()) {
            var pageIdx = BookView.convertDummyPageToPage(perekId);
            this.pageToPageIdx(pageIdx);
        }
        pageToPageIdx(pageIdx: number = Number(this.pageIdx())) {
            var range = this.$book.turn('range', pageIdx);

            for (var pageI = Math.max(1, range[0] - 1); pageI <= range[1] + 1; pageI++) {
                if (this.loadedPagesIds.indexOf(pageI) === -1) {
                    Dsoft.Debounce.debounceWithId($.proxy(this.refreshPage, this, pageI), 1500, pageI);
                    this.loadedPagesIds.push(pageI);
                }
            }

            this.$book.turn('page', pageIdx);
        }
        pageToToc() {this.wasPagedToToc(true);
            this.refreshPage(BookView.tocPageIdx);
            this.$book.turn('page', BookView.tocPageIdx);
        }
        refreshPage(pageIdx: number, hard: boolean = false) {
            //console.log("refreshing #" + pageIdx + '' + (hard ? ' hard' : ''));
            if (pageIdx > BookView.pagesCount) return;
            var $page;
            var perekId = BookView.convertPageToDummyPage(pageIdx);
            if ($.inArray(pageIdx, BookView.staticPagesIdxs) !== -1) {
                $page = BookView.staticPages[pageIdx];
                if (!this.$book.turn('hasPage', pageIdx)) {
                    //console.log("adding page#" + pageIdx);
                    this.$book.turn('addPage', $page, pageIdx);
                } else {
                    if (pageIdx == BookView.tocPageIdx)
                        this.wasPagedToToc(true);
                }
            } else if (typeof this.doublePagesViews[perekId] == 'undefined' || hard) {
                var doublePage: DoublePageView;
                var isEven = (pageIdx % 2 === 0);
                var isOdd = (pageIdx % 2 !== 0);
                var $evenPage: JQuery, $oddPage: JQuery;
                if (typeof this.doublePagesViews[perekId] == 'undefined') {
                    // TODO: treat edge situation such as $.inArray(pageIdx + 1, BookView.staticPagesIdxs) !== -1
                    $evenPage = BookView.generatePage(Pairity.EVEN, isEven ? pageIdx : pageIdx - 1);
                    $oddPage = BookView.generatePage(Pairity.ODD, isOdd ? pageIdx : pageIdx + 1);
                } else {
                    $evenPage = this.doublePagesViews[perekId].$perekPageElem;
                    $oddPage = this.doublePagesViews[perekId].$perekExtraPageElem;
                }
                var $pagesToAdd: JQuery[] = [$evenPage, $oddPage].sort(function ($a, $b) {
                    return Number($a.attr('data-page-idx')) - Number($b.attr('data-page-idx'));
                });
                $pagesToAdd.forEach($.proxy(function ($pageToAdd: JQuery, index: number, $array: JQuery[]) {
                    var pageIdxToAdd = Number($pageToAdd.attr('data-page-idx'));
                    if (!this.$book.turn('hasPage', pageIdxToAdd)) {
                        //console.log("adding page#" + pageIdxToAdd);
                        this.$book.turn('addPage', $pageToAdd, pageIdxToAdd);
                    }
                }, this));
                if (typeof this.doublePagesViews[perekId] == 'undefined') {
                    doublePage = new DoublePageView(perekId, { $perekPageElem: $evenPage, $perekExtraPageElem: $oddPage });
                    this.doublePagesViews[perekId] = doublePage;
                }
                this.doublePagesViews[perekId].load(perekId);
            }

        }
        refreshRangeByPageIdx(pageIdx: number, distance: { dPrev: number, dNext: number } = { dPrev: 5, dNext: 8 }) {
            if (this.firstTime === true) {
                this.firstTime = false;
                return;
            }
            // Gets the range of pages that the book needs right now
            var range = this.$book.turn('range', pageIdx);
            // loadPage
            for (var pageI = Math.max(1, range[0] - distance.dPrev); pageI <= range[1] + distance.dNext; pageI++) {
                if (pageI !== pageIdx && this.loadedPagesIds.indexOf(pageI) === -1) {
                    Dsoft.Debounce.debounceWithId($.proxy(this.refreshPage, this, pageI), 1500, pageI);
                    this.loadedPagesIds.push(pageI);
                }
                else {
                    Dsoft.Debounce.debounceWithId($.proxy(this.refreshPage, this, pageIdx, true), 500, pageIdx);
                }
            }
        }
        registerWindowEvents() {
            $(window).on('keydown', $.proxy(function (e: JQueryKeyEventObject) {
                if (e.target && e.target.tagName.toLowerCase() != 'input')
                    if (e.which == 39 && !Dsoft.GeneralHelper.isSpecialKeyAlsoPressed(e))
                        this.$book.turn('previous');
                    else if (e.which == 37 && !Dsoft.GeneralHelper.isSpecialKeyAlsoPressed(e))
                        this.$book.turn('next');
            }, this));
            $(window).on('orientationchange resize', Dsoft.debounce($.proxy(function (e) {
                this.calcBookDimensions();
                this.updateStyleSheets();
                this.$book.turn('size', this.pageWidth * 2, this.pageHeight);
            }, this), 150));
        }
        toggleFullScreen() {
            Dsoft.Fullscreen.toggleFullscreen(document, this.$flipbookViewport[0]).then((isInFullScreen) => {
                this.fullScreenFlag(isInFullScreen);
                if (isInFullScreen) {
                    $('.enlarge-btn').addClass('disabled');
                } else {
                    $('.enlarge-btn').removeClass('disabled');
                }
            });
        }
        updateStyleSheets() {
            if (typeof this.$dynamicCssClass !== 'undefined') {
                this.$dynamicCssClass.remove();
            }
            this.$dynamicCssClass = $('<style>').text(
                "#book{width:" + this.pageWidth * 2 + "px; height:" + this.pageHeight + "px; left: -" + this.pageWidth + "px; bottom: " + (this.pageHeight) + "px; }"
                + ".page{width:" + this.pageWidth + "px;height:" + this.pageHeight + "px;}"
            );
            $(document.body).append(this.$dynamicCssClass);
        }

        onPageTurning(e, pageIdx, view) {
            if (this.soundEnabled()) {
                BookView.flipSound.play();
            }

            var perekId = BookView.convertPageToDummyPage(pageIdx);
            var callback: () => Promise<any> = () => {
                return Promise.resolve(this.perekId(perekId)).then(() => {
                    if (!ResourcesPool_V2.ViewPool.indexView.syncView.hidden) {
                        ResourcesPool_V2.ViewPool.indexView.syncView.hide();
                    }
                    this.pageToPerek();
                })
            };
            ResourcesPool_V2.ViewPool.indexView.addressLineMgr.changeState(new AddressLineState(perekId, undefined, undefined, callback, true));
            this.perekId(perekId);

            switch (pageIdx) {
                case 4:
                case 5:
                    var $a: JQuery = BookView.staticPages[5].find('ul li dl dt a');
                    $a.unbind('click');
                    $a.bindToIntuitiveClick($.proxy(function (e: JQueryEventObject) {
                        e.preventDefault();
                        var dummyPageIdx: number = Number($(e.target).parent().next().html());
                        var pageIdx = BookView.convertDummyPageToPage(dummyPageIdx);
                        // Gets the range of pages that the book needs right now
                        var range = this.$book.turn('range', pageIdx);
                        // loadPage
                        for (var page = Math.max(1, range[0] - 5); page <= range[1] + 8; page++) {
                            this.refreshPage(page);
                        }
                        this.$book.turn('page', pageIdx);
                        return false;
                    }, this));
                    break;
                default:
            }

            $('.perek-recording-player').each((i, elem: HTMLAudioElement) => { elem.pause(); });
        }
        onPageTurned(e, pageIdx) {
            this.refreshRangeByPageIdx(pageIdx);
        }
    }
}