module App_V3 {
    export module AppAlHaperek {
        export class ArticlesGroupedByAuthorViewmodel {
            public author: ko.Observable<AlHaperek.RabbiViewmodel>;
            public articleViewmodels: ko.ObservableArray<AlHaperek.ArticleViewmodel>;

            constructor(author: AlHaperek.RabbiViewmodel, articleViewmodels: AlHaperek.ArticleViewmodel[]) {
                this.author = ko.observable(author);
                this.articleViewmodels = ko.observableArray(articleViewmodels);
            }
        }
    }
}