/// <reference path="../../scripts/dsoft/dsoft.general-helper.ts" />
module AlHaperek {
    export class PerekViewModel {
        public additional: ko.Observable<number>;
        public additionalHeb: ko.Computed<string>;
        public dayOfWeek: ko.Observable<number>;
        public dedications: ko.ObservableArray<DedicationViewModel>;
        public header: ko.Observable<string>;
        public hebDate: ko.Observable<string>;
        //public href: ko.Computed<string>;
        public id: ko.Observable<number>;
        public loadedOnce: boolean;
        public loadingAnimatorCount: number;
        public loadingAnimatorRegistration: number;
        public hasRecording: ko.Computed<boolean>;
        public nextPerekId: ko.Computed<number>;
        public perek: ko.Observable<number>;
        public perekDate: ko.Computed<string>;
        public perekHeb: ko.Computed<string>;
        public perekId: ko.Observable<number>;
        public perekSource: ko.Computed<string>;
        public previousPerekId: ko.Computed<number>;
        public seferCategory: ko.Computed<string>;
        public seferId: ko.Observable<number>;
        public seferName: ko.Observable<string>;
        public seferTanachUsName: ko.Observable<string>;
        public seferTanachUsNamePlusAdditional: ko.Computed<string>;
        public selectedPasukViewModel: PasukViewModel;
        public loadedThens: Dsoft.GeneralHelper.Then<PerekViewModel>[] = [];
        public dataSource: PerekDataSource_V2;

        constructor(load?: boolean, perekId?: number) {
            this.init();
            if (typeof load !== 'undefined' && load) {
                if (typeof perekId !== 'undefined') {
                    this.loadByPerekId(perekId);
                } else {
                    this.loadByDateTime();
                }
            }
            this.perekDate = ko.computed({
                owner: this,
                read: () => $.proxy(function () {
                    if (typeof this.dayOfWeek() !== 'undefined') {
                        return 'יום ' + Dsoft.HebDateHelper.DayOfWeekAsNumberToDayName(this.dayOfWeek()) + ' ' + this.hebDate();
                    }
                }, this)()
            });

            this.perekSource = ko.computed({
                owner: this,
                read: () => { return this.seferName() + ' ' + (this.additional() != null ? Dsoft.GimatryHelper.toLetters(this.additional()) + ' ' : '') + Dsoft.GimatryHelper.toLetters(this.perek()); }
            });
        }

        init() {
            this.id = ko.observable(0);
            this.perekId = ko.observable(0);
            this.additional = ko.observable(0);
            this.dayOfWeek = ko.observable(0);
            this.dedications = ko.observableArray([]);
            this.header = ko.observable('');
            this.hebDate = ko.observable('');
            this.loadedOnce = false;
            this.loadingAnimatorCount = 1;
            this.perek = ko.observable(0);

            this.seferId = ko.observable(0);
            this.seferName = ko.observable('');
            this.seferTanachUsName = ko.observable('');

            this.selectedPasukViewModel = new PasukViewModel(this);

            this.additionalHeb = ko.pureComputed(() => {
                return Dsoft.GimatryHelper.toLetters(PerakimData.perakim.additional[this.perekId() - 1]);
            }, this);
            this.hasRecording = ko.pureComputed(() => {
                return $.inArray(this.perekId(), PerakimData.perakimWithRecordings) !== - 1;
            }, this);
            //this.href = ko.pureComputed(() => {
            //    return Conn.localPath + this.perekId();
            //});
            this.nextPerekId = ko.computed<number>(function () {
                return Math.min(929, this.perekId() + 1)
            }, this);
            this.perekHeb = ko.pureComputed(() => {
                return Dsoft.GimatryHelper.toLetters(PerakimData.perakim.perek[this.perekId() - 1]);
            }, this);
            this.previousPerekId = ko.computed<number>(function () {
                return Math.max(1, this.perekId() - 1);
            }, this);

            this.seferCategory = ko.computed({
                owner: this,
                read: () => $.proxy(function () {
                    var group = AlHaperek.SefarimData.sefarimGroups.find((seferGroup, i, seferGroups) => this.seferId() >= seferGroup.from && this.seferId() <= seferGroup.to);
                    if (typeof group !== 'undefined') {
                        return group.header;
                    }
                    return "";
                }, this)()
            });
            this.dataSource = new PerekDataSource_V2();
        }
        loadByDateTime(dateTime?: Date): Promise<PerekViewModel> {
            return new Promise<PerekViewModel>((resolve: (value?: PerekViewModel) => void, reject: (reason?: any) => void) => {
                dateTime = typeof dateTime !== 'undefined' ? dateTime : new Date();
                this.dataSource.loadPerekByDateTime(dateTime).then(this.perekLoaded.bind(this)).then((value: Perek) => {
                    resolve(this);
                })
            }).thens(this.loadedThens);
        }
        loadByPerekId(perekId: number): Promise<PerekViewModel> {
            return new Promise<PerekViewModel>((resolve: (value?: PerekViewModel) => void, reject: (reason?: any) => void) => {
                this.dataSource.loadPerekByPerekId(perekId).then(this.perekLoaded.bind(this)).then((value: Perek) => {
                    resolve(this);
                })
            }).thens(this.loadedThens);
        }
        loadBySource(seferId: number, additional: number, perek: number): Promise<PerekViewModel> {
            return new Promise<PerekViewModel>((resolve: (value?: PerekViewModel) => void, reject: (reason?: any) => void) => {
                this.dataSource.loadPerekBySource(seferId, additional, perek).then(this.perekLoaded.bind(this)).then((value: Perek) => {
                    resolve(this);
                })
            }).thens(this.loadedThens);
        }
        loadNext(): Promise<PerekViewModel> {
            return Dsoft.Debounce.debouncedPromise(this.loadByPerekId(this.nextPerekId()), 'perekViewModelLoadNext');
            //Dsoft.Debounce.debounceWithId($.proxy(() => { this.dataSource.loadPerekByPerekId(this.nextPerekId()); }, this), 100, 'perekViewModelLoadNext')
        }
        loadPrevious(): Promise<PerekViewModel> {
            return Dsoft.Debounce.debouncedPromise(this.loadByPerekId(this.previousPerekId()), 'perekViewModelLoadPrevious');
            //Dsoft.Debounce.debounceWithId($.proxy(() => { this.dataSource.loadPerekByPerekId(this.previousPerekId()); }, this), 100, 'perekViewModelLoadPrevious')
        }
        perekLoaded(perek: Perek) {
            this.id(perek.id);
            this.additional(perek.additional);
            this.dayOfWeek(perek.dayOfWeek);
            this.dedications(DedicationViewModel.modelsToViewModels(DedicationsDataSource.getDedicationsOfPerek(perek.perekId)));
            this.header(perek.header);
            this.hebDate(perek.hebDate);
            this.perekId(perek.perekId);
            this.perek(perek.perek);
            this.seferId(perek.seferId);
            this.seferName(perek.seferName);
            this.seferTanachUsName(perek.seferTanachUsName);
        }

        toModel(): Perek {
            return new Perek(this.id(), this.perekId(), this.additional(), this.dayOfWeek(), this.header(), this.hebDate(), this.perek(), this.seferId(), this.seferName(), this.seferTanachUsName());
        }
    }
}