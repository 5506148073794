module AlHaperek {
    export class ArticlesViewmodel {
        public articlesContent: Array<string> = [];
        public articleViewmodels: ko.ObservableArray<ArticleViewmodel>;
        public perekId: number;
        public perekViewmodel: PerekViewModel;
        public selectedArticleViewmodel: ko.Observable<ArticleViewmodel>;

        public dataSource: ArticlesDataSource;

        constructor(perekViewmodel?: PerekViewModel, articleViewModels?: ArticleViewmodel[], selectedArticleViewModel?: ArticleViewmodel) {
            this.init(perekViewmodel, articleViewModels, selectedArticleViewModel);
            if (typeof perekViewmodel !== 'undefined' && articleViewModels.length == 0) {
                this.load();
            }
        }
        init(perekViewmodel?: PerekViewModel, articleViewModels?: ArticleViewmodel[], selectedArticleViewModel?: ArticleViewmodel) {
            if (typeof perekViewmodel !== 'undefined') {
                this.perekViewmodel = perekViewmodel;
                this.perekId = this.perekViewmodel.perekId();
            }
            var articleViewModelsToFill = typeof articleViewModels == 'undefined' ? [] : articleViewModels;
            this.articleViewmodels = ko.observableArray(articleViewModelsToFill);

            // ko bugs
            if (typeof selectedArticleViewModel == 'undefined') {
                this.selectedArticleViewmodel = ko.observable(new ArticleViewmodel());
                this.selectedArticleViewmodel(null);
            } else {
                this.selectedArticleViewmodel = ko.observable(selectedArticleViewModel);
            }


            this.dataSource = new ArticlesDataSource();
        }
        load(perekId?: number): Promise<ko.ObservableArray<ArticleViewmodel>> {
            return new Promise<ko.ObservableArray<ArticleViewmodel>>((resolve: (value?: ko.ObservableArray<ArticleViewmodel>) => void, reject: (reason? :any) => void) => {
                if (typeof perekId !== 'undefined') {
                    this.perekId = perekId;
                } else {
                    this.perekId = this.perekViewmodel.perekId();
                }
                var loadArticlesPromise = this.dataSource.loadArticlesByPerekId(this.perekId).then(this.articlesLoaded.bind(this));
                var loadArticlesContentPromise = this.dataSource.loadArticlesContent(this.perekId).then(this.articlesContentLoaded.bind(this));
                var allLoadPromises = Promise.all([loadArticlesPromise, loadArticlesContentPromise]);
                allLoadPromises.then(() => {
                    this.injectArticlesContent();
                    resolve(this.articleViewmodels);
                });
            });
        }
        articlesContentLoaded(articlesContent: Array<string>): void {
            this.articlesContent = articlesContent;
          
        }
        articlesLoaded(articles: Array<Article>): void {
            this.articleViewmodels.removeAll();
            articles.forEach((value: Article, index: number, array: Array<Article>) => {
                var articleVM = new ArticleViewmodel(value, this.perekId);
                this.articleViewmodels.push(articleVM);
            });
        }
        injectArticlesContent() {
            this.articleViewmodels().forEach((articleVM: ArticleViewmodel, i: number, arr: ArticleViewmodel[]) => {
                articleVM.fillContent(this.articlesContent[articleVM.id()]);
            }, this);
        }
    }
}