module Tanahpedia {
    export class EntryDataSource {
        entryExtendedLoadedEventHandler: EventHandler;
        entryExtendedSavedEventHandler: EventHandler;
        entryLoadedEventHandler: EventHandler;
        entrySavedEventHandler: EventHandler;

        constructor() {
            this.entryExtendedLoadedEventHandler = new EventHandler();
            this.entryExtendedSavedEventHandler = new EventHandler();
            this.entryLoadedEventHandler = new EventHandler();
            this.entrySavedEventHandler = new EventHandler();
        }

        loadEntry(name: string, type: string) {
            //console.log("loading entry: " + name);
            var doneCallback = $.proxy(function (data) {
                if (data.success) {
                    this.entryLoadedEventHandler.fire(new EventArgs(this, { data: data.message }));
                }
            }, this);
            var checkFail = $.proxy(
                function (jqXHR, textStatus, errorThrown) {
                    try {
                        var result = JSON.parse(jqXHR.responseText.substring(jqXHR.responseText.indexOf('{')));
                        this.doneCallback(result);
                    } catch (ex) { }
                }
                , { context: this, doneCallback: doneCallback });
            $.ajax({
                type: 'GET', url: Conn.remoteEntriesPath + 'get_entry.php', data: { 'name': name, 'type': type }, dataType: 'json'
            }).done(doneCallback).fail(checkFail);
        }
        loadEntryExtended(name: string, type: string) {
            var doneCallback = $.proxy(function (data) {
                this.entryExtendedLoadedEventHandler.fire(new EventArgs(this, { data: data }));

            }, this);
            var failCallback = $.proxy(
                function () {
                    this.entryExtendedLoadedEventHandler.fire(new EventArgs(this, { data: "" }));
                }
                , this);
            $.ajax({
                type: 'GET', url: Conn.remoteEntriesPath + type + 's' + '/' + Conn.factoryHebToEncodedUrl(name) + '.html'
            }).done(doneCallback).fail(failCallback);
        }
    }
}