module Dsoft {
    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    // https://davidwalsh.name/javascript-debounce-function
    export function debounce(func, wait, immediate?) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };
    //export function debounceWithId(func, wait, id, immediate?) {
    //    var timeouts = {};
    //    return function () {
    //        var context = this, args = arguments;
    //        var later = function () {
    //            timeouts[id] = null;
    //            if (!immediate) func.apply(context, args);
    //        };
    //        var callNow = immediate && !timeouts[id];
    //        clearTimeout(timeouts[id]);
    //        timeouts[id] = setTimeout(later, wait);
    //        if (callNow) func.apply(context, args);
    //    };
    //};
    export module Debounce {
        export var ids = new Array<any>();
        export var promises = new Map<any, Promise<any>>();

        export function debounceWithId(func: any, killAfter: number, id: any) {
            if (ids.indexOf(id) === -1) {
                ids.push(id);
                setTimeout(function (id: number) { Debounce.ids.splice(Debounce.ids.indexOf(id), 1); }, killAfter, id);
                func();
            } else {
                return;
            }
        }
        export function debouncedPromise<T>(promise: Promise<T>, id: any): Promise<T> {
            var promiseToReturn: Promise<T>;
            if (!promises.has(id)) {
                promises.set(id, promise);
                promise.then((value: T) => {
                    promises.delete(id);
                });
                promiseToReturn = promise;
            } else {
                promiseToReturn = promises.get(id);
            }
            return promiseToReturn;
        }
    }
}