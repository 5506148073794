/// <reference path="perushim-viewmodel.ts" />
module AlHaperek {
    export class PerushimOfPasukViewModel extends PerushimViewmodel {
        pasukViewModel: PasukViewModel;
        constructor(perekViewModel?: PerekViewModel, pasukViewModel?: PasukViewModel) {
            super(perekViewModel);
            this.pasukViewModel = pasukViewModel;
        }
        progressiveLoad(perekViewModel?: PerekViewModel, pasukViewModel?: PasukViewModel): any {
            if (typeof (pasukViewModel) !== 'undefined') {
                this.pasukViewModel = pasukViewModel;
            }
            this.load(perekViewModel).then((perushViewModels: ko.ObservableArray<PerushViewmodel>) =>
                this.validatePrushVMs(perushViewModels()));
        }
        validatePrushVMs(perushViewModels: PerushViewmodel[]) {
            var extractedPerushimViewModels = perushViewModels;
            this.perushViewmodels([]);
            for (var perushVM of extractedPerushimViewModels) {
                if (perushVM.html() == "") {
                    perushVM.load().then((loadedPerushVM) => this.validatePerushVM(loadedPerushVM));
                } else {
                    Promise.resolve(this.validatePerushVM(perushVM));
                }
            }
        }
        validatePerushVM(perushViewModel: PerushViewmodel) {
            if ($.inArray(this.pasukViewModel.pasukNum, perushViewModel.pesukimNums) !== -1) {
                var oldPerushViewmodels = this.perushViewmodels();

                this.perushViewmodels(oldPerushViewmodels.concat([perushViewModel]));
            }
        }
    }
}
