module App_V3 {
    export class BaseView {
        public static mainPopoverOpenerSelector = '.page.page-current .popover-open.more-options-btn';

        public binded: boolean = false;
        public isFirstTimePageIn: boolean = true;
        public get $popover(): JQuery {
            return $('.page.page-current .popover[class*="more-options"]');
        }
        public underlying: Framework7.View.View;
        public underlyingParameters: Framework7.View.Parameters;

        public get menuButtonAction(): () => void {
            return () => this.showMainPopover();
        }
        public get name(): string {
            return "";
        }
        public get analyticsRootScreenName(): string {
            return '/' + this.name;
        }
        public get elem(): Element {
            if (typeof (this.underlying) !== 'undefined')
                return this.underlying.$el[0];
            else {
                return $('#' + this.name + 'Page')[0];
            }
        }

        constructor() {

        }

        protected setAnalyticsScreen(pathToConcat?: string) {
            var analyticsScreen: string = this.analyticsRootScreenName + (typeof pathToConcat !== 'undefined' ? '/' + pathToConcat : '');
            ResourcesPool.ViewPool.analytics.setAnalyticsScreen(analyticsScreen);
        }
        public getContext(): ko.BindingContext {
            return ko.contextFor(this.underlying.$el[0]);
        }
        public bind() {
            if (!this.binded) {
                ko.cleanNode(this.underlying.$el[0]);
                ko.applyBindings(this, this.underlying.$el[0]);
                this.binded = true;
            }
        }
        public create() {
            if (typeof this.underlyingParameters !== 'undefined' && typeof this.underlyingParameters['name'] !== 'undefined') {
                var viewName = this.underlyingParameters.name;
                this.underlying = App_V3.app.underlying.views.create('#' + viewName + 'View', this.underlyingParameters);
            }
        }
        public hasMainPopover(): boolean {
            return this.get$mainPopoverOpener().length > 0;
        }
        public get$mainPopoverOpener(): JQuery {
            ;
            var $mainPopoverOpener = $(BaseView.mainPopoverOpenerSelector);
            return $mainPopoverOpener;
        }
        public hidePopovers() {
            app.hidePopovers();
        }
        public showMainPopover() {
            var $mainPopoverOpener = this.get$mainPopoverOpener();
            if (!this.hasMainPopover()) { // nothing to open
                return;
            }
            // close app panel
            //if ($("#app>.panel-active").length) { // try to replace with a method rather a selector
            App_V3.app.underlying.panel.close();
            //}

            var popoverSelector = $mainPopoverOpener.attr('data-popover');
            App_V3.app.underlying.popover.open(popoverSelector, BaseView.mainPopoverOpenerSelector, true);
        }
        public unbind() {
            ko.cleanNode(this.elem);
            this.binded = false;
        }

        public onMainPopoverClose(popover: Framework7.Popover.Popover) {
            
        }

        public onPageAfterIn(pageData: Framework7.Router.Page) {
            if (this.$popover.length == 0) {
                return;
            }
            
            this.$popover.off("popover:close", this.onMainPopoverClose.bind(this))
            this.$popover.on("popover:close", this.onMainPopoverClose.bind(this))
        }
        public onPageBeforeRemove(pageData: Framework7.Router.Page) {
        }

    }

}