module AlHaperek {
    export class RabbiViewmodel {

        everLoaded: boolean;

        public id: ko.Observable<number>;
        public details: ko.Observable<string>;
        public name: ko.Observable<string>;
        public safeUrlName: ko.Computed<string>;

        public articleViewmodels: ko.ObservableArray<AlHaperek.ArticleViewmodel>;

        public dataSource: RabbiDataSource;
        public articlesDataSource: ArticlesDataSource;

        constructor(rabbiId?: number) {
            this.init(rabbiId);
            if (typeof rabbiId !== 'undefined') {
                this.load();
            }
        }
        init(rabbiId?: number) {
            if (typeof rabbiId !== 'undefined') {
                this.id = ko.observable(rabbiId);
            } else {
                this.id = ko.observable(-1);
            }
            this.everLoaded = false;
            this.details = ko.observable("אין מידע זמין");
            this.name = ko.observable("אין מידע זמין");
            this.safeUrlName = ko.computed(() => { return encodeURI(this.name()); });
            // { id: ko.observable(1), perekId: ko.observable(1) }
            // [new Object() as AlHaperek.ArticleViewmodel]
            const emptyArticleVM: AlHaperek.ArticleViewmodel = <AlHaperek.ArticleViewmodel>new Object(<AlHaperek.ArticleViewmodel>{
                id: ko.observable(-1),
                abstract: ko.observable('ריק'),
                additionalHeb: ko.pureComputed(() => ''),
                author: ko.observable(null),
                bookHeb: ko.pureComputed(() => ''),
                content: ko.observable(''),
                href: ko.pureComputed(() => ''),
                name: ko.observable(''),
                perekHeb: ko.pureComputed(() => ''),
                perekId: ko.observable(null),
                shortAbstract: ko.pureComputed(() => '')
            });
            this.articleViewmodels = ko.observableArray(new Array<AlHaperek.ArticleViewmodel>(emptyArticleVM));
            this.dataSource = new RabbiDataSource();
            this.articlesDataSource = new ArticlesDataSource();


        }
        load(rabbiId?: number) {
            if (typeof rabbiId != 'undefined') {
                this.id(rabbiId);
            }
            this.dataSource.loadRabbi(this.id()).then(this.process.bind(this));
        }
        loadArticles(): Promise<RabbiViewmodel> {
            return new Promise<RabbiViewmodel>((resolve: (value?: RabbiViewmodel | PromiseLike<RabbiViewmodel>) => void, reject: (reason?: any) => void) => {
                this.articlesDataSource.loadArticlesByRabbiId(this.id()).then(this.processArticles.bind(this)).then(() => {
                    resolve(this);
                });
            });
        }
        processArticles(articles: AlHaperek.Article[]) {
            this.articleViewmodels.destroyAll();
            this.articleViewmodels(AlHaperek.ArticleViewmodel.modelsToViewmodels(articles));
        }
        process(rabbi: Rabbi ) {
            this.details(rabbi.details);
            this.name(rabbi.name);

            this.everLoaded = true;
        }

        public static mapper(value: Rabbi, index: number, array: Rabbi[]): RabbiViewmodel { return RabbiViewmodel.modelToViewmodel(value); }
        public static modelToViewmodel(model: Rabbi): RabbiViewmodel {
            var vm: RabbiViewmodel = new RabbiViewmodel();
            if (typeof model !== 'undefined') {
                vm.details(model.details);
                vm.id(model.id);
                vm.name(model.name);
            }
            return vm;
        }
        public static modelsToViewmodels(models: Rabbi[]): RabbiViewmodel[] {
            var vms: RabbiViewmodel[];
            if (typeof models !== 'undefined')
                vms = models.map(RabbiViewmodel.mapper);
            else
                vms = [];
            return vms;
        }

    }
}