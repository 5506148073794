class Parshan {
    public birthYear: number;
    public entryAsHTML: string;
    public hasPic: boolean;
    public id: string;
    public name: string;

    constructor(birthYear: number, entryAsHTML: string, hasPic: boolean, id: string, name: string) {
        this.birthYear = birthYear;
        this.entryAsHTML = entryAsHTML;
        this.hasPic = hasPic;
        this.id = id;
        this.name = name;
    }
}