/// <reference path="model/dal/rabbis-data-source.ts" />
module AlHaperek {

    export module ResourcesPool_V2 {
        export var constructed: boolean;
        export function ctor(config: Config): void {
            ModelPool.ctor(config);
            ViewmodelPool.ctor(config);
            ViewPool.ctor(config);
        }
        export module ModelPool {
            //export var requestTimeoutMS: number = 12000;
            export var pathToData: string;
            export var rabbis: Rabbi[] = RabbisDataSource.rabbis;
            export var todaysPerekId: number;
            export function ctor(config: Config): void {
                switch (config) {
                    case Config.SITE_BIG:
                        pathToData = (Conn.isLocalDev() ? "/tanah-dev/v2/www" : "") + "/929/data";
                        break;
                    case Config.SITE_SMALL:
                        pathToData = (Conn.isLocalDev() ? "/tanah-dev/v2/www" : "") + "/929/data";
                        break;
                    case Config.BOOK_STANDALONE:
                        pathToData = (Conn.isLocalDev() ? "/tanah-dev/v2/www" : "") + "/929/data";
                        break;
                    case Config.APP_BIG:
                        pathToData = "../929/data";
                        break;
                    case Config.APP_SMALL:
                        pathToData = "../929/data";
                        break;
                }
            }
        }
        export module ViewmodelPool {
            export var todaysPerekId: ko.Observable<number>;
            export function ctor(config: Config): void {
                todaysPerekId = ko.observable(1);
            }
        }
        export module ViewPool {
            export var bookStandaloneView: BookStandalone.BookStandaloneView;
            export var indexView: ResponsiveIndexView;
            export var isPhone: boolean;

            export function ctor(config: Config): void {
                switch (config) {
                    case Config.SITE_BIG:
                        BookView.ctor();
                        var $tooltips: JQuery = $('[data-toggle="tooltip"]');
                        $tooltips.tooltip();
                        var $showedTooltips: JQuery = $tooltips.filter('[data-show="true"]');
                        $showedTooltips.tooltip("show");
                        $showedTooltips.tooltip("disable");
                        $showedTooltips.bind('mouseover', function () {
                            $(this).tooltip('enable');
                        });
                        break;
                    case Config.SITE_SMALL:
                        break;
                    case Config.APP_BIG:
                    case Config.APP_SMALL:
                        break;
                    case Config.BOOK_STANDALONE:
                        AlHaperek.BookStandalone.BookStandaloneView.ctor();
                        var $tooltips: JQuery = $('[data-toggle="tooltip"]');
                        $tooltips.tooltip();
                        var $showedTooltips: JQuery = $tooltips.filter('[data-show="true"]');
                        $showedTooltips.tooltip("show");
                        $showedTooltips.tooltip("disable");
                        $showedTooltips.bind('mouseover', function () {
                            $(this).tooltip('enable');
                        });
                        break;
                }
            }
        }
    }
}