module Tanahpedia {
    export module Entries {
        export class MetadataDataSource {
            metadataLoadedEventHandler: EventHandler;
            constructor() {
                this.metadataLoadedEventHandler = new EventHandler();
            }

            loadMetadataAsync() {
                var doneCallback = $.proxy(function (data) {
                    if (data.success) {
                        this.metadataLoadedEventHandler.fire(new EventArgs(this, { data: data.message }));
                    }
                }, this);
                var checkFail = $.proxy(
                    function (jqXHR, textStatus, errorThrown) {
                        try {
                            var result = JSON.parse(jqXHR.responseText.substring(jqXHR.responseText.indexOf('{')));
                            this.doneCallback(result);
                        } catch (ex) { }
                    }
                    , { context: this, doneCallback: doneCallback });
                $.ajax({
                    type: 'GET', url: Conn.remoteEntriesPath + 'get_metadata.php', dataType: 'json'
                }).done(doneCallback).fail(checkFail);
            }
        }
    }
}