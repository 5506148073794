module Tanahpedia {
    export module Entries {
        export class SyncView {
            hidden: boolean = false;
            $entryExtended: JQuery;
            $syncView: JQuery;
            $syncViewReadableSection: JQuery;

            hiddenEventHandler: EventHandler;

            entryViewmodel: EntryViewmodel;
            entryExtendedViewmodel: EntryExtendedViewmodel;

            public personView: Person.SyncPersonView;

            constructor(entryViewmodel: EntryViewmodel, entryExtendedViewmodel: EntryExtendedViewmodel) {
                this.init(entryViewmodel, entryExtendedViewmodel);
            }
            init(entryViewmodel: EntryViewmodel, entryExtendedViewmodel: EntryExtendedViewmodel) {
                if (ResourcesPool_V2.ViewPool.isPhone) {
                    $('#goToBookBtnContainer').detach();
                }

                this.entryViewmodel = entryViewmodel;
                this.entryExtendedViewmodel = entryExtendedViewmodel;
                this.entryExtendedViewmodel.entryExtendedLoadedEventHandler.register(this.entryExtendedLoaded, this);

                this.$syncView = $('#syncView');
                this.$syncViewReadableSection = this.$syncView.children().first();
                this.$entryExtended = this.$syncView.find('.entry-extended');

                this.attachEvents();

                this.hiddenEventHandler = new EventHandler();

                switch (this.entryViewmodel.type()) {
                    case Type.person:
                        this.personView = new Person.SyncPersonView(<Person.EntryViewmodel>this.entryViewmodel);
                        break;
                    default:
                }

                ko.applyBindings(this, this.$syncView[0]);
                this.attachAnchorsEvents();
            }

            attachEvents() {
                if (!ResourcesPool_V2.ViewPool.isPhone) {
                    this.$syncView.on('click', $.proxy(function (e) {
                        if (this.$syncView.is(e.target))
                            this.hide();
                    }, this));
                    $('#goToBookBtnContainer').bind("click", $.proxy(function (e: JQueryMouseEventObject) {
                        this.hide();
                    }, this));
                }
            }
            attachAnchorsEvents() {
                var $allAnchorsWithHref: JQuery = this.$entryExtended.find('a[href]:not([href=""])');
                var $footNotesAndFootRefs = this.$entryExtended.find('a[data-dest^="#r"], a[data-dest^="#fn"]');
                //var $internalLinks = $allAnchorsWithHref.filter('a[href^="./"]');
                var $internalLinks = $allAnchorsWithHref.filter('a:not([href*="/"])');
                console.log($internalLinks.length);
                $footNotesAndFootRefs.bindToIntuitiveClick($.proxy(function (e: JQueryMouseEventObject) {
                    e.preventDefault();
                    e.stopPropagation();
                    var $a = $(e.target);
                    Dsoft.View.scrollWithJqueryAnim(!ResourcesPool_V2.ViewPool.isPhone ? this.$syncViewReadableSection : this.$syncView, $($a.data('dest')));
                    return false;
                }, this));
                $internalLinks.bindToIntuitiveClick($.proxy(function (e: JQueryMouseEventObject) {
                    var $a = $(e.target);
                    var href = $a.attr('href');
                    //var possibleEntryName = href.substr(href.indexOf('./') + 2);
                    var possibleEntryName = href;
                    if (ResourcesPool_V2.ViewPool.indexView.entriesMetadataViewmodel.isMetaDataExist(possibleEntryName)) {
                        ResourcesPool_V2.ViewPool.indexView.load(possibleEntryName);
                    }
                }, this));
            }
            hide() {
                this.$syncViewReadableSection.slideUp(300);
                setTimeout($.proxy(function () { this.$syncView.fadeOut() }, this), 300);
                this.hiddenEventHandler.fire(new EventArgs(this, { data: null }));
                this.hidden = true;
            }
            show(withEffects: boolean = true) {
                if (withEffects) {
                    this.$syncView.fadeIn(300)
                    setTimeout($.proxy(function () {
                        this.$syncViewReadableSection.slideDown();
                    }, this), 300);
                } else {
                    this.$syncView.fadeIn(0);
                    this.$syncViewReadableSection.slideDown(0);
                }
                this.hidden = false;
            }

            entryExtendedLoaded(sender: any, e: { data: string }) {
                var html = e.data;
                //if (html !== '') {
                this.attachAnchorsEvents();
                //$("<hr>").insertBefore(this.$entryExtended.find("h2"));
            }
        }
    }
}