module Shared {
    export class NameAndValue {
        public name: string;
        public value: string;

        constructor(name = "", value = "") {
            this.name = name;
            this.value = value;
        }
    }
    export class NamesAndValues {
        public names: string[];
        public values: string[];

        constructor(names = [], values = []) {
            this.names = names;
            this.values = values;
        }
    }
}