module AlHaperek {
    export module BookStandalone {
        export class DoublePageView {
            private perekId: number;
            public $perekPageElem: JQuery;
            public $perekExtraPageElem: JQuery;

            public perekLoadedEventHandler: EventHandler;

            public perekViewmodel: PerekViewModel;

            public perekPageView: PerekPageView;
            public perekExtraPageView: PerekExtraPageView;

            constructor(perekId: number, $pageElems: { $perekPageElem: JQuery, $perekExtraPageElem: JQuery }) {
                this.init(perekId, $pageElems);
            }
            init(perekId: number, $pageElems: { $perekPageElem: JQuery, $perekExtraPageElem: JQuery }) {
                this.perekId = perekId;
                this.$perekPageElem = $pageElems.$perekPageElem;
                this.$perekExtraPageElem = $pageElems.$perekExtraPageElem;
                this.perekViewmodel = new PerekViewModel(false);
                this.perekPageView = new PerekPageView(this.perekViewmodel, perekId, this.$perekPageElem);
                this.perekExtraPageView = new PerekExtraPageView(this.perekViewmodel, perekId, this.$perekExtraPageElem);

                this.perekLoadedEventHandler = new EventHandler();
            }
            load(perekId?: number) {
                this.perekViewmodel.loadByPerekId(perekId);//.then(this.perekPageView.perekLoaded.bind(this.perekPageView), this.perekExtraPageView.perekLoaded.bind(this.perekExtraPageView));
            }
        }

    }
}