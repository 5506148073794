module AlHaperek {
    export module Parshanim {
        export class ParshanimViewmodel {

            parshanPerushimLoadedReg: number = -1;
            public parshanViewmodels: ko.ObservableArray<ParshanViewmodel>;
            public filteredParshanViewmodels: ko.ObservableArray<ParshanViewmodel>;
            public selectedParshanViewmodel: ko.Observable<ParshanViewmodel>;

            public parshanPerushimLoadedEventHandler: EventHandler;

            constructor(parshanim?: Parshan[]) {
                this.init(parshanim);
            }
            init(parshanim?: Parshan[]) {
                this.parshanViewmodels = ko.observableArray(ParshanViewmodel.modelsToViewmodels(parshanim));
                //this.parshanViewmodels().forEach((parshanVM: ParshanViewmodel, index: number, parshanVMs: ParshanViewmodel[]) => {
                //    parshanVM.perushimLoadedEventHandler.register(this.parshanPerushimLoaded, this);
                //});
                this.filteredParshanViewmodels = ko.observableArray(this.parshanViewmodels());
                this.selectedParshanViewmodel = ko.observable(null);
                this.selectedParshanViewmodel.subscribe((pVM: ParshanViewmodel) => {
                    this.parshanPerushimLoadedReg = pVM.perushimLoadedEventHandler.register(this.parshanPerushimLoaded, this);
                    pVM.loadPerushim();
                });

                this.parshanPerushimLoadedEventHandler = new EventHandler();
            }
            filterParshanim(parshan: Parshan[]) {
                this.filteredParshanViewmodels(this.parshanViewmodels().filter((parshanVM: ParshanViewmodel, parshanVMIndex: number, parshanVMs: ParshanViewmodel[]) => {
                    return parshan.some((parshan: Parshan, parshanIndex: number, parshanim: Parshan[]) => { return parshan.name == parshanVM.name(); });
                }));
            }
            parshanPerushimLoaded(sender: any, e: { data: AlHaperek.Article[] }) {
                this.selectedParshanViewmodel().perushimLoadedEventHandler.unregister(this.parshanPerushimLoadedReg);
                var parshanVM = sender as ParshanViewmodel;
                this.parshanPerushimLoadedEventHandler.fire(new EventArgs(this, { data: { parshanVM: parshanVM, articles: e.data } }));
            }
            selectParshanByName(name : string) : ParshanViewmodel {
                var maybeParshanVMArr : Array<ParshanViewmodel> = this.parshanViewmodels().filter((parshanVM: ParshanViewmodel, index: number, parshanVMs: ParshanViewmodel[]) => {
                    return name == parshanVM.name();
                }, this);
                this.selectedParshanViewmodel((maybeParshanVMArr.length > 0) ? maybeParshanVMArr[0] : null); 
                return this.selectedParshanViewmodel();
            }

            viewRendered() {
                console.log('viewRendered');
            }
        }
    }
}