/// <reference path="../../scripts/typings/framework7/framework7.d.ts" />
/// <reference path="../view/root-view.ts" />
module App_V3 {
    export module AppAlHaperek {
        export class AppPerekView extends RootView {
            private firstLoad: boolean;

            private perekSourceQualifier: PerekSourceQualifier;
            public perekViewmodel: AlHaperek.PerekViewModel;

            public perekTextView: AppPerekTextView;
            public perekExplainView: PerekExplainView;

            picker: Framework7.Picker.Picker;

            public get name(): string {
                return 'alHaperek';
            }

            public get analyticsRootScreenName(): string {
                return '/929';
            }

            constructor() {
                super();
                this.init();
            }
            init() {
                this.firstLoad = true;
                this.perekViewmodel = new AlHaperek.PerekViewModel();
                new AlHaperek.DedicationsDataSource().loadDedications().then((value: { dedications: Map<number, AlHaperek.Dedication>, perakimDedications: Map<number, number[]> }) => {
                    this.perekViewmodel.dedications(AlHaperek.DedicationViewModel.modelsToViewModels(AlHaperek.DedicationsDataSource.getDedicationsOfPerek(this.perekViewmodel.perekId())));
                });
                this.perekTextView = new AppPerekTextView(this.perekViewmodel);
                this.perekExplainView = new PerekExplainView(this.perekViewmodel);


                this.underlyingParameters = <Framework7.View.Parameters>{
                    name: this.name,
                    routes: [],
                    stackPages: true,
                    url: '/app_929/'
                };
            }
            initPicker() {
                this.perekSourceQualifier = new PerekSourceQualifier();
                var flatSource = this.perekSourceQualifier.getFlatSource(this.perekViewmodel.seferCategory(),
                    this.perekViewmodel.seferId().toString(),
                    this.perekViewmodel.additional() == null ? 'null' : this.perekViewmodel.additional().toString()
                );

                this.picker = App_V3.app.underlying.picker['create'](<Framework7.Picker.Parameters>{
                    inputEl: '#perekPicker',
                    rotateEffect: true,
                    on: {
                        close: (p: Framework7.Picker.Picker) => {
                            var values = p.value as any[];
                            this.loadBySource(parseInt(values[1]), values[2] == "null" || parseInt(values[2]) < 0 ? null : parseInt(values[2]), parseInt(values[3]));
                        },
                        open: (p: Framework7.Picker.Picker) => {
                            var values = p.value as any[];
                            if (values[2] == "null" || parseInt(values[2]) < 0) {
                                $(p.cols[2]['el']).css({ 'visibility': 'hidden' });
                            }
                        }
                    },
                    formatValue: function (values) {
                        return '';
                    },
                    cols: [
                        <Framework7.Picker.ColumnParameters>{
                            textAlign: 'right',
                            values: flatSource.groups.values,
                            displayValues: flatSource.groups.names,
                            onChange: (picker, groupValue, groupDisplayValue) => {
                                if (picker.cols[1].replaceValues) {
                                    var flatSource: FlatPerekSource = this.perekSourceQualifier.getFlatSource(groupDisplayValue);
                                    picker.cols[1].replaceValues(flatSource.sefarim.values, flatSource.sefarim.names);
                                    picker.cols[2].replaceValues(flatSource.additionals.values, flatSource.additionals.names);
                                    if (flatSource.additionals.values[0] == "null") {
                                        $(picker.cols[2]['el']).css({ 'visibility': 'hidden' });
                                    } else {
                                        $(picker.cols[2]['el']).css({ 'visibility': 'visible' });
                                    }
                                    picker.cols[3].replaceValues(flatSource.perakim.values, flatSource.perakim.names);
                                }
                            },
                            width: 160
                        },
                        <Framework7.Picker.ColumnParameters>{
                            displayValues: flatSource.sefarim.names,
                            
                            onChange: (picker, seferValue, seferDisplayValue) => {
                                if (picker.cols[2].replaceValues) {
                                    var flatSource = this.perekSourceQualifier.getFlatSource(picker.cols[0].displayValue, seferValue);
                                    picker.cols[2].replaceValues(flatSource.additionals.values, flatSource.additionals.names);
                                    if (flatSource.additionals.values[0] == "null") {
                                        $(picker.cols[2]['el']).css({ 'visibility': 'hidden' });
                                    } else {
                                        $(picker.cols[2]['el']).css({ 'visibility': 'visible' });
                                    }
                                    picker.cols[3].replaceValues(flatSource.perakim.values, flatSource.perakim.names);
                                }
                            },
                            textAlign: 'right',
                            values: flatSource.sefarim.values,
                            width: 160,
                        },
                        <Framework7.Picker.ColumnParameters>{
                            displayValues: flatSource.additionals.names,
                            onChange: (picker, additionalValue, additionalDisplayValue) => {
                                //        if (additional == "null" || parseInt(additional) < 0) return;
                                if (picker.cols[3].replaceValues) {
                                    var flatSource = this.perekSourceQualifier.getFlatSource(picker.cols[0].displayValue, String(picker.cols[1].value), additionalValue);
                                    picker.cols[3].replaceValues(flatSource.perakim.values, flatSource.perakim.names);
                                }
                            },
                            textAlign: 'right',
                            values: flatSource.additionals.values,
                            width: 50
                        },
                        <Framework7.Picker.ColumnParameters>{
                            displayValues: flatSource.perakim.names,
                            textAlign: 'right',
                            values: flatSource.perakim.values,
                            width: 80
                        }
                    ]
                });
            }
            bind() {
                super.bind();
                this.perekTextView.matchSelectors();
                ko.cleanNode($('.al-haperek-audio-player-sheet')[0]);
                ko.applyBindings(this, $('.al-haperek-audio-player-sheet')[0]);
            }
            loadPerekById(perekId?: number) {
                this.perekViewmodel.loadByPerekId(perekId).then(this.processPerek.bind(this));
            }
            loadPerekByDateTime() {
                this.perekViewmodel.loadByDateTime().then(this.processPerek.bind(this));
            }
            loadPerekByLocalStorage(fallback = this.loadPerekByDateTime.bind(this)) {
                var lastLoadedPerekIdAsString = localStorage.getItem('lastLoadedPerekId');
                if (typeof (lastLoadedPerekIdAsString) === 'undefined' || lastLoadedPerekIdAsString == null || lastLoadedPerekIdAsString == "") {
                    fallback();
                    return;
                }
                var lastLoadedPerekId: number = parseInt(lastLoadedPerekIdAsString);
                this.loadPerekById(lastLoadedPerekId);
            }
            loadBySource(seferId: number, additional: number, perek: number) {
                this.perekViewmodel.loadBySource(seferId, additional, perek).then(this.processPerek.bind(this));
            }
            onPageAfterIn(pageData: Framework7.Router.Page) {
                if (pageData.direction == 'backward') {
                    this.setAnalyticsScreen(this.perekViewmodel.perekId().toString());
                } else {
                    this.bind();
                    switch (Number(ResourcesPool.ViewmodelPool.preferencesViewmodel.getProperty<AppPreferences.PerekToLoad>("perekToLoad").value())) {
                        case AppPreferences.PerekToLoad.todays:
                            this.loadPerekByDateTime();
                            break;
                        default:
                            this.loadPerekByLocalStorage();
                    }
                }
                ResourcesPool.ViewPool.preloaderView.applicationLoaded(true);
                // must be at end
                super.onPageAfterIn(pageData);

                this.perekTextView.matchSelectors()
                this.perekTextView.attachEvents();
            }

            openPicker() {
                this.picker.open();
            }

            processPerek(perekViewModel: AlHaperek.PerekViewModel) {
                if (this.firstLoad) {
                    this.firstLoad = false;
                    this.initPicker();
                    Dsoft.Framework7.onSwipeEdge($('#mainTabsView>div'),
                        {
                            callback: () => {
                                perekViewModel.loadNext().then(this.processPerek.bind(this));
                                app.underlying.tab.show('#perekTextTab', true);
                            }
                        },
                        {
                            callback: () => {
                                perekViewModel.loadPrevious().then(this.processPerek.bind(this));
                            }
                        }
                    );
                }
                var flatSource = this.perekSourceQualifier.getFlatSource(perekViewModel.seferCategory(),
                    perekViewModel.seferId().toString(),
                    perekViewModel.additional() == null ? 'null' : perekViewModel.additional().toString()
                );
                this.picker.setValue([flatSource.groups.names.findIndex((groupName, i, groupNames) => groupName == perekViewModel.seferCategory()),
                perekViewModel.seferId(),
                perekViewModel.additional() == null ? 'null' : perekViewModel.additional(),
                perekViewModel.perek()
                ], 1);
                localStorage.setItem('lastLoadedPerekId', perekViewModel.perekId().toString());
            }
        }
    }
}