module App_V3 {
    export module AppPreferences {
        export enum PerushEmbeddedKind {
            PerushOnly = 0,
            EmbeddedPerush = 1
        }
        export enum PerekToLoad {
            todays = 0,
            lastLearnt = 1
        }
        export class Property<T> {
            public value: T;
            public fireBaseProperty: string = "";

            constructor(value: T, fireBaseProperty = "") {
                this.value = value;
                this.fireBaseProperty = fireBaseProperty;
            }
        }
        export class Preferences {
            public dailyNotifications: Property<boolean> = new Property<boolean>(false, "dailyNotifications");
            public fitToAccessibility: Property<boolean> = new Property<boolean>(false);
            public fontSize: Property<number> = new Property<number>(16);
            public occasionalNotifications: Property<boolean> = new Property<boolean>(false, "occasionalNotifications");
            public perekToLoad: Property<PerekToLoad> = new Property<PerekToLoad>(PerekToLoad.lastLearnt);
            public perushEmbeddedKind: Property<PerushEmbeddedKind> = new Property<PerushEmbeddedKind>(PerushEmbeddedKind.PerushOnly);

            public getProperty<T>(name: string): Property<T> {
                return <Property<T>>this[name];
            }
        }
    }
}