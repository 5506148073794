module Tanahpedia {
    export module Entries {
        export module Person {
            export class LifeViewmodel {
                public length: number;
                public lifePoints: Array<LifePointViewmodel>;
                public selectedLifePoint: LifePointViewmodel;
                public changedEventHandler: EventHandler;

                constructor(personEntryLife: Life) {
                    this.changedEventHandler = new EventHandler();
                    if (typeof personEntryLife == 'undefined')
                        personEntryLife = new Life();

                    this.lifePoints = LifePointViewmodel.modelsToViewmodels(personEntryLife.lifePoints);
                    this.length = personEntryLife.length;

                    this.selectedLifePoint = new LifePointViewmodel(new LifePoint());
                }
                public toModel() {
                    return LifeViewmodel.viewmodelToModel(this);
                }
                public static viewmodelToModel(viewmodel: LifeViewmodel): Life {
                    var model = new Life();

                    model.length = viewmodel.length;
                    model.lifePoints = LifePointViewmodel.viewmodelsToModels(viewmodel.lifePoints);

                    return model;
                }

            }
        }
    }
}