module AlHaperek {
    export class ArticlesDataSource {
        static rabbisArticles: Map<number, Article[]> = new Map<number, Article[]>();
        static perekArticles: Map<number, Article[]> = new Map<number, Article[]>();
        static perekArticlesContent: Map<number, string[]> = new Map<number, string[]>();
        public static appendPerekArticles(perekId: number, articles: Article[]) {
            ArticlesDataSource.perekArticles.set(perekId, articles);
            ArticleDataSource.appendArticles(articles);
            setTimeout(() => { ArticlesDataSource.perekArticles.delete(perekId) }, 90000);
        }
        public static appendPerekArticlesContent(perekId: number, articlesContent: string[]) {
            ArticlesDataSource.perekArticlesContent.set(perekId, articlesContent);
            ArticleDataSource.appendArticlesContent(articlesContent);
        }
        public static appendRabbisArticles(rabbiId: number, articles: Article[]) {
            ArticlesDataSource.rabbisArticles.set(rabbiId, articles);
            ArticleDataSource.appendArticles(articles);
        }
        loadArticlesByPerekId(perekId: number): Promise<AlHaperek.Article[]> {
            if (perekId < 1 || perekId > 929) {
                return Promise.reject(new Error("Perek is out of range"));
            }
            if (ArticlesDataSource.perekArticles.has(perekId) && GlobalSettings.cacheEnabled) {
                return Promise.resolve(ArticlesDataSource.perekArticles.get(perekId));
            }

            var settings = { async: true, type: 'GET', url: Conn.remotePath + 'get_articles.php', data: { 'perekId': perekId }, dataType: 'json' };

            return Dsoft.Ajax.commonAjaxCall<AlHaperek.Article[], AlHaperek.Article[]>(settings, (message: AlHaperek.Article[]): AlHaperek.Article[] => {
                var articles: Article[] = Dsoft.GeneralHelper.keyedObjectToArray(message);
                ArticlesDataSource.appendPerekArticles(perekId, articles);
                return articles;
            });
        }
        loadArticlesByRabbiId(rabbiId: number): Promise<AlHaperek.Article[]> {
            if (ArticlesDataSource.rabbisArticles.has(rabbiId) && GlobalSettings.cacheEnabled) {
                return Promise.resolve(ArticlesDataSource.rabbisArticles.get(rabbiId));
            }

            var settings = { async: true, type: 'GET', url: Conn.remotePath + 'get_articles.php', data: { 'authorId': rabbiId }, dataType: 'json' };

            return Dsoft.Ajax.commonAjaxCall<Article[], Article[]>(settings, (message: Article[]) => {

                ArticlesDataSource.appendRabbisArticles(rabbiId, message.map((article) => {
                    article.authorId = rabbiId; return article;
                }));
                return message;
            });
        }
        loadArticlesContent(perekId: number): Promise<string[]> {
            if (perekId < 1 || perekId > 929) {
                return Promise.reject(new Error("Perek is out of range"));
            }
            if (ArticlesDataSource.perekArticlesContent.has(perekId) && GlobalSettings.cacheEnabled) {
                return Promise.resolve(ArticlesDataSource.perekArticlesContent.get(perekId));
            }

            var settings = { async: true, type: 'GET', url: Conn.remotePath + 'get_articles_content.php', data: { 'perekId': perekId }, dataType: 'json' };

            return Dsoft.Ajax.commonAjaxCall<string[], object>(settings, (message: object): string[] => {
                var articlesContent: string[] = Dsoft.GeneralHelper.keyedObjectToArray(message);
                ArticlesDataSource.appendPerekArticlesContent(perekId, articlesContent);
                return articlesContent;
            });
        }

    }
}