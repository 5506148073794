
module Tanahpedia {
    export module Entries {
        export class ImageMetadata {
            credit: string;
            height: number;
            license: string;
            licenseLink: string;
            link: string;
            name: string;
            title: string;
            width: number;
            constructor(credit = '', height = 800, link = '', license = '', licenseLink = '', name = '', title = '', width= 600) {
                this.credit = credit;
                this.height = height;
                this.license = license;
                this.licenseLink = licenseLink;
                this.link = link;
                this.name = name;
                this.title = title;
                this.width = width;
            }
        }
    }
}