module App_V3 {
    export module Application {
        export module Underlying {
            export const on = <{
                [event in keyof Framework7.Framework7Events]?: Framework7.Framework7Events[event]
            }>{
                    init: () => { },
                    pageAfterIn: (page: Framework7.Router.Page) => app.onPageAfterIn.bind(app)(page),
                    pageBeforeRemove: (page: Framework7.Router.Page) => app.onPageBeforeRemove.bind(app)(page),
                    pageBeforeOut: (page: Framework7.Router.Page) => app.onPageBeforeOut.bind(app)(page),
                    pageMounted: (page: Framework7.Router.Page) => app.onPageMounted.bind(app)(page)
                    //pageInit: void
                };
            export const panel = {
                swipe: 'right'
            };
            export const picker = <Framework7.Picker.Parameters>{
                toolbarCloseText: "סיים"
            }
            export const touch = {
                mdTouchRipple: false,
                tapHold: true         //enable tap hold events
            };
        }
    }
}