module AlHaperek {
    export module Rabbis {
        export class RabbiSearchViewmodel {
            private rabbis: Rabbi[];

            public searchExpression: ko.Observable<string>;
            
            public resultChangedEventHandler: EventHandler;
            constructor(rabbis: Rabbi[]) {
                this.init(rabbis);
            }
            expressionChanged(newExpr: string) {
                var result: Rabbi[];
                result = this.rabbis.filter((rabbi: Rabbi, index: number, rabbis: Rabbi[]) => {
                    const regexStr = '(?=.*' + newExpr.trim().split(/\s/).join(')(?=.*') + ')';
                    const searchRegEx = new RegExp(regexStr, 'gi');
                    return rabbi.name.replace('הרב','').replace('שליט"א','').replace('ז"ל','').match(searchRegEx) !== null;
                });

                this.resultChangedEventHandler.fire(new EventArgs(this, { data: result }));
            }
            init(rabbis: Rabbi[]) {
                this.rabbis = rabbis;
                this.searchExpression = ko.observable('');
                this.searchExpression.subscribe(this.expressionChanged, this);
                this.resultChangedEventHandler = new EventHandler();
            }

        }
    }
}