module Dsoft {
    export module AddressLine {
        export const SEPARATOR = "/";
        export abstract class AddressLineState {
            callback: () => Promise<any>;
            cyclic: boolean;
            guid: number;
            static counter: number = 0;
            constructor(callback?: () => Promise<any>, cyclic: boolean = false) {
                this.callback = callback;
                this.cyclic = cyclic;
                this.guid = AddressLineState.counter++;
            }

            abstract translateToRelativePath(): string;
        }
        export class AddressLineManager {
            currentState: AddressLineState;
            freezeMode: boolean; // === do not push any state.
            originalWindowLocation: string;
            states: AddressLineState[] = [];
            wait: boolean = false;
            constructor(originalWindowLocation: string, state?: AddressLineState) {
                this.originalWindowLocation = originalWindowLocation;

                if (typeof state !== 'undefined') {
                    this.changeState(state);
                }

                $(window).bind('popstate', this.onPopState.bind(this));
            }
            changeState(state: AddressLineState, replaceIfFirst : boolean = true, sendAnalytics : boolean = true) {
                if (this.freezeMode) return;
                if (state.cyclic && this.wait) {
                    //this.wait = false;
                    return;
                }
                var historyManipulationCallback: (data: any, title: string, url?: string | null) => void = ((replaceIfFirst && this.states.length == 0) ? window.history.replaceState : window.history.pushState).bind(window.history);
                this.states[state.guid] = state;
                historyManipulationCallback(state.guid, 'Title', this.originalWindowLocation + SEPARATOR + state.translateToRelativePath());
                if (sendAnalytics && typeof ga !== 'undefined') ga('send', 'pageview', location.pathname);
            }
            freeze() {
                this.freezeMode = true;
            }
            unfreeze() {
                this.freezeMode = false;
            }

            onPopState(e: JQueryEventObject) {
                if (typeof ga !== 'undefined') ga('send', 'pageview', location.pathname);
                var guid = e.originalEvent['state'];
                var state: AddressLineState = this.states[guid];
                if (state.cyclic)
                    this.wait = true;
                state.callback().then(() => {
                    this.wait = false;
                });
            }
        }
    }
}