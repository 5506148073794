/// <reference path="rabbis-data-source.ts" />
module AlHaperek {
    export class RabbiDataSource {
        loadRabbi(rabbiId: number): Promise<Rabbi> {
            if (typeof RabbisDataSource.rabbis[rabbiId] !== 'undefined') {
                return Promise.resolve(RabbisDataSource.rabbis[rabbiId]);
            }


            var settings = { async: true, type: 'GET', url: Conn.remotePath + 'get_rabbi.php', data: { 'rabbiId': rabbiId }, dataType: 'json' };
            return Dsoft.Ajax.commonAjaxCall<Rabbi, Rabbi>(settings, (message: Rabbi) => {
                RabbisDataSource.rabbis[rabbiId] = message;
                return Dsoft.Ajax.defaultProcessMsgCallback(message);
            }, { enabled: true, id: "RabbiDataSource.loadRabbi(" + rabbiId + ")" });
        }
    }
}