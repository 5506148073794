module Dsoft {
    export function keyByValue<T>(arr: any, value: T): any {
        for (var prop in arr) {
            if (arr.hasOwnProperty(prop)) {
                if (arr[prop] === value)
                    return prop;
            }
        }
        return "";
    }
    export class GimatryHelper {
        static lettersValues = { 'א': 1, 'ב': 2, 'ג': 3, 'ד': 4, 'ה': 5, 'ו': 6, 'ז': 7, 'ח': 8, 'ט': 9, 'י': 10, 'כ': 20, 'ל': 30, 'מ': 40, 'נ': 50, 'ס': 60, 'ע': 70, 'פ': 80, 'צ': 90, 'ק': 100, 'ר': 200, 'ש': 300, 'ת': 400, 'ך': 20, 'ם': 40, 'ן': 50, 'ף': 80, 'ץ': 90, '': 0 };
        static lettersValuesWithMantzpach = { 'א': 1, 'ב': 2, 'ג': 3, 'ד': 4, 'ה': 5, 'ו': 6, 'ז': 7, 'ח': 8, 'ט': 9, 'י': 10, 'כ': 20, 'ל': 30, 'מ': 40, 'נ': 50, 'ס': 60, 'ע': 70, 'פ': 80, 'צ': 90, 'ק': 100, 'ר': 200, 'ש': 300, 'ת': 400, 'ך': 500, 'ם': 600, 'ן': 700, 'ף': 800, 'ץ': 900, '': 0 };
        static lettersIndexes = { 'א': 1, 'ב': 2, 'ג': 3, 'ד': 4, 'ה': 5, 'ו': 6, 'ז': 7, 'ח': 8, 'ט': 9, 'י': 10, 'כ': 11, 'ל': 12, 'מ': 13, 'נ': 14, 'ס': 15, 'ע': 16, 'פ': 17, 'צ': 18, 'ק': 19, 'ר': 20, 'ש': 21, 'ת': 22 };


        public static isLetter(char): boolean {
            return char >= 'א' && char <= 'ת';
        }
        public static toNumberWithMantzpach(phrase: string): number {
            var sum = 0;
            var i = phrase.length;
            while (i--) {
                if (phrase[i] >= 'א' && phrase[i] <= 'ת') {
                    sum += GimatryHelper.lettersValuesWithMantzpach[phrase[i]];
                }
            }
            return sum;
        }
        
        public static toNumber(phrase: string): number {
            var sum = 0;
            var i = phrase.length;
            while (i--) {
                if (phrase[i] >= 'א' && phrase[i] <= 'ת') {
                    sum += GimatryHelper.lettersValues[phrase[i]];
                }
            }
            return sum;
        }
        public static toLetters(number: number, tousands :boolean = false, tousandsSepartor = '', addGershaim = false): string {
            var result = "";

            if (tousands && (number > 1000)) {
                var alafim = Math.floor(number / 1000) % 10;      // receive sifrat alafim
                number = number - alafim * 1000; // delete sifrat alafim from number
                result += Dsoft.keyByValue<number>(GimatryHelper.lettersValues, alafim); // add leading letter alef to tet into result.
                result += tousandsSepartor;
            }

            var ahadot = number % 10;
            var asarot = Math.floor((number / 10)) % 10;
            var rest = number - asarot * 10 - ahadot;
            var tafCount = 0;
            var sherit = 0;
            
            if (rest >= 100) {
                if (rest <= 400) {
                    result += Dsoft.keyByValue<number>(GimatryHelper.lettersValues, rest);
                }
                else {
                    tafCount = Math.floor(rest / 400);
                    var sherit = rest % 400;
                    for (var i = 0; i < tafCount; i++) {
                        result += "ת";
                    }
                    result += Dsoft.keyByValue<number>(GimatryHelper.lettersValues, sherit);
                }

            }

            result += Dsoft.keyByValue(GimatryHelper.lettersValues, asarot * 10);
            result += Dsoft.keyByValue(GimatryHelper.lettersValues, ahadot);

            return result.replace('יה', "טו").replace("יו", "טז");
        }
        public static indexToHebrewLetter(index: number): string {
            return Dsoft.keyByValue<number>(GimatryHelper.lettersIndexes, index);
        }
        public static hebrewLetterToIndex(letter: string): number {
            return GimatryHelper.lettersIndexes[letter];
        }

    }
}