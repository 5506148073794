module AlHaperek {
    export class PerekTextViewModel {
        public $perekText : JQuery;
        public textAsHtml: ko.Observable<string>;
        public perekViewModel: PerekViewModel;
        public dataSource: PerekTextDataSource_V2;

        constructor(perekViewModel: PerekViewModel) {
            this.init(perekViewModel);
        }
        init(perekViewModel: PerekViewModel) {
            this.perekViewModel = perekViewModel;
            this.textAsHtml = ko.observable('');
            this.dataSource = new PerekTextDataSource_V2();
        }
        load(): Promise<string> {
            return this.dataSource.loadPerekText(this.perekViewModel.toModel()).then(this.processText.bind(this));
        }

        processText(perekText: string) {
            var $perekText = $('<div>').html(perekText);
            var $pesukimArr = $perekText.find(">span");
            $pesukimArr.find('.teluia').each($.proxy(function (teluiaIndex: number, teluia: Element) {
                var teluiaDataArr = PerakimData.specialLettersData.tluiot.filter(function (value, index, array) {
                    return value.seferId == this.perekViewModel.seferId() &&
                        value.perekId == this.perekViewModel.perek() &&
                        value.pasukId == $pesukimArr.index(teluia.parentElement) + 1; 
                }, this);

                var $supReplacement = $('<sup>').html(teluia.innerHTML);
                $(teluia).replaceWith($supReplacement);
                try {
                    if (teluiaDataArr[0].endOfWord) $(document.createTextNode(" ")).insertAfter($supReplacement);
                } catch (ex) { }
            }, this));

            $pesukimArr.find('.zeira').each($.proxy(function (zeiraIndex: number, zeira: Element) {
                var zeiraDataArr = PerakimData.specialLettersData.zeirot.filter(function (value, index, array) {
                    return value.seferId == this.perekViewModel.seferId() &&
                        value.perekId == this.perekViewModel.perek() &&
                        value.pasukId == $pesukimArr.index(zeira.parentElement) + 1; 
                }, this);
                try {
                    if (zeiraDataArr[0].endOfWord) $(document.createTextNode(" ")).insertAfter(zeira);
                } catch (ex) { }
            }, this));

            $pesukimArr.find('.rabati').each($.proxy(function (zeiraIndex: number, rabati: Element) {
                var rabatiDataArr = PerakimData.specialLettersData.rabati.filter(function (value, index, array) {
                    return value.seferId == this.perekViewModel.seferId() &&
                        value.perekId == this.perekViewModel.perek() &&
                        value.pasukId == $pesukimArr.index(rabati.parentElement) + 1;
                }, this);
                try {
                    if (rabatiDataArr[0].endOfWord) $(document.createTextNode(" ")).insertAfter(rabati);
                } catch (ex) { }
            }, this));
            $pesukimArr.each(function (index: number, elem: Element) {
                var spacing = "";
                if (index !== 0 && $(elem).prev().has('.p-ptuha').length === 0) {
                    spacing = " ";
                }
                $('<span>', { 'class': 'pasuk-num' }).append(spacing, $('<a>', { 'data-pasuk-num': index + 1 }).html(Dsoft.GimatryHelper.toLetters(index + 1)), " ").insertBefore(elem);
            });
            var $pesukimWitKriQtiv = $pesukimArr.has('.qri, .ktiv');
            $pesukimWitKriQtiv.each(function (pasukIdx: number, pasuk: Element) {
                var $pasuk = $(pasuk);
                Dsoft.DomHelper.concatNeighboursOfSameClass(pasuk);
                var $qris = $pasuk.find('.qri');
                var $ktivs = $pasuk.find('.ktiv');

                $ktivs.each(function (ktivIdx: number, ktiv: Element) {
                    var $ktiv = $(ktiv);
                    var $ktivPreviousSibling = $(ktiv.previousSibling);
                    var spaceBefore: boolean = (pasuk.childNodes[0] !== ktiv && // not first child of pasuk
                        ((ktiv.previousSibling.nodeType == Node.TEXT_NODE && // previous node is text
                            $ktivPreviousSibling.text().charAt($ktivPreviousSibling.text().length - 1) !== '־') || // and not after makaf or:
                            (ktiv.previousSibling.nodeType == Node.ELEMENT_NODE && !$ktivPreviousSibling.hasClass('qri') && !$ktivPreviousSibling.hasClass('ktiv')) // is node but not kri nor ktiv.
                        )
                    );
                    var spaceAfter: boolean = (pasuk.childNodes[pasuk.childNodes.length - 1] !== ktiv && // not last child of pasuk
                        $ktiv.text().charAt($ktiv.text().length - 1) !== '־'); // last char of ktiv is not makaf

                    if (spaceBefore) $(document.createTextNode(" ")).insertBefore($ktiv);
                    if (spaceAfter) $(document.createTextNode(" ")).insertAfter($ktiv);
                });
                $qris.each(function (qriIdx: number, qri: Element) {
                    var $qri = $(qri);

                    var spaceBefore: boolean = (pasuk.childNodes[0] !== qri && // not first child of pasuk
                        (qri.previousSibling.nodeType == Node.TEXT_NODE || // previous node is text or:
                            (!$(qri.previousSibling).hasClass('qri') && !$(qri.previousSibling).hasClass('ktiv')) // is node but not kri nor ktiv.
                        )
                    )
                    var spaceAfter: boolean = (pasuk.childNodes[pasuk.childNodes.length - 1] !== qri && // not last child of pasuk
                        $qri.text().charAt($qri.text().length - 1) !== '־'); // last char of qri is not makaf

                    $qri.append(document.createTextNode(")"));
                    $qri.prepend($('<label>'));
                    $qri.prepend(document.createTextNode("("));
                    if (spaceBefore) $(document.createTextNode(" ")).insertBefore($qri);
                    if (spaceAfter) $(document.createTextNode(" ")).insertAfter($qri);

                });
            });

            //if (typeof pasukNum !== 'undefined')
            //    $($perekText.children('span')[pasukNum - 1]).addClass('referenced-pasuk');

            this.textAsHtml($perekText.html());
            this.$perekText = $perekText;
        }
    }
}