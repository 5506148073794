declare var dudu: Array<any>;
module Tanahpedia {
    export module Entries {
        export class BookView {
            private $book: JQuery;
            private $dynamicCssClass: JQuery;
            private $flipbookViewport: JQuery;
            private $originalBook: JQuery;
            private $tocPage: JQuery;
            private $toolBar: JQuery;

            private firstTime: boolean = true;
            private pageNeeded: boolean = true;
            private pageHeight: number;
            private pageWidth: number;

            entriesMetaDataViewmodel: EntriesMetaDataViewmodel;

            private doublePagesViews: Array<DoublePageView>;
            private fullScreenFlag: ko.Observable<boolean>;
            public pageIdx: ko.Observable<number>;
            public perekId: ko.Observable<number>;
            private soundEnabled: ko.Observable<boolean>;

            static firstPerekPageIdx = 6;
            static flipSound;
            static pagesCount: number;
            static pageRatio: number = 4 / 5;
            static staticPagesIdxs: Array<number>;
            static staticPages;
            static tocPageIdx = 5;
            static $evenPageTemplate: JQuery;
            static $oddPageTemplate: JQuery;

            public static generatePage = function (pairity: Pairity, idx: number): JQuery {
                var $page = $('<div />', { 'class': 'page', 'id': 'page-' + idx, 'data-page-idx': idx });
                if (pairity == Pairity.EVEN) {
                    $page.html(BookView.$evenPageTemplate.html());
                } else {
                    $page.html(BookView.$oddPageTemplate.html());
                }
                return $page;
            }
            public static ctor() {
                BookView.$evenPageTemplate = $('<div>').append($('.even-page-view'));
                BookView.$oddPageTemplate = $('<div>').append($('.odd-page-view'));

                BookView.flipSound = new Howl({
                    src: ['../media/flip.mp3', '../media/flip.ogg', '../media/flip.wav']
                });
            }
            constructor(entriesMetaDataViewmodel: EntriesMetaDataViewmodel) {
                this.init(entriesMetaDataViewmodel);
            }
            init(entriesMetaDataViewmodel: EntriesMetaDataViewmodel) {
                this.entriesMetaDataViewmodel = entriesMetaDataViewmodel;

                this.fullScreenFlag = ko.observable(false);
                this.pageIdx = ko.observable(0);
                this.pageIdx.subscribe(function (pageIdx: number) {
                    if (!this.pageNeeded) {
                        this.pageNeeded = true;
                        return;
                    } else {
                        this.pageToPageIdx(pageIdx);
                    }
                }, this);
                this.soundEnabled = ko.observable(true);

                this.registerWindowEvents();
                this.doublePagesViews = [];

                this.$book = $('#book');
                this.$originalBook = this.$book.clone(true, true);
                this.$flipbookViewport = $('#flipbookViewport');
                this.$tocPage = $('#tocPage');
                this.$toolBar = $('#bookToolbar');

                ko.applyBindings(this, this.$toolBar[0]);
                ko.applyBindings(entriesMetaDataViewmodel, this.$tocPage[0]);

                // front cover(2) + first page(1) + empty(1) + toc(1) +  <entries_count>*2 + empty(1) + back cover(2) = 1866
                BookView.pagesCount = 5 + entriesMetaDataViewmodel.totalCount * 2 + 3;
                BookView.staticPagesIdxs = [1, 2, 3, 4, 5, BookView.pagesCount - 2, BookView.pagesCount - 1, BookView.pagesCount];
                BookView.staticPages = new Array<JQuery>(BookView.pagesCount);
                BookView.staticPages[Entries.BookView.pagesCount - 2] = $('<div>').addClass('empty');
                BookView.staticPages[Entries.BookView.pagesCount - 1] = $('<div>', { 'id': 'backCoverInside', 'class': 'hard' }).append($('<div>', { 'id': 'backCoverInsideEmptyZone' }));
                BookView.staticPages[Entries.BookView.pagesCount] = $('<div>', { 'id': 'backCover', 'class': 'hard' });

                this.buildBook();
            }
            buildBook() {
                this.calcBookDimensions();
                this.updateStyleSheets();
                this.$book.turn({
                    acceleration: true,
                    autoCenter: true,
                    direction: "rtl",
                    duration: 1000,
                    elevation: 500,
                    height: this.pageHeight,
                    gradients: true,
                    pages: BookView.pagesCount,
                    width: this.pageWidth * 2,
                    when: {
                        turning: $.proxy(this.onPageTurning, this),
                        turned: $.proxy(this.onPageTurned, this)
                    }

                });
            }
            calcBookDimensions() {
                switch (Dsoft.Mobility.getScreenOrientation()) {
                    case Dsoft.Mobility.Orientation.LANDSCAPE:
                        this.pageHeight = this.$flipbookViewport.height() - (this.$toolBar.position().top + this.$toolBar.height() + 6);
                        this.pageWidth = Math.min(this.pageHeight * BookView.pageRatio, this.$flipbookViewport.width() / 2 - 20);
                        break;
                    case Dsoft.Mobility.Orientation.PORTRAIT:
                    case Dsoft.Mobility.Orientation.UNKNOWN:
                    default:
                        this.pageWidth = this.$flipbookViewport.width() / 2 - 20;
                        this.pageHeight = Math.min(this.pageWidth / BookView.pageRatio, this.$flipbookViewport.height() - (this.$toolBar.position().top + this.$toolBar.height() + 6));
                        break;
                }
            }
            closeBook() {
                this.$book.turn('page', 1);
            }
            getEntryMetaDataViewmodelByPageIdx(pageIdx: number): EntryMetaDataViewmodel {
                return this.entriesMetaDataViewmodel.getMetaDataByIndex(Math.floor((pageIdx - 5 - 1) / 2));
            }
            getPageIdxByEntryMetaDataViewmodelIdx(entryMetadataViewmodelIdx: number): number {
                return (entryMetadataViewmodelIdx * 2) + 5 + 1;
            }

            pageBackward() {
                this.$book.turn('previous');
            }
            pageForward() {
                this.$book.turn('next');
            }
            pageToToc() {
                this.$book.turn('page', BookView.tocPageIdx);
            }
            pageToPageIdx(pageIdx: number) {
                // Gets the range of pages that the book needs right now
                var range = this.$book.turn('range', pageIdx);
                // loadPage
                for (var page = Math.max(1, range[0] - 5); page <= range[1] + 8; page++) {
                    this.refreshPage(page);
                }
                this.$book.turn('page', pageIdx);
            }
            refreshPage(pageIdx: number, hard: boolean = false) {
                console.log("refreshing #" + pageIdx + '' + (hard ? ' hard' : ''));
                if (pageIdx > BookView.pagesCount) return;
                var $page;
                if ($.inArray(pageIdx, BookView.staticPagesIdxs) !== -1) {
                    $page = BookView.staticPages[pageIdx];
                    if (!this.$book.turn('hasPage', pageIdx)) {
                        console.log("adding page#" + pageIdx);
                        this.$book.turn('addPage', $page, pageIdx);
                    }
                } else {
                    var entryIndex = this.getEntryMetaDataViewmodelByPageIdx(pageIdx).index;
                    if (typeof this.doublePagesViews[entryIndex] == 'undefined' || hard) {
                        var doublePage: DoublePageView;
                        var isEven = (pageIdx % 2 === 0);
                        var isOdd = (pageIdx % 2 !== 0);

                        var $evenPage: JQuery = BookView.generatePage(Pairity.EVEN, isEven ? pageIdx : pageIdx - 1);
                        var $oddPage: JQuery = BookView.generatePage(Pairity.ODD, isOdd ? pageIdx : pageIdx + 1);
                        var $pagesToAdd: JQuery[] = [$evenPage, $oddPage].sort(function ($a, $b) {
                            return Number($a.attr('data-page-idx')) - Number($b.attr('data-page-idx'));
                        });
                        $pagesToAdd.forEach($.proxy(($pageToAdd: JQuery, index: number, $array: JQuery[]) => {
                            var pageIdxToAdd = Number($pageToAdd.attr('data-page-idx'));
                            if (!this.$book.turn('hasPage', pageIdxToAdd)) {
                                //console.log("adding page#" + pageIdxToAdd);
                                this.$book.turn('addPage', $pageToAdd, pageIdxToAdd);
                            }
                            else if (hard && pageIdxToAdd % 2 === 0) {
                                this.doublePagesViews[entryIndex].entryPageView.reattachNativeEvents();
                            } else if (hard && pageIdxToAdd % 2 !== 0) {
                                $('#page-' + pageIdxToAdd).html('');
                                $('#page-' + pageIdxToAdd).append($pageToAdd);
                            }
                        }, this));
                        doublePage = new DoublePageView(this.getEntryMetaDataViewmodelByPageIdx(pageIdx), { $entryPageElem: $evenPage, $entryExtraPageElem: $oddPage });
                        this.doublePagesViews[entryIndex] = doublePage;
                        doublePage.load();
                        //if (typeof dudu === 'undefined')
                        //    dudu = [];
                        //if (doublePage.entryViewmodel.name() == 'משה')
                        //    dudu.push(doublePage);
                        //this.doublePagesViews[perekId] = doublePage;
                        ////doublePage.perekLoadedEventHandler.register($.proxy(function (sender: any, e: { data: Perek }) {
                        ////    //console.log(e.data.seferName);
                        ////}, this), this);
                        //doublePage.load(perekId);
                    }
                }
            }
            registerWindowEvents() {
                $(window).on('keydown', $.proxy(function (e : JQueryKeyEventObject) {
                    if (e.target && e.target.tagName.toLowerCase() != 'input')
                        if (e.which == 39 && !Dsoft.GeneralHelper.isSpecialKeyAlsoPressed(e))
                            this.$book.turn('previous');
                        else if (e.which == 37 && !Dsoft.GeneralHelper.isSpecialKeyAlsoPressed(e))
                            this.$book.turn('next');
                }, this));
                $(window).on('orientationchange resize', Dsoft.debounce($.proxy(function (e) {
                    this.calcBookDimensions();
                    this.updateStyleSheets();
                    this.$book.turn('size', this.pageWidth * 2, this.pageHeight);
                }, this), 150));
            }
            toggleFullScreen() {
                Dsoft.Fullscreen.toggleFullscreen(document, this.$flipbookViewport[0]);
                this.fullScreenFlag(Dsoft.Fullscreen.isInFullscreen(document));
                if (Dsoft.Fullscreen.isInFullscreen(document)) {
                    $('.enlarge-btn').addClass('disabled');
                } else {
                    $('.enlarge-btn').removeClass('disabled');
                }
            }
            updatePageIdx(pageIdx: number) {
                this.pageNeeded = false;
                this.pageIdx(pageIdx);
            }
            updateStyleSheets() {
                if (typeof this.$dynamicCssClass !== 'undefined') {
                    this.$dynamicCssClass.remove();
                }
                this.$dynamicCssClass = $('<style>').text(
                    "#book{width:" + this.pageWidth * 2 + "px; height:" + this.pageHeight + "px; left: -" + this.pageWidth + "px; bottom: " + (this.pageHeight) + "px; }"
                    + ".page{width:" + this.pageWidth + "px;height:" + this.pageHeight + "px;}"
                );
                $(document.body).append(this.$dynamicCssClass);
            }

            onPageTurning(e, pageIdx, view) {
                if (this.soundEnabled()) {
                    BookView.flipSound.play();
                }
                this.updatePageIdx(pageIdx);
                switch (pageIdx) {
                    case 4:
                    case 5:
                        var $a: JQuery = this.$tocPage.find('li dl dt a');
                        $a.unbind('click');
                        $a.bindToIntuitiveClick($.proxy(function (e: JQueryEventObject) {
                            e.preventDefault();
                            var pageIdx = Number($(e.target).attr('data-page-idx'));
                            this.pageToPageIdx(pageIdx);
                            return false;
                        }, this));
                        break;
                    default:
                }
                if (this.firstTime) {
                    this.firstTime = false;
                } else {
                    var callback: Function = <Function>$.proxy(this.pageToPageIdx, this, pageIdx);
                    var entryMetaData = this.getEntryMetaDataViewmodelByPageIdx(pageIdx);
                    if (typeof entryMetaData !== 'undefined') {
                        ResourcesPool_V2.ViewPool.indexView.addressLineMgr.changeState(new AddressLineState(entryMetaData.name, entryMetaData.type, callback, true));
                    }
                    else {
                        ResourcesPool_V2.ViewPool.indexView.addressLineMgr.changeState(new AddressLineState(undefined, undefined, callback, true));
                    }
                }
            }
            onPageTurned(e, pageIdx) {
                // Gets the range of pages that the book needs right now
                var range = this.$book.turn('range', pageIdx);
                // loadPage
                // + 8
                for (var pageI = Math.max(1, range[0] - 5); pageI <= range[1]; pageI++) {
                    if (pageI !== pageIdx)
                        this.refreshPage(pageI);
                    else {
                        this.refreshPage(pageI, true);
                    }
                }

            }
        }
    }
}