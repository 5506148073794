module AlHaperek {
    export enum DedicationType {
        'MEMORIAL' = 1, 'REMEDY' = 2, 'MATCH' = 4, 'LIVING' = 8
    }
    export class Dedication {
        public id: number;
        public subject: string;
        public types: Array<DedicationType>;

        constructor(id: number, subject: string, types: Array<DedicationType>) {
            this.id = id;
            this.subject = subject;
            this.types = types;
        }
    }
}