module AlHaperek {
    export interface ResponsiveIndexViewConf {
        articleId?: number,
        articles?: Article[],
        perekId?: number,
        perushId?: number,
        perushim?: Perush[],
        perekIdWasZero: boolean,
        text: string
    }
    export class ResponsiveIndexView {
        private articleId: number;
        private perekId: number;
        private perushId: number;

        public addressLineMgr: Dsoft.AddressLine.AddressLineManager;
        public bookView: BookView;
        public navbarView: NavbarView;
        public syncView: SyncView;

        constructor(conf: ResponsiveIndexViewConf) {
            AlHaperek.ResourcesPool_V2.ViewPool.indexView = this;
            this.init(conf);
        }
        init(conf: ResponsiveIndexViewConf) {
            this.navbarView = new NavbarView();
            this.perekId = conf.perekId;
            this.articleId = conf.articleId;
            this.perushId = conf.perushId == -1 ? undefined : conf.perushId;

            this.addressLineMgr = new Dsoft.AddressLine.AddressLineManager(window.location.href.substr(0, window.location.href.indexOf('929') + 3)); //new AddressLineState(this.perekId, this.articleId, conf.text, addressLineChanged, true)

            var articleViewModels: ArticleViewmodel[] = ArticleViewmodel.modelsToViewmodels(conf.articles);
            var perushViewModels: PerushViewmodel[] = PerushViewmodel.modelsToViewmodels(conf.perushim);

            var selectedArticleViewModel: ArticleViewmodel, selectedPerushViewModel : PerushViewmodel;

            // complete articleViewModels and fill selectedArticleViewModel
            articleViewModels.forEach((articleVM: ArticleViewmodel, index: number, array: ArticleViewmodel[]) => {
                articleVM.perekId(this.perekId);
                if (articleVM.id() == conf.articleId) {
                    selectedArticleViewModel = articleVM;
                }
            });

            // complete perushViewModels 
            perushViewModels.forEach((perushVM: PerushViewmodel, index: number, array: PerushViewmodel[]) => {
                if (perushVM.id() == conf.perushId) {
                    selectedPerushViewModel = perushVM;
                }
            });
            this.syncView = new SyncView(articleViewModels, selectedArticleViewModel, perushViewModels, selectedPerushViewModel);
            // fill selectedPerushViewModel
            if (typeof selectedPerushViewModel !== 'undefined') {
                selectedPerushViewModel.perekViewModel = this.syncView.perekViewmodel;
            }

            this.syncView.load(this.perekId, this.articleId, this.perushId);

            if (!ResourcesPool_V2.ViewPool.isPhone) {
                this.syncView.hiddenThens.push({
                    onfulfilled: (value: any) => {
                        // First Time
                        if (typeof this.bookView === 'undefined') {
                            this.bookView = new BookView(this.syncView.perekViewmodel.perekId(), this.syncView.selectedArticleId);
                        }
                        // Book's page is still different from sync-view page and in future reveal sync-view's article in book view.
                        if (this.bookView.perekId() != this.syncView.perekViewmodel.perekId() ||
                            this.syncView.articlesViewmodel.selectedArticleViewmodel() != null) {
                            //this.bookView.updatePerekId(sender.perekViewmodel.perekId(), true);
                            //this.bookView.perekId(this.syncView.perekViewmodel.perekId());
                            this.bookView.pageToPerek(this.syncView.perekViewmodel.perekId());
                        }
                    }
                });
                //Dsoft.GeneralHelper.callOnFirstUserInteraction($.proxy(function () {
                //    this.bookView = new BookView(this.perekId, this.articleId);
                //}, this));
            }

            // Refresh today's perek id every 1/4 hour.
            setInterval(function () {
                ResourcesPool_V2.ViewmodelPool.todaysPerekId((new PerekDataSource_V2()).dateToPerekId());
            }, 15 * 60 * 1000);
            //this.syncView.hide();

            if (conf.perekIdWasZero) {
                this.syncView.hide();
                this.bookView.pageToToc();
            }
        }

    }
}