module Tanahpedia {
    export module Entries {
        export module ResourcesPool_V2 {
            export var constructed: boolean;
            export function ctor(config: Config): void {
                ModelPool.ctor(config);
                ViewmodelPool.ctor(config);
                ViewPool.ctor(config);
            }
            export module ModelPool {
                export var entriesMetaData: EntriesMetaData;
                export function ctor(config: Config): void {
                    switch (config) {
                        case Config.SITE_BIG:
                            break;
                        case Config.SITE_SMALL:
                            break;
                        case Config.APP_BIG:
                            break;
                        case Config.APP_SMALL:
                            break;
                    }
                }
            }
            export module ViewmodelPool {
                export var entriesMetaDataViewmodel: EntriesMetaDataViewmodel;
                export function ctor(config: Config): void {
                }
            }
            export module ViewPool {
                export var indexView: ResponsiveIndexView;
                export var isPhone: boolean;

                export function ctor(config: Config): void {
                    switch (config) {
                        case Config.SITE_BIG:
                            BookView.ctor();
                            break;
                        case Config.SITE_SMALL:
                            break;
                        case Config.APP_BIG:
                        case Config.APP_SMALL:
                            break;
                    }

                }
            }

        }
    }
}