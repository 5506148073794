module AlHaperek {
    export class RabbisDataSource {
        static rabbis: Rabbi[] = [];
        static loadedOnce: boolean = false;
        loadRabbis(): Promise<Rabbi[]> {
            if (RabbisDataSource.loadedOnce) {
                return Promise.resolve(RabbisDataSource.rabbis);
            }

            var settings = { async: true, type: 'GET', url: Conn.remotePath + 'get_rabbis.php', data: { 'all': true }, dataType: 'json' };

            return Dsoft.Ajax.commonAjaxCall<Rabbi[], Rabbi[]>(settings, (message: any): Rabbi[] => {
                var rabbis = <Rabbi[]>Object['values'](message);
                for (var rabbi of rabbis) {
                    RabbisDataSource.rabbis[rabbi.id] = rabbi;
                }
                RabbisDataSource.loadedOnce = true;
                return rabbis;
            });
        }
    }
}