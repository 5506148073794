module AlHaperek {
    export class PerekDataSource_V2 {
        //perekLoadedEventHandler: EventHandler;
        public isLoading: boolean;
        constructor() {
            this.isLoading = false;
            //this.perekLoadedEventHandler = new EventHandler();
        }
        dateToPerekId(dateTime?: Date): number {
            dateTime = typeof dateTime !== 'undefined' ? dateTime : new Date();
            if (dateTime.getDay() >= 5) {
                dateTime.setDate(dateTime.getDate() + 1);
                dateTime.setHours(1);
                return this.dateToPerekId(dateTime);
            }
            var dateTime2: Date;
            var perekId: number = 1;
            var dateWithSlashes = dateTime['format']('yyyy/mm/dd');
            var dateWithDashes = dateTime['format']('yyyy-mm-dd');
            for (var i = 0; i < 929; i++) {

                if (PerakimData.perakim.date[i] == dateWithDashes) {
                    dateTime2 = new Date(dateWithSlashes + " " + PerakimData.perakim.tseit[i]);
                    if (dateTime <= dateTime2) {
                        perekId = i + 1;
                    } else {
                        perekId = i + 2;
                    }
                    break;
                }
            }
            return perekId;
        }
        // old attitude: yyyy-mm-dd HH:MM:ss
        loadPerekByDateTime(dateTime?: Date): Promise<Perek> {
            return this.loadPerekByPerekId(this.dateToPerekId(dateTime));
            //return new Promise<Perek>((resolve: (value?: Perek) => void, reject: (reason?: any) => void) => {
            //    //if (this.isLoading) return;
            //    //this.isLoading = true;
            //    dateTime = typeof dateTime !== 'undefined' ? dateTime : new Date();
            //    this.loadPerekByPerekId(this.dateToPerekId(dateTime), true).then((value: Perek) => {
            //        resolve(value);
            //    });
            //    //this.isLoading = false;
            //});
        }

        loadPerekByPerekId(perekId: number): Promise<Perek> { //, internally: boolean = false
            //if (this.isLoading && !internally) return;
            //this.isLoading = true;
            //console.log("loading perek#" + perekId + " in v2 way");
            return new Promise<Perek>((resolve: (value?: Perek) => void, reject: (reason?: any) => void) => {
                if (perekId > 929) perekId = 929;
                var perekIdx = perekId - 1;
                var id = null;
                var additional = PerakimData.perakim.additional[perekIdx]
                var dayOfWeek = PerakimData.perakim.dayOfWeek[perekIdx];
                var hebDate = PerakimData.perakim.hebDate[perekIdx];
                var header = PerakimData.perakim.header[perekIdx];
                var perek = PerakimData.perakim.perek[perekIdx];
                var seferId = PerakimData.perakim.seferId[perekIdx];
                var sefer = SefarimData.sefarim[PerakimData.perakim.seferId[perekIdx]];
                var seferName = sefer.name;
                var seferTanahUsName: string;
                if (additional == null) {
                    seferTanahUsName = sefer.tanahUsName;
                } else {
                    var series: Array<string> = sefer.tanahUsName;
                    seferTanahUsName = series[additional];
                }
                resolve(new Perek(null,
                    perekId,
                    additional,
                    dayOfWeek,
                    header,
                    hebDate,
                    perek,
                    seferId,
                    seferName,
                    seferTanahUsName));
                //this.isLoading = false;
            });
        }

        loadPerekBySource(seferId: number, additional: number, perek: number): Promise<Perek> {
            //if (this.isLoading) return;
            //this.isLoading = true;
            //this.loadPerekByPerekId(this.perekSourceToPerekId(seferId, additional, perek), true);
            //this.isLoading = false;

            return this.loadPerekByPerekId(this.perekSourceToPerekId(seferId, additional, perek));
        }
        perekSourceToPerekId(seferId: number, additional: number, perek: number): number {
            //console.log("loading perek of source: (" + seferId + (additional != null ? " " : "") + additional + " " + perek + ")" + " in v2 way");
            var seferPos: number;
            var additionalPos: number;
            var perekId: number;
            for (var i = 0; i < 929; i++) {
                if (PerakimData.perakim.seferId[i] == seferId) {
                    seferPos = i;
                    break;
                }
            }
            for (var i = seferPos; i < 929; i++) {
                if (PerakimData.perakim.additional[i] == additional) {
                    additionalPos = i;
                    break;
                }
            }
            for (var i = additionalPos; i < 929; i++) {
                if (PerakimData.perakim.perek[i] == perek) {
                    perekId = i + 1;
                    break;
                }
            }
            return perekId;
        }
    }

}