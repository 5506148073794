module AlHaperek {
    export class PerushimViewmodel {

        public perushViewmodels: ko.ObservableArray<PerushViewmodel>;
        public perushimGroupedByParshanim: ko.ObservableArray<PerushimGroupedByParshanimViewmodel>;
        public perekId: number;
        public perekViewmodel: PerekViewModel;
        public selectedPerushViewmodel: ko.Observable<PerushViewmodel>;

        constructor(perekViewmodel?: PerekViewModel, perushViewModels?: PerushViewmodel[], selectedPerushViewModel?: PerushViewmodel) {
            this.init(perekViewmodel, perushViewModels, selectedPerushViewModel);
        }
        init(perekViewmodel?: PerekViewModel, perushViewModels?: PerushViewmodel[], selectedPerushViewModel?: PerushViewmodel) {
            if (typeof perekViewmodel !== 'undefined') {
                this.perekViewmodel = perekViewmodel;
                this.perekId = this.perekViewmodel.perekId();
            }
            this.perushViewmodels = ko.observableArray([]);

            var perushViewModelsToFill = typeof perushViewModels == 'undefined' ? [] : perushViewModels;
            this.perushViewmodels = ko.observableArray(perushViewModelsToFill);

            // ko bugs
            if (typeof selectedPerushViewModel == 'undefined') {
                this.selectedPerushViewmodel = ko.observable(new PerushViewmodel());
                this.selectedPerushViewmodel(null);
            } else {
                this.selectedPerushViewmodel = ko.observable(selectedPerushViewModel);
            }

            this.perushimGroupedByParshanim = ko.observableArray([]);
            this.perushViewmodels.subscribe((newPerushViewModels: PerushViewmodel[]) => {
                this.perushimGroupedByParshanim.removeAll();
                newPerushViewModels.forEach((perushVM: AlHaperek.PerushViewmodel, index: number, array: AlHaperek.PerushViewmodel[]) => {
                    var groupOfSameParshanimAsPerushVM: PerushimGroupedByParshanimViewmodel = this.perushimGroupedByParshanim().find((group: PerushimGroupedByParshanimViewmodel, i: number, groups: PerushimGroupedByParshanimViewmodel[]) => {
                        return (group.multipleParshanim() == false && typeof perushVM.parshan2 === 'undefined' && group.parshan().name() == perushVM.parshan.name()) ||
                            (group.multipleParshanim() == true && group.parshan().name() == perushVM.parshan.name() && group.parshan2().name() == perushVM.parshan2.name())
                    }, this);
                    if (groupOfSameParshanimAsPerushVM == undefined) {
                        var parshanVMs: Array<AlHaperek.ParshanViewmodel> = [perushVM.parshan];
                        if (typeof perushVM.parshan2 !== 'undefined') {
                            parshanVMs.push(perushVM.parshan2);
                        }
                        groupOfSameParshanimAsPerushVM = new PerushimGroupedByParshanimViewmodel(parshanVMs, [perushVM]);
                        this.perushimGroupedByParshanim.push(groupOfSameParshanimAsPerushVM);
                    } else {
                        groupOfSameParshanimAsPerushVM.perushViewmodels(groupOfSameParshanimAsPerushVM.perushViewmodels().concat([perushVM]));
                    }
                });
                this.perushimGroupedByParshanim.sort((g1, g2) => {
                    var result =
                        g1.parshan().birthYear() - g2.parshan().birthYear();
                    if (result == 0) {
                        if (g1.multipleParshanim() && !g2.multipleParshanim())
                            result++;
                        else if (!g1.multipleParshanim() && g2.multipleParshanim())
                            result++;
                    }
                    return result;
                });
            });
        }
        load(source?: number | PerekViewModel): Promise<ko.ObservableArray<PerushViewmodel>> {
            return new Promise<ko.ObservableArray<PerushViewmodel>>((resolve: (value?: ko.ObservableArray<PerushViewmodel>) => void, reject: (reason?: any) => void) => {
                if (typeof source === 'undefined') {
                    this.perekId = this.perekViewmodel.perekId();
                }
                else if (typeof source === 'number') {
                    this.perekId = source;
                    this.perekViewmodel = new PerekViewModel(true, source);
                } else {
                    this.perekViewmodel = source;
                }
                this.perushViewmodels([]);
                var perushimIds: number[] = <number[]>AlHaperek.PerushimData.sefarimPerakimPerushim[this.perekViewmodel.seferTanachUsName()][this.perekViewmodel.perek()];
                var perushViewModels: PerushViewmodel[] = perushimIds.map((value: number, indx: number, array: number[]) =>
                    new PerushViewmodel(this.perekViewmodel, <Perush>PerushimData.perushim[value])
                );
                this.perushViewmodels(perushViewModels);
                resolve(this.perushViewmodels);
            });
        }
    }
}