module WebSite {
    export class Registration {
        registering: boolean = false;;

        $registrationFormContainer: JQuery;
        $email: JQuery;
        $submit: JQuery;
        $registering: JQuery;
        $registeringFailed: JQuery;
        $registeringSuccefull: JQuery;
        registeredEventHandler: EventHandler;
        notRegisteredSentEventHandler: EventHandler;

        constructor() {
            this.init();
        }

        init() {
            this.$registrationFormContainer = $('#newsletter');
            this.$email = this.$registrationFormContainer.find('input[type=email]');
            this.$submit = this.$registrationFormContainer.find('input[type=submit]');
            this.$registering = this.$registrationFormContainer.find('.registering').first();
            this.$registeringFailed = this.$registrationFormContainer.find('.registering.failed');
            this.$registeringSuccefull = this.$registrationFormContainer.find('.registering.succefull');
            this.$submit.bind('click', $.proxy(function (e: JQueryEventObject) {
                e.stopPropagation();
                if (!this.registering) {
                    this.registering = true;
                    this.$submit.hide();
                    this.$registering.show();
                    this.register(this.$email.val());
                }
                return false;
            }, this));
            this.registeredEventHandler = new EventHandler();
            this.registeredEventHandler.register(this.registered, this);
            this.notRegisteredSentEventHandler = new EventHandler();
            this.notRegisteredSentEventHandler.register(this.notRegistered, this);
        }


        register(email: string): void {
            var doneCallback = $.proxy(function (data) {
                if (data.success) {
                    try {
                        if (data.message) {
                            this.registeredEventHandler.fire(new EventArgs(this, { data: data.message }));
                        } else {
                            this.notRegisteredSentEventHandler.fire(new EventArgs(this, { data: data.message }));
                        }
                    } catch (ex) { }
                }
            }, this);
            var checkFail = $.proxy(
                function (jqXHR, textStatus, errorThrown) {
                    try {
                        var result = JSON.parse(jqXHR.responseText.substring(jqXHR.responseText.indexOf('{')));
                        this.doneCallback(result);
                    } catch (ex) { }
                }
                , { context: this, doneCallback: doneCallback });
            $.ajax({
                type: 'POST', url: 'https://xn--febl3a.co.il/server/' + 'register.php', data: { 'email': email }, dataType: 'json'
            }).done(doneCallback).fail(checkFail);
        }

        registered() {
            this.registering = false;
            this.$registering.hide();
            this.$registeringSuccefull.show();
        }
        notRegistered() {
            this.registering = false;
            this.$registering.hide();
            this.$registeringFailed.show();
        }
    }
}