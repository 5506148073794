module AlHaperek {
    export module BookStandalone {
        export class PerekExtraPageView {
            private articlesSelectedStates: ko.ObservableArray<ko.Observable<boolean>>;
            private perushimSelectedStates: ko.ObservableArray<ko.Observable<boolean>>;
            private systemMessagesSelectedStates: ko.ObservableArray<ko.Observable<boolean>>;

            //private isThisArticleSelected: ko.Computed<boolean>;
            //private isThisPerushSelected: ko.Computed<boolean>;

            private perekId: number;

            private $closeArticleBtn: JQuery;
            private $closePerushBtn: JQuery;
            private $closeSystemMessageBtn: JQuery;
            private $perekExtraPageElem: JQuery;

            public articlesViewmodel: ArticlesViewmodel;
            public perushimViewmodel: PerushimViewmodel;
            public systemMessagesViewModel: SystemMessagesViewModel;

            public perekViewmodel: PerekViewModel;

            constructor(perekViewmodel: PerekViewModel, perekId: number, $perekExtraPageElem: JQuery) {
                this.init(perekViewmodel, perekId, $perekExtraPageElem);
            }
            init(perekViewmodel: PerekViewModel, perekId: number, $perekExtraPageElem: JQuery) {
                this.perekId = perekId;

                this.articlesSelectedStates = ko.observableArray([]);
                this.perushimSelectedStates = ko.observableArray([]);
                this.systemMessagesSelectedStates = ko.observableArray([]);

                this.perekViewmodel = perekViewmodel;
                this.perekViewmodel.loadedThens.push({ onfulfilled: this.perekLoaded.bind(this) });
                this.articlesViewmodel = new ArticlesViewmodel();
                this.articlesViewmodel.selectedArticleViewmodel(null);
                this.perushimViewmodel = new PerushimViewmodel(this.perekViewmodel);
                this.perushimViewmodel.selectedPerushViewmodel(null);
                this.systemMessagesViewModel = new SystemMessagesViewModel();
                this.systemMessagesViewModel.load().then((systemMessageViewModels: ko.ObservableArray<SystemMessageViewModel>) => {
                    var systemMessagesSelectedStates: ko.Observable<boolean>[] = [];
                    systemMessageViewModels().forEach((systemMessageViewModel: SystemMessageViewModel) => {
                        var selectedObserver = ko.observable(false);
                        systemMessagesSelectedStates[systemMessageViewModel.id()] = selectedObserver;
                        selectedObserver.subscribe((newValue: boolean) => {
                            var found: boolean = false;
                            if (newValue) {
                                this.showSystemMessage(systemMessageViewModel);
                            } else {
                                this.systemMessagesViewModel.selectedSystemMessageViewModel(null);
                            }
                        });
                    });
                    this.systemMessagesSelectedStates(systemMessagesSelectedStates);
                });

                this.$perekExtraPageElem = $perekExtraPageElem;
                this.$closeArticleBtn = this.$perekExtraPageElem.find('.selected-article-container .close-btn-container .btn');
                this.$closePerushBtn = this.$perekExtraPageElem.find('.selected-perush-container .close-btn-container .btn');
                this.$closeSystemMessageBtn = this.$perekExtraPageElem.find('.selected-system-message-container .close-btn-container .btn');

                ko.applyBindings(this, this.$perekExtraPageElem[0]);

                this.perekViewmodel = perekViewmodel;
            }
            closeSelectedSystemMessage(): void {
                this.perushimViewmodel.selectedPerushViewmodel(null);
                this.systemMessagesSelectedStates().forEach((systemMessageSelectedState: ko.Observable<boolean>) => systemMessageSelectedState(false));
            }
            closeSelectedArticle(): void {
                this.articlesViewmodel.selectedArticleViewmodel(null);
                this.articlesSelectedStates().forEach((articleSelectedState: ko.Observable<boolean>) => articleSelectedState(false));
            }
            closeSelectedPerush(): void {
                this.perushimViewmodel.selectedPerushViewmodel(null);
                this.perushimSelectedStates().forEach((perushSelectedState: ko.Observable<boolean>) => perushSelectedState(false));
            }

            scrollToPasukPerush(pasukNum: number) {
                $.scrollWithJqueryAnim(this.$perekExtraPageElem.find('.selected-perush-container'), $("#perekExtraView_" + this.perekId + "_" + "Pasuk_" + pasukNum));
            }
            showArticle(vm: ArticleViewmodel): void {
                this.articlesViewmodel.selectedArticleViewmodel(vm);
                vm.loadContent();
            }
            reattachNativeEvents() {
                $([this.$closeArticleBtn, this.$closePerushBtn]).unbind('click');
                this.$closeArticleBtn.bind('click', this.closeSelectedArticle.bind(this));
                this.$closeSystemMessageBtn.bind('click', this.closeSelectedSystemMessage.bind(this));
                this.$closePerushBtn.bind('click', this.closeSelectedPerush.bind(this));
            }
            showPerush(vm: PerushViewmodel): void {
                this.perushimViewmodel.selectedPerushViewmodel(vm);
                vm.load();
            }
            showSystemMessage(vm: SystemMessageViewModel): void {
                this.systemMessagesViewModel.selectedSystemMessageViewModel(vm);
            }
            processArticles(articleViewmodels: ko.ObservableArray<ArticleViewmodel>) {
                var articlesSelectedStates: ko.Observable<boolean>[] = [];
                articleViewmodels().forEach((articleViewModel: ArticleViewmodel, index: number, array: ArticleViewmodel[]) => {
                    var selectedObservable = ko.observable<boolean>(false);
                    articlesSelectedStates[articleViewModel.id()] = selectedObservable;
                    selectedObservable.subscribe((newValue: boolean) => {
                        if (newValue) {
                            this.showArticle(articleViewModel);
                        } else {
                            this.articlesViewmodel.selectedArticleViewmodel(null);
                        }
                    });
                });
                this.articlesSelectedStates(articlesSelectedStates);
            }
            processPerushim(perushViewmodels: ko.ObservableArray<PerushViewmodel>) {
                var perushimSelectedStates: ko.Observable<boolean>[] = [];


                perushViewmodels().forEach((perushViewModel: PerushViewmodel, index: number, array: PerushViewmodel[]) => {
                    var selectedObservable = ko.observable<boolean>(false);
                    perushimSelectedStates[perushViewModel.id()] = selectedObservable;
                    selectedObservable.subscribe((newValue: boolean) => {
                        if (newValue) {
                            this.showPerush(perushViewModel);
                        } else {
                            this.perushimViewmodel.selectedPerushViewmodel(null);
                        }
                    });

                    perushViewModel.perushLoadedEventHandler.register((sender: any, e: { data: string }) => {
                        var perushVm = <PerushViewmodel>sender;
                        perushVm.$perushText.find('.pasuk-num').each((index: number, elem: Element) => {
                            var $pasukNum = $(elem);
                            var n: number = <number>$pasukNum.data('pasuk-num');
                            $()
                            var $pasukNumA = $('<a>', { 'id': "perekExtraView_" + this.perekId + "_" + "Pasuk_" + n, 'deta-dest': "#perekView_" + this.perekId + "_" + "Pasuk_" + n, 'data-pasuk-num': n }).html($pasukNum.html().trim());
                            //$pasukNumA.bindToIntuitiveClick($.proxy(this.scrollToPasuk, this, index + 1));
                            $pasukNum.html('').append($pasukNumA, '.', '&nbsp;');
                        });
                        perushVm.html(perushVm.$perushText.html());

                        this.$perekExtraPageElem.find('.selected-perush .pasuk-num a').each((index: number, elem: Element) => {
                            var $pasukNumAnchor = $(elem);
                            if (typeof ResourcesPool_V2.ViewPool.bookStandaloneView !== 'undefined' && typeof AlHaperek.ResourcesPool_V2.ViewPool.bookStandaloneView.doublePagesViews[this.perekId] !== 'undefined') {
                                $pasukNumAnchor.bindToIntuitiveClick(AlHaperek.ResourcesPool_V2.ViewPool.bookStandaloneView.doublePagesViews[this.perekId].perekPageView.scrollToPasuk.bind(ResourcesPool_V2.ViewPool.indexView.bookView.doublePagesViews[this.perekId].perekPageView, $pasukNumAnchor.data('pasuk-num')));
                            }
                        });
                    }, this);

                }, this);

                this.perushimSelectedStates(perushimSelectedStates);
            }
            perekLoaded(perekViewModel: PerekViewModel) {
                this.articlesViewmodel.load(perekViewModel.perekId()).then(this.processArticles.bind(this));
                this.perushimViewmodel.load().then(this.processPerushim.bind(this));

                this.reattachNativeEvents();
            }

        }

    }
}