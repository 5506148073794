/// <reference path="../../../global-settings.ts" />
module Tanahpedia {
    export module Conn {
        export enum ConnType {
            homegroup, local, remote, remoteDev
        }
        export var displayedLocalPath: string;
        export var localPath: string;
        export var localEntriesPath: string;
        export var remoteEntriesPath;
        export var remotePath: string;
        export var rootPath: string;
        displayedLocalPath = "http://תנך.co.il/929/"; // double // are not allowed. exact address is needed.
        export function init(connType: ConnType): void {
            switch (connType) {
                case ConnType.homegroup:
                    localPath = "http://192.168.43.32/tanah-dev/v2/www/pedia/";
                    localEntriesPath = "http://192.168.43.32/tanah-dev/v2/www/pedia/entries/";
                    remotePath = "http://192.168.43.32/tanah-dev/v2/server/pedia/";
                    remoteEntriesPath = "http://192.168.43.32/tanah-dev/v2/server/pedia/entries/";
                    break;
                case ConnType.local:
                    localPath = "http://localhost/tanah-dev/v2/www/pedia/";
                    localEntriesPath = "http://localhost/tanah-dev/v2/www/pedia/entries/";
                    remotePath = "http://localhost/tanah-dev/v2/server/pedia/";
                    remoteEntriesPath = "http://localhost/tanah-dev/v2/server/pedia/entries/";
                    break;
                case ConnType.remote:
                    localPath = "https://xn--febl3a.co.il/pedia/"; // double // are not allowed. exact address is needed.
                    localEntriesPath = "https://xn--febl3a.co.il/pedia/entries/";
                    rootPath = "https://xn--febl3a.co.il/";
                    remoteEntriesPath = "https://xn--febl3a.co.il/server/pedia/entries/";
                    remotePath = "https://xn--febl3a.co.il/server/pedia/";
                    break;
                case ConnType.remoteDev:
                    localPath = "https://tanah-preprod.tk/pedia/";
                    localEntriesPath = "https://tanah-preprod.tk/www/pedia/entries/";
                    remotePath = "https://tanah-preprod.tk/server/pedia/";
                    remoteEntriesPath = "https://tanah-preprod.tk/server/pedia/entries/";
                    break;
            }
        }
        export function hebToEncodedUrl(hebrewStr: string): string {
            return encodeURIComponent(hebrewStr).replace(/%20/g, '_');
        }
        export function hebToEncodedUrlLocalhost(hebrewStr: string): string {
            var str = encodeURIComponent(hebrewStr).replace(/%20/g, '_');
            var result = str.replace(/%/g, '%25');
            //for (var i = 0; i < str.length; i++) {
            //    result += 
            //}
            return result;
        }
        export function factoryHebToEncodedUrl(hebrewStr: string): string {
            return hebToEncodedUrlLocalhost(hebrewStr);
        }
        init(GlobalSettings.connType);
    }
}
