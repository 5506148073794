module App_V3 {
    export class NavigationPanelView {
        public $el: JQuery;
        public underlying: any;
        public bind($el: JQuery) {
            this.$el = $el;
            ko.applyBindings(this, $el[0]);
        }

        public onItemClick(context: NavigationPanelView, ev: MouseEvent) {
            var $itemElem = $(<HTMLAnchorElement>ev.currentTarget);
            var viewToShowSelector: string = $itemElem.data('view');
            var $viewToShow = $(viewToShowSelector);
            $viewToShow.prependTo($viewToShow.parent());

            var viewName = viewToShowSelector.replace(/View/g, '').replace(/#/g, '');
            var viewToShow = ResourcesPool.ViewPool.rootViews.find((view) => view.name == viewName);

            App_V3.app.onPageAfterIn(<Framework7.Router.Page>{ name: viewName, direction: 'backward' });

            App_V3.app.underlying.panel.close('right');
        }

    }
}
