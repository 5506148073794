module AlHaperek {
    export module Parshanim {
        export interface IndexViewConf {
            parshanName?: string,
            $viewContainer: JQuery
        }
        export class IndexView {
            static datatableOptions: DataTables.Settings = {
                columnDefs: [
                    {
                        searchable: false,
                        targets: [0],
                        visible : false
                    },
                    {
                        orderData: 0,
                        targets: [1]
                    },
                    {
                        orderable: false,
                        searchable: false,
                        targets: [2]
                    },
                    {
                        orderable: false,
                        searchable: false,
                        targets: [3]
                    }
                ],
                info: false,
                language: {
                    processing: "מעבד...",
                    zeroRecords: "לא נמצאו מאמרים התואמים לחיפוש",
                    emptyTable: "עדיין אין מאמרים של הרב",
                    search: "חפש מאמר (ספר/כותרת):",
                },
                
                order: [[1, "asc"]],
                paging: false,
                scrollCollapse: false
            };
            static slideshowOptions: JQuerySlickOptions = {
                arrows: true,
                rtl: true
            };
            static $slideTemplate: JQuery;
            static $dataTableTemplate: JQuery;

            everShowed: boolean = false;
            everGridInited: boolean = false;
            mouseDownInClosePos: boolean;
            parshan: Parshan;

            parshanSearchViewmodel: ParshanSearchViewmodel;
            parshanimViewmodel: ParshanimViewmodel;

            public addressLineMgr: AddressLineManager;

            $viewContainer: JQuery;
            $parshanimSlideshowContainer: JQuery;
            $parshanimSlideshow: JQuery;
            $parshanimGrid: JQuery;
            parshanimGridMasonry: Masonry;

            constructor(conf: IndexViewConf) {
                AlHaperek.Parshanim.ResourcesPool.ctor();
                AlHaperek.Parshanim.ResourcesPool.ViewPool.indexView = this;
                this.init(conf);
            }
            init(conf: IndexViewConf) {
                this.parshan = ParshanimData.parshanim.find(p => p.name == conf.parshanName);

                this.parshanimViewmodel = new ParshanimViewmodel(ParshanimData.parshanim);
                this.parshanSearchViewmodel = new ParshanSearchViewmodel(ParshanimData.parshanim);
                this.parshanSearchViewmodel.resultChangedEventHandler.register((sender: any, e: { data: Parshan[] }) => {
                    this.parshanimViewmodel.filterParshanim(e.data);
                }, this);
                this.parshanimViewmodel.parshanPerushimLoadedEventHandler.register((sender: any, e: { data: { parshanVM: ParshanViewmodel, articles: AlHaperek.ArticleViewmodel[] } }) => {
                    var $currentSlide: JQuery = this.$parshanimSlideshow.find('li[data-parshan-id=' + e.data.parshanVM.hash() + ']:not(.slick-cloned)');
                    this.refreshSlideDataTable($currentSlide);
                }, this);
                this.parshanimViewmodel.selectedParshanViewmodel.subscribe((rVM: ParshanViewmodel) => {
                    this.showParshan(rVM);
                }, this);
                if ((typeof this.parshan == 'undefined') || (this.parshan === null)) {
                    this.addressLineMgr = new AddressLineManager(
                        new AddressLineState(
                            "",
                            $.proxy(this.hideSlideshow, this),
                            true)
                    );
                } else {
                    this.addressLineMgr = new AddressLineManager();
                }


                this.$viewContainer = conf.$viewContainer;
                this.$parshanimSlideshowContainer = this.$viewContainer.find('.slideshow');
                this.$parshanimSlideshowContainer.mousedown((ev: JQueryMouseEventObject) => {
                    this.mouseDownInClosePos = (ev.target == this.$parshanimSlideshowContainer[0] || ev.target.className == 'slick-track');
                });
                this.$parshanimSlideshowContainer.click($.proxy((ev: JQueryMouseEventObject) => {
                    if (this.mouseDownInClosePos &&
                        (ev.target == this.$parshanimSlideshowContainer[0] || ev.target.className == 'slick-track')
                    ) this.hideSlideshow();
                }, this));
                this.$parshanimGrid = this.$viewContainer.find('.grid-gallery');

                ko.applyBindings(this, this.$viewContainer[0]);

                this.parshanimViewmodel.filteredParshanViewmodels.subscribe(() => {
                    if (!this.everGridInited) { // seems to not work.
                        this.initGrid();
                        this.$parshanimGrid.find('figcaption').removeClass('until-init');
                        this.initGrid();
                        this.initSlides();
                        this.everGridInited = true;
                        if (this.parshan != null) {
                            this.parshanimViewmodel.selectParshanByName(this.parshan.name);
                        }
                    } else {
                        this.$parshanimGrid.find('figcaption').removeClass('until-init');
                        this.initGrid();
                        this.initSlides();
                    }
                }, this);
                this.parshanimViewmodel.filteredParshanViewmodels(this.parshanimViewmodel.parshanViewmodels());

            }
            initGrid() {
                this.parshanimGridMasonry = new Masonry('ul.grid', {
                    itemSelector: 'li',
                    resize: true,
                    columnWidth: this.$viewContainer.find('.grid-sizer')[0],
                    originLeft: false
                });
                var $parshanAnchors: JQuery = this.$parshanimGrid.find('a');
                if (!$parshanAnchors.data('binded')) {
                    this.$parshanimGrid.find('a').bindToIntuitiveClick($.proxy((ev: JQueryMouseEventObject) => {
                        this.parshanimViewmodel.selectedParshanViewmodel(ko.dataFor(ev.target) as ParshanViewmodel);

                    }, this));
                    $parshanAnchors.data('binded', true);
                }
            }
            initSlides() {

                this.$parshanimSlideshow = this.$parshanimSlideshowContainer.find('ul');
                try {
                    if (this.$parshanimSlideshow.hasClass('slick-initialized')) {
                        this.$parshanimSlideshow.slick('unslick');
                        // ---------------- reattach bindings -----------------
                        this.$parshanimSlideshow.empty();
                        this.$parshanimSlideshow.append(IndexView.$slideTemplate);
                        ko.cleanNode(this.$parshanimSlideshow[0]);
                        ko.applyBindings(this, this.$parshanimSlideshow[0]);
                        // ----------------------------------------------------
                    }
                    this.$parshanimSlideshow.slick(IndexView.slideshowOptions);
                    this.$parshanimSlideshow.on('afterChange', $.proxy((event, slick, currentSlide) => {
                        Dsoft.Debounce.debounceWithId($.proxy((currentSlide) => {
                            var $currentSlide: JQuery = this.$parshanimSlideshow.find('li[data-slick-index=' + currentSlide + ']');
                            var parshanName: string = String($currentSlide.data('parshan-name'));
                            if (this.parshanimViewmodel.selectedParshanViewmodel().name() != parshanName) {
                                this.parshanimViewmodel.selectParshanByName(parshanName);
                            }
                        }, this, currentSlide), 20, currentSlide);
                    }, this));
                } catch (ex) { console.log(ex); }


            }
            public getCurrentSlideIdx() : number {
                return <number>this.$parshanimSlideshow.slick('slickCurrentSlide');
            }
            public hideSlideshow() {
                this.$parshanimSlideshowContainer.removeClass('animated rollIn');
                this.$parshanimSlideshowContainer.addClass('animated rollOut');
                Dsoft.View.allowWindowScroll(window);
                this.addressLineMgr.changeState(
                    new AddressLineState(
                        "",
                        $.proxy(this.hideSlideshow, this),
                        true
                    )
                );
                $(document).prop('title', 'תנ"ך על הפרק - פרשנים קודמים');
            }
            public isSlideshowShowed() :boolean {
                return this.$parshanimSlideshowContainer.hasClass('animated rollIn');
            }
            public jumpToSlide(slideIdx): void {
                this.$parshanimSlideshow.slick("slickGoTo", slideIdx, true);
            }
            public refreshSlideDataTable($slide: JQuery) {
                var $currentDataTableContainer: JQuery = $slide.find('.data-table-container');
                // ---------- reattach bindings (for datagrid dist init) ----------
                $currentDataTableContainer.empty();
                var $dataTableClone: JQuery = IndexView.$dataTableTemplate.clone();
                $currentDataTableContainer.append($dataTableClone);
                var parshanVM : ParshanViewmodel = ko.dataFor($slide[0]);
                ko.cleanNode($dataTableClone[0]);
                ko.applyBindings(parshanVM, $dataTableClone[0]);
                // ----------------------------------------------------------------
                var optionsClone: DataTables.Settings = new Object(IndexView.datatableOptions);
                $dataTableClone.DataTable(optionsClone);
                //$dataTableClone.DataTable(IndexView.datatableOptions);
            }
            public showParshan(parshanVM: ParshanViewmodel, changeHistory : boolean = true) {
                if (!this.isSlideshowShowed()) {
                    this.showSlideshow();
                }
                var slideIdx: number = this.parshanimViewmodel.filteredParshanViewmodels.indexOf(parshanVM);
                if (!this.everShowed || this.getCurrentSlideIdx() !== slideIdx) {
                    this.jumpToSlide(slideIdx);
                }
                this.everShowed = true;
                if (changeHistory) {
                    var addressLineState = new AddressLineState(
                        parshanVM.name(),
                        $.proxy((parshanVM: ParshanViewmodel) => { this.showParshan(parshanVM, false); }, this, parshanVM),
                        true
                    )
                    if (this.addressLineMgr.states.length == 0) {
                        this.addressLineMgr.replaceState(addressLineState);
                    } else {
                        this.addressLineMgr.changeState(addressLineState);
                    }
                }
                $(document).prop('title', 'תנ"ך על הפרק - ' + parshanVM.name());
            }
            public showSlideshow() {
                this.$parshanimSlideshowContainer.show();
                this.$parshanimSlideshowContainer.removeClass('animated rollOut');
                this.$parshanimSlideshowContainer.addClass('animated rollIn');
                this.$parshanimSlideshowContainer.find('.hide-button').unbind('click');
                this.$parshanimSlideshowContainer.find('.hide-button').click($.proxy((ev) => {
                    this.hideSlideshow();
                }, this));
                Dsoft.View.preventWindowScroll(window);
            }
        }
    }
}