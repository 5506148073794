module Tanahpedia {
    export module Entries {
        export class EntriesViewModel {

            public entryViewmodels: Array<EntryViewmodel>;
            public selectedEntryViewmodel: ko.Observable<EntryViewmodel>;
            public type: string;

            public dataSource: EntriesDataSource;

            public entriesLoadedEventHandler: EventHandler;
            constructor(type?: string) {
                this.init(type);
            }
            init(type?: string) {
                this.type = type;
                this.dataSource = new EntriesDataSource();

                this.entryViewmodels = [];
                switch (this.type) {
                    case 'person':
                        this.selectedEntryViewmodel = ko.observable(new Person.EntryViewmodel);
                        break;
                    case 'place':
                        //this.selectedEntryViewmodel = ko.observable(new Place.EntryViewmodel);
                        break;
                    default:
                }



                this.dataSource.entriesLoadedEventHandler.register(this.entriesLoaded, this);

                this.entriesLoadedEventHandler = new EventHandler();
            }

            load(type: string): void {
                this.type = type;
                this.dataSource.loadEntriesAsync(type);
            }

            entriesLoaded(sender: any, e: { data: Array<Entry> }): void {
                var entries = e.data;
                this.entryViewmodels = [];
                switch (this.type) {
                    case 'person':
                        entries.forEach($.proxy(function (personEntry: Person.Entry, index: number, array: Array<Entry>): void {
                            this.entryViewmodels.push(new Person.EntryViewmodel(personEntry.name, personEntry.value));
                        }, this));
                        break;
                    case 'place':

                        break;
                    default:
                }

                this.entriesLoadedEventHandler.fire(new EventArgs(this, { data: this.entryViewmodels }));

            }
        }
    }
}