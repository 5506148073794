module Dsoft {
    export module Fullscreen {
        const fullscreenMethodsAndProps = {
            open: ['requestFullscreen', 'webkitRequestFullscreen', 'mozRequestFullScreen', 'msRequestFullscreen'],
            close: ['exitFullscreen', 'webkitExitFullscreen', 'mozCancelFullScreen', 'msExitFullscreen'],
            opened: ['fullscreenElement', 'webkitFullscreenElement', 'mozFullScreenElement', 'msFullscreenElement']
        };
        export function isInFullscreen(doc: Document) : boolean {
            return fullscreenMethodsAndProps.opened.map(function (value: string, index: number, array: string[]): boolean {
                return doc[value];
            }).reduce(function (a, b) { return (a ? true : false) || (b ? true : false); })
        }
        export function toggleFullscreen(doc: Document, elem: HTMLElement) : Promise<boolean> {
            if (!isInFullscreen(doc)) {
                fullscreenMethodsAndProps.open.forEach(function (value: string, index: number, array: string[]) {
                    if (elem[value]) elem[value]();
                });
                return new Promise<boolean>((resolve) => { resolve(true); });
            } else {
                fullscreenMethodsAndProps.close.forEach(function (value: string, index: number, array: string[]) {
                    if (doc[value]) doc[value]();
                });
                return new Promise<boolean>((resolve) => { resolve(false); });
            }
        }
    }
}