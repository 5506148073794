
module Tanahpedia {
    export module Entries {
        export class ImagesMetadataViewmodel {
            imagesMetadata: ko.ObservableArray<ImageMetadataViewmodel>;
            dataSource: ImagesMetadataDataSource;
            imagesMetadataLoadedEventHandler: EventHandler;
            constructor() {
                this.init();
            }
            init(imageMetadata: ImageMetadata = new ImageMetadata()) {
                this.imagesMetadata = ko.observableArray([]);
                this.imagesMetadataLoadedEventHandler = new EventHandler();
                this.dataSource = new ImagesMetadataDataSource();

                this.dataSource.imagesMetadataLoadedEventHandler.register(this.imagesMetadataLoaded, this);
            }
            load(entryName : string) {
                this.dataSource.loadImagesMetadataAsync(entryName);
            }
            imagesMetadataLoaded(sender: any, e: { data: ImageMetadata[] }): void {

                this.imagesMetadataLoadedEventHandler.fire(new EventArgs(this, { data: e.data }));
            }
        }
    }
}