module WebSite {
    export class IndexView {
        contact: Contact;
        registration: Registration;

        constructor(isPhone : boolean) {
            // preloader
            $(window).on('load', function () {
                $('.preloader').fadeOut(1000); // set duration in brackets    
            });

            $(function () {
                //new WOW().init();
                $('.templatemo-nav')['singlePageNav']({
                    offset: 70
                });


                $(document).ready(function () {
                    //$(".navbar-brand").unbind("click");
                    $("a").unbind("click");

                    /* Hide mobile menu after clicking on a link
                    -----------------------------------------------*/
                    $('.navbar-collapse a').click(function () {
                        $(".navbar-collapse")['collapse']('hide');
                    });
                    $('#learningScreenShotsCarousel')['slick']({
                        arrows: !isPhone,
                        dots: !isPhone,
                        infinite: true,
                        rtl: true
                    });
                    this.contact = new WebSite.Contact();
                    this.registration = new WebSite.Registration();
                });
            })
        }
    }
}