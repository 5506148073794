module Dsoft {
    export module Framework7 {
        export function onSwipeEdge($elem: JQuery, releaseMoreCallbackAndContext: { callback: any, context?: any }, releaseLessCallbackAndContext: { callback: any, context?: any }) : void {
            var swiperObserver: MutationObserver;
            $elem.swipeEdgeData = {
                lastXTranform: 0,
                releaseLessCallbackAndContext: releaseLessCallbackAndContext,
                releaseMoreCallbackAndContext: releaseMoreCallbackAndContext,
                swipeLessStarted: false,
                swipeMoreStarted: false,
            };
            swiperObserver = new MutationObserver($.proxy(($elem: JQuery, mutations: MutationRecord[], observer: MutationObserver) => {
                var xTransform = $elem.getTransform().x;
                var width = Math.round($elem.width());
                if (xTransform > 0 && xTransform < width) {
                    $elem.swipeEdgeData.swipeLessStarted = false;
                    $elem.swipeEdgeData.swipeMoreStarted = false;
                } else if (xTransform < 0) {
                    $elem.swipeEdgeData.swipeLessStarted = true;
                } else if (xTransform > width) {
                    $elem.swipeEdgeData.swipeMoreStarted = true;
                } else if (xTransform == 0 && $elem.swipeEdgeData.swipeLessStarted) {
                    if (Math.abs($elem.swipeEdgeData.lastXTranform - xTransform) > 3) {
                        (<Function>$elem.swipeEdgeData.releaseLessCallbackAndContext.callback).apply(<Function>$elem.swipeEdgeData.releaseLessCallbackAndContext.context);
                    }
                    $elem.swipeEdgeData.swipeLessStarted = false;
                } else if (Math.abs(xTransform - width) < 2 && $elem.swipeEdgeData.swipeMoreStarted) {
                    if (Math.abs($elem.swipeEdgeData.lastXTranform - xTransform) > 3) {
                        (<Function>$elem.swipeEdgeData.releaseMoreCallbackAndContext.callback).apply(<Function>$elem.swipeEdgeData.releaseLessCallbackAndContext.context);
                    }
                    $elem.swipeEdgeData.swipeMoreStarted = false;
                }
                $elem.swipeEdgeData.lastXTranform = xTransform;
            }, this, $elem));
            swiperObserver.observe($elem[0], {
                attributes: true,
                attributeFilter: ['style']
            });
        }
    }
}
