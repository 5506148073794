/// <reference path="../../scripts/dsoft/dsoft.address-line.ts" />
module AlHaperek {
    export class AddressLineState extends Dsoft.AddressLine.AddressLineState {
        articleId: number;
        perekId: number;
        text: string;
        constructor(perekId?: number, articleId?: number, text?: string, callback?: () => Promise<any>, cyclic: boolean = false) {
            super(callback, cyclic);
            this.articleId = articleId;
            this.perekId = perekId;
            this.text = text;
        }
        translateToRelativePath(): string {
            var relativePath: string = "";
            if (typeof this.perekId == 'undefined') {
                relativePath = "";
            } else if (typeof this.articleId == 'undefined' && !this.isTextLegal()) {
                relativePath = this.perekId.toString();
            } else if (typeof this.articleId !== 'undefined') {
                relativePath = this.perekId.toString() + Dsoft.AddressLine.SEPARATOR + this.articleId.toString();
            } else if (typeof this.text !== 'undefined') {
                relativePath = this.perekId.toString() + Dsoft.AddressLine.SEPARATOR + this.text;
            }
            return relativePath;
        }
        isTextLegal() {
            return (typeof this.text !== 'undefined' && this.text != "");
        }
    }
}