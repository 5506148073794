module AlHaperek {
    export class PasukViewModel {
        pasukNum: number;
        perekViewModel: PerekViewModel;
        perushimViewModel: PerushimOfPasukViewModel;

        constructor(perekViewModel: PerekViewModel) {
            this.init(perekViewModel);
        }

        public init(perekViewModel: PerekViewModel) {
            this.perekViewModel = perekViewModel;

            this.perushimViewModel = new PerushimOfPasukViewModel(perekViewModel, this);
        }
        public load(pasukNum: number, perushViewModels?:PerushViewmodel[]) { // TODO: : Promise<PasukViewModel>
            this.pasukNum = pasukNum;
            this.perushimViewModel.perushViewmodels(perushViewModels);
            if (typeof perushViewModels === 'undefined') {
                this.perushimViewModel.progressiveLoad(this.perekViewModel);
            } else {
                this.perushimViewModel.validatePrushVMs(perushViewModels);
            }
        }
    }
}