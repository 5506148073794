module AlHaperek {
    export class PerushimGroupedByParshanimViewmodel {
        public parshan: ko.Observable<AlHaperek.ParshanViewmodel>;
        public parshan2: ko.Observable<AlHaperek.ParshanViewmodel>;
        public multipleParshanim: ko.Observable<boolean>;
        public perushViewmodels: ko.ObservableArray<AlHaperek.PerushViewmodel>;

        constructor(parshanim: AlHaperek.ParshanViewmodel[], perushViewmodels: AlHaperek.PerushViewmodel[]) {
            this.parshan = ko.observable(parshanim[0]);
            this.perushViewmodels = ko.observableArray(perushViewmodels);
            if (parshanim.length > 1) {
                this.parshan2 = ko.observable(parshanim[1]);
                this.multipleParshanim = ko.observable(true);
            } else {
                this.multipleParshanim = ko.observable(false);
            }
        }
    }
}
