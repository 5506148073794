//module Tanahpedia {
//    export module Entries {
//        export module Place {
//            export class EntryViewmodel implements Entries.EntryViewmodel {

//                public firstOccurance: ko.Observable<string>;
//                public firstOccuranceHref: ko.Observable<string>;
//                public name: ko.Observable<string>;
//                public recognitions: ko.ObservableArray<RecognitionViewmodel>;
//                public selectedRecognition: ko.Observable<RecognitionViewmodel>;
//                public suspendSaving: boolean;
//                public type: ko.Observable<string>;
//                public value: ko.Observable<string>;

//                dataSource: EntryDataSource;

//                public entryChangedEventHandler: EventHandler;
//                public entryLoadedEventHandler: EventHandler;
//                constructor(name?: string, value?: string) {
//                    this.init(name, value);
//                }
//                init(name?: string, value?: string) {
//                    var entry = new Place.Entry;

//                    this.firstOccurance = ko.observable(entry.firstOccurance);
//                    this.firstOccuranceHref = ko.observable(entry.firstOccuranceHref);
//                    this.name = ko.observable(typeof name !== 'undefined' ? name : entry.name);
//                    this.recognitions = ko.observableArray([]);
//                    this.selectedRecognition = ko.observable(new RecognitionViewmodel);
//                    this.type = ko.observable("place");
//                    this.value = ko.observable(typeof value !== 'undefined' ? value : entry.value);



//                    this.entryChangedEventHandler = new EventHandler();

//                    this.dataSource = new EntryDataSource();

//                    this.entryLoadedEventHandler = new EventHandler();

//                    //this.FEILD.subscribe($.proxy(function () { this.method() }, this)); ...

//                    this.dataSource.entryLoadedEventHandler.register(this.entryLoaded, this);
//                    this.dataSource.entryExtendedLoadedEventHandler.register(this.entryExtendedLoaded, this);
//                }


//                load() {
//                    this.dataSource.loadEntry(this.name(), this.type());
//                }

//                loadExtended() {
//                    this.dataSource.loadEntryExtended(this.name(), this.type());
//                }


//                toEntry(): Place.Entry {
//                    return Place.EntryViewmodel.viewmodelToModel(this);
//                }

//                entryExtendedLoaded(sender: any, e: { data: string }) {
//                    //ResourcesPool.ViewmodelPool.contentToEditCallback = $.proxy(function (data) { this.saveExtended(data); }, this);
//                    //ResourcesPool.ViewmodelPool.contentToEditViewmodel(e.data);
//                }
//                entryLoaded(sender: any, e: { data: Place.Entry }) {
//                    var personEntry = e.data;

//                    this.fillWithModel(personEntry);
//                    this.entryLoadedEventHandler.fire(new EventArgs(this, e));
//                }
//                fillWithModel(placeEntry: Place.Entry) {
//                    var recognitions = RecognitionViewmodel.modelsToViewmodels(placeEntry.recognitions, { handler: this.entryChanged, owner: this });
//                    this.firstOccurance(placeEntry.firstOccurance);
//                    this.firstOccurance(placeEntry.firstOccuranceHref);
//                    this.name(placeEntry.name);
//                    this.recognitions(recognitions);
//                    if (recognitions.length > 0)
//                        this.selectedRecognition(recognitions[0]);
//                    this.value(placeEntry.value);
//                }
//                public static viewmodelToModel(viewmodel: Place.EntryViewmodel): Place.Entry {
//                    var model = new Place.Entry();
//                    model.firstOccurance = viewmodel.firstOccurance();
//                    model.firstOccuranceHref = viewmodel.firstOccuranceHref();
//                    model.name = viewmodel.name();
//                    model.recognitions = RecognitionViewmodel.viewmodelsToModels(viewmodel.recognitions());
//                    model.type = viewmodel.type();
//                    model.value = viewmodel.value();

//                    return model;

//                }
//                public entryChanged() {
//                    this.entryChangedEventHandler.fire(new EventArgs(this, { data: null }));
//                }
//            }
//        }
//    }
//}