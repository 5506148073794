module Tanahpedia {
    export module Entries {
        export module Person {
            export class EntryViewmodel implements Entries.EntryViewmodel {
                public family: FamilyViewmodel;
                public firstOccurance: ko.Observable<string>;
                public firstOccuranceHref: ko.Observable<string>;
                public gendre: ko.Observable<string>;
                public life: LifeViewmodel;
                public name: ko.Observable<string>;
                public reincarnation: ReincarnationViewmodel;
                public suspendSaving: ko.Observable<boolean>;
                public type: ko.Observable<string>;
                public value: ko.Observable<string>;


                dataSource: EntryDataSource;
                public entryLoadedEventHandler: EventHandler;
                public entryChangedEventHandler: EventHandler;
                constructor(name?: string, value?: string) {
                    this.init(name, value);
                }
                init(name?: string, value?: string) {
                    var entry = new Person.Entry;

                    this.family = new FamilyViewmodel(entry.family);
                    this.firstOccurance = ko.observable(entry.firstOccurance);
                    this.firstOccuranceHref = ko.observable(entry.firstOccuranceHref);
                    this.gendre = ko.observable(entry.gendre);
                    this.life = new LifeViewmodel(entry.life);
                    this.name = ko.observable(typeof name !== 'undefined' ? name : entry.name);
                    this.reincarnation = new ReincarnationViewmodel(entry.reincarnation);

                    this.type = ko.observable(Type.person);
                    this.value = ko.observable(typeof value !== 'undefined' ? value : entry.value);

                    this.dataSource = new EntryDataSource();

                    this.entryLoadedEventHandler = new EventHandler();


                    this.dataSource.entryLoadedEventHandler.register(this.entryLoaded, this);
                }

                clone(): EntryViewmodel {
                    var replica = new EntryViewmodel();
                    replica.fillWithModel(EntryViewmodel.viewmodelToModel(this));
                    return replica;
                }

                load() {
                    this.dataSource.loadEntry(this.name(), this.type());
                }
                toEntry(): Person.Entry {
                    return Person.EntryViewmodel.viewmodelToModel(this);
                }
                entryLoaded(sender: any, e: { data: Person.Entry }) {
                    var personEntry = e.data;

                    this.fillWithModel(personEntry);
                    this.entryLoadedEventHandler.fire(new EventArgs(this, e));
                }
                fillWithModel(personEntry: Person.Entry) {

                    var familyVm = new FamilyViewmodel(personEntry.family);
                    var lifeVm = new LifeViewmodel(personEntry.life);
                    var reincarnationVm = new ReincarnationViewmodel(personEntry.reincarnation);


                    this.family = familyVm;
                    this.firstOccurance(personEntry.firstOccurance);
                    this.firstOccuranceHref(personEntry.firstOccuranceHref);
                    this.gendre(personEntry.gendre);
                    this.life = lifeVm;
                    this.name(personEntry.name);
                    this.reincarnation = reincarnationVm;
                    this.value(personEntry.value);
                }
                public static viewmodelToModel(viewmodel: EntryViewmodel): Person.Entry {
                    var model = new Person.Entry();
                    model.family = viewmodel.family.toModel();
                    model.firstOccurance = viewmodel.firstOccurance();
                    model.firstOccuranceHref = viewmodel.firstOccuranceHref();
                    model.gendre = viewmodel.gendre();
                    model.life = viewmodel.life.toModel();
                    model.name = viewmodel.name();
                    model.reincarnation = viewmodel.reincarnation.toModel();
                    model.type = viewmodel.type();
                    model.value = viewmodel.value();

                    return model;

                }
            }
        }
    }
}