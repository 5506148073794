module AlHaperek {
    export class ArticleDataSource {
        public static articles: AlHaperek.Article[] = [];
        public static articleContents: string[] = [];
        
        static appendArticles(articles: Article[]) {
            articles.forEach((value: Article, index: number, array: Article[]) => {
                ArticleDataSource.articles[value.id] = value;
            });
        }
        static appendArticlesContent(articlesContent: string[]) {
            articlesContent.forEach((value: string, index: number, array: string[]) => {
                ArticleDataSource.articleContents[index] = value;
            });
        }

        loadArticle(articleId: number): Promise<AlHaperek.Article> {
            if (typeof ArticleDataSource.articles[articleId] !== 'undefined' && GlobalSettings.cacheEnabled) {
                return Promise.resolve(ArticleDataSource.articles[articleId]);
            }

            var settings = { async: true, type: 'GET', url: Conn.remotePath + 'get_article.php', data: { 'articleId': articleId }, dataType: 'json' };

            return Dsoft.Ajax.commonAjaxCall<Article, Article>(settings, (message: Article) => {
                ArticleDataSource.articles[articleId] = message;
                return message;
            });
        }
        loadArticleContent(articleId: number, perekId: number): Promise<string> {
            if (typeof ArticleDataSource.articleContents[articleId] !== 'undefined' && GlobalSettings.cacheEnabled) {
                return Promise.resolve(ArticleDataSource.articleContents[articleId]);
            }

            var settings = { async: true, type: 'GET', url: Conn.remotePath + 'get_article_content.php', data: { 'articleId': articleId, 'perekId': perekId }, dataType: 'json' };

            return Dsoft.Ajax.commonAjaxCall<string, string>(settings, (message: string) => {
                ArticleDataSource.articleContents[articleId] = message;
                return message;
            });
        }
    }
}