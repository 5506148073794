/// <reference path="../typings/knockout/knockout.d.ts" />
/// <reference path="../typings/dsoft/dsoft.knockout.d.ts" />
(function () {
    if (typeof ko === 'undefined') return;
    ko.bindingHandlers.fadeDisplay = <ko.BindingHandler<boolean>>{
        init: function (element: HTMLElement, valueAccessor: () => boolean, allBindings: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext<any>) {
            //// Initially set the element to be instantly visible/hidden depending on the value
            var value = valueAccessor();
            //$(element).toggle(ko.utils.unwrapObservable(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
            if (ko.utils.unwrapObservable(value)) {
                $(element).show();
                $(element).addClass('fadeDisplay-showed');
                $(element).removeClass('fadeDisplay-hidden');
            } else {
                $(element).hide();
                $(element).addClass('fadeDisplay-hidden');
                $(element).removeClass('fadeDisplay-showed');
            }
        },
        update: function (element: HTMLElement, valueAccessor: () => boolean, allBindings: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext<any>) {
            // Whenever the value subsequently changes, slowly fade the element in or out
            var value = valueAccessor();
            if (ko.utils.unwrapObservable(value)) {
                $(element).fadeIn();
                $(element).addClass('fadeDisplay-showed');
                $(element).removeClass('fadeDisplay-hidden');
            } else {
                $(element).fadeOut($(element).data('fade-out-duration') || 200);
                $(element).addClass('fadeDisplay-hidden');
                $(element).removeClass('fadeDisplay-showed');
            }

        }
    };
    ko.bindingHandlers.holdedDisplay = <ko.BindingHandler<boolean>>{
        init: function (element: HTMLElement, valueAccessor: () => boolean, allBindings: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext<any>) {
            //// Initially set the element to be instantly visible/hidden depending on the value
            var value = valueAccessor();
            //$(element).toggle(ko.utils.unwrapObservable(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
            if (ko.utils.unwrapObservable(value)) {
                $(element).show();
                $(element).addClass('fadeDisplay-showed');
                $(element).removeClass('fadeDisplay-hidden');
            } else {
                $(element).hide();
                $(element).addClass('fadeDisplay-hidden');
                $(element).removeClass('fadeDisplay-showed');
            }
        },
        update: function (element: HTMLElement, valueAccessor: () => boolean, allBindings: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext<any>) {
            // Whenever the value subsequently changes, slowly fade the element in or out
            var value = valueAccessor();

            if (ko.utils.unwrapObservable(value)) {
                setTimeout($.proxy(function ($elem) {
                    $elem.show();
                    $(element).addClass('fadeDisplay-showed');
                    $(element).removeClass('fadeDisplay-hidden');
                }, this), 200, $(element))
            } else {
                $(element).addClass('fadeDisplay-hidden');
                $(element).removeClass('fadeDisplay-showed');

                $(element).hide();
            }
        }
    };
    ko.bindingHandlers.intuitiveClick = <ko.BindingHandler<Function>>{
        init: (element: HTMLElement, valueAccessor: () => Function, allBindings: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext<any>) => {
            var eventHandler = ko.utils.unwrapObservable(valueAccessor());
            $(element).bindToIntuitiveClick(eventHandler);
        },
    };
    ko.bindingHandlers.loadTemplate = <ko.BindingHandler<string>>{
        init: (element: HTMLElement, valueAccessor: () => string, allBindings: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext<any>) => {
            var url = ko.utils.unwrapObservable(valueAccessor());
            var allBindingsArr = allBindings();
            delete allBindingsArr['loadTemplate'];
            Dsoft.View.injectTemplate(element, url, () => { });
            ko.cleanNode(element);
            if (typeof allBindingsArr['callAfterInjectBeforeBind'] !== 'undefined') {
                $.proxy(allBindingsArr['callAfterInjectBeforeBind'], bindingContext.$data)();
                delete allBindingsArr['callAfterInjectBeforeBind'];
            }
            ko.applyBindingsToNode(element, allBindingsArr, bindingContext);
        },
    };
    //ko.bindingHandlers.f7sliderValue = <ko.BindingHandler<number>>{
    //    init: function (element: HTMLElement, valueAccessor: () => number | ko.Observable<number>, allBindings: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext<any>) {
    //        var maybeObservable = valueAccessor();
    //        var value = ko.utils.unwrapObservable(maybeObservable);
    //        var cssSelectorGenerator = new CssSelectorGenerator;
    //        var selector = cssSelectorGenerator.getSelector(element);
    //        var allBindingsArr = allBindings();
    //        var app = allBindingsArr['f7app'];
    //        var range = app['range'].get(selector);
    //        range.setValue(value);
    //        if (ko.isObservable(maybeObservable)) {
    //            range.$el.on('range:change', (e) =>
    //                (<ko.Observable<number>>maybeObservable)($(e.target).find('input').val())
    //            );
    //        }
    //    },
    //};
    ko.bindingHandlers.f7radioGroupValue = <ko.BindingHandler<any>>{
        init: function (element: HTMLElement, valueAccessor: () => any | ko.Observable<any>, allBindings: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext<any>) {
            var $el = $(element);
            var maybeObservable = valueAccessor();
            var value = ko.utils.unwrapObservable(maybeObservable);
            var allBindingsArr = allBindings();
            var f7radioGroupName = ko.utils.unwrapObservable(allBindingsArr['f7radioGroupName']);
            $('input[type="radio"][name="newsletter"]')

            var $radios = $el.find('input[type="radio"][name="' + f7radioGroupName + '"]');
            var $radiosThatMatchedValue = $radios.filter('input[value="' + value + '"]');
            $radiosThatMatchedValue.prop("checked", true);
            if (ko.isObservable(maybeObservable)) {
                $radios.on('change', (e) => {
                    (<ko.Observable<number>>maybeObservable)($(e.target).val());
                });
            }
        },
        update: function (element: HTMLElement, valueAccessor: () => any | ko.Observable<any>, allBindings: ko.AllBindings, viewModel: any, bindingContext: ko.BindingContext<any>) {
            var $el = $(element);
            var maybeObservable = valueAccessor();
            var value = ko.utils.unwrapObservable(maybeObservable);
            var allBindingsArr = allBindings();
            var f7radioGroupName = ko.utils.unwrapObservable(allBindingsArr['f7radioGroupName']);
            $('input[type="radio"][name="newsletter"]')

            var $radios = $el.find('input[type="radio"][name="' + f7radioGroupName + '"]');
            var $radiosThatMatchedValue = $radios.filter('input[value="' + value + '"]');
            $radiosThatMatchedValue.prop("checked", true);
        },
    };
    ko['reApplyBindings'] = function <T = any>(bindingContext: T | ko.BindingContext<T>, rootNode?: Node | null, extendCallback?: ko.BindingContextExtendCallback<T>): void {
        ko.cleanNode(rootNode);
        ko.applyBindings(bindingContext, rootNode, extendCallback);
    }
})();

