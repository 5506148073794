module App_V3 {
    export module AppAlHaperek {
        export class RecursivePerekSourceAdditional {
            additional: Shared.NameAndValue;
            perakim: Shared.NamesAndValues = new Shared.NamesAndValues();
        }
        export class RecursivePerekSourceSefer {
            sefer: Shared.NameAndValue;
            additionals: Array<RecursivePerekSourceAdditional> = [];
        }
        export class RecursivePerekSourceGroup {
            public group: Shared.NameAndValue;
            public sefarim: Array<RecursivePerekSourceSefer> = [];
        }
        export class RecursivePerekSource {
            public groups: Array<RecursivePerekSourceGroup> = [];
        }
        export class FlatPerekSource {
            public groups: Shared.NamesAndValues;
            public sefarim: Shared.NamesAndValues;
            public additionals: Shared.NamesAndValues;
            public perakim: Shared.NamesAndValues;
        }
        export class PerekSourceQualifier {
            public whole: RecursivePerekSource = new RecursivePerekSource();
            constructor() {
                AlHaperek.SefarimData.sefarimGroups.forEach((group, groupIdx, groups) => {
                    var recursiveGroup = new RecursivePerekSourceGroup();
                    recursiveGroup.group = new Shared.NameAndValue(group.header, groupIdx.toString());
                    for (var seferId = group.from; seferId <= group.to; seferId++) {
                        var recursiveSefer = new RecursivePerekSourceSefer();
                        var sefer = AlHaperek.SefarimData.sefarim[seferId];
                        recursiveSefer.sefer = new Shared.NameAndValue(sefer.name, seferId.toString());

                        var maybeAdditionals = sefer.tanahUsName;
                        if (typeof maybeAdditionals === 'string') {
                            var recursiveAdditional1 = new RecursivePerekSourceAdditional();
                            recursiveAdditional1.additional = new Shared.NameAndValue("@", "null");
                            recursiveSefer.additionals.push(recursiveAdditional1);
                            for (var perek = 1; perek <= AlHaperek.PerakimData.perakimOfBooks[seferId]; perek++) {
                                recursiveAdditional1.perakim.values.push(perek.toString());
                                recursiveAdditional1.perakim.names.push(Dsoft.GimatryHelper.toLetters(perek));
                            }
                            var recursiveAdditional2 = new RecursivePerekSourceAdditional();
                            recursiveAdditional2.additional = new Shared.NameAndValue("@", "null");
                            recursiveSefer.additionals.push(recursiveAdditional2);
                        } else {
                            // sort additionals naturally, except ezra
                            var additionals = Object.keys(maybeAdditionals).sort((a1, a2) => (a1 == '50' ? 1 : (a1 == '70' ? -1 : (parseInt(a1) - parseInt(a2)))));
                            for (var additionalIdx = 0; additionalIdx < additionals.length; additionalIdx++) {
                                var recursiveAdditional = new RecursivePerekSourceAdditional();
                                var additional = additionals[additionalIdx];
                                recursiveAdditional.additional = new Shared.NameAndValue(Dsoft.GimatryHelper.toLetters(parseInt(additional)), additional);
                                for (var perek = 1; perek <= AlHaperek.PerakimData.perakimOfBooks[seferId][additional]; perek++) {
                                    recursiveAdditional.perakim.values.push(perek.toString());
                                    recursiveAdditional.perakim.names.push(Dsoft.GimatryHelper.toLetters(perek));
                                }
                                recursiveSefer.additionals.push(recursiveAdditional);
                            }
                        }
                        recursiveGroup.sefarim.push(recursiveSefer);
                    }
                    this.whole.groups.push(recursiveGroup);
                });

            }
            public getFlatSource(groupName: string = "", seferVal: string = "", additionalVal: string = ""): FlatPerekSource {
                var result: FlatPerekSource = new FlatPerekSource();
                result.groups = new Shared.NamesAndValues(this.whole.groups.map((g, i, gs) => g.group.name), this.whole.groups.map((g, i, gs) => g.group.value));
                var sefarim = this.whole.groups.find((g, i, gs) => g.group.name == groupName).sefarim;
                result.sefarim = new Shared.NamesAndValues(sefarim.map((s, i, ss) => s.sefer.name), sefarim.map((s, i, ss) => s.sefer.value));
                if (seferVal == "") {
                    seferVal = sefarim[0].sefer.value;
                }
                var additionals = sefarim.find((s, i, ss) => s.sefer.value == seferVal).additionals;
                result.additionals = new Shared.NamesAndValues(additionals.map((a, i, as) => a.additional.name), additionals.map((a, i, as) => a.additional.value));
                if (additionalVal == "") {
                    additionalVal = additionals[0].additional.value;
                }
                result.perakim = additionals.find((a, i, as) => a.additional.value == additionalVal).perakim;
                return result;
            }
            //public getQualifiedPerekSource(group: string = "", sefer : string = "", additional: string = "") : QualifiedPerekSource {
            //    var result: QualifiedPerekSource;
                
            //    for (var i = 0; i < length; i++) {

            //    }
            //    if (group = "") {

            //    }
            //}
        }
    }
}