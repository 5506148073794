module AlHaperek {
    export class DedicationsDataSource {
        static dedications: Map<number, Dedication> = new Map();
        static perakimDedications: Map<number, number[]> = new Map();
        static fillDedications(dedications: Array<Dedication>) {
            dedications.forEach((dedication, index, dedications) => {
                DedicationsDataSource.dedications.set(dedication.id, dedication);
            });
        }
        static fillPerakimDedications(perakimDedications: Array<number[]>) {
            perakimDedications.forEach((perekDedications, index, perakimDedications) => {
                DedicationsDataSource.perakimDedications.set(index, perekDedications);
            });
        }
        static getDedicationsOfPerek = function (perekId: number): Array<Dedication> {
            if (!DedicationsDataSource.perakimDedications.has(perekId))
                return [];
            return DedicationsDataSource.perakimDedications.get(perekId).map((dedicationId, index, dedicationIds) => DedicationsDataSource.dedications.get(dedicationId));
        };
        loadDedications(): Promise<{ dedications: Map<number, Dedication>, perakimDedications: Map<number, number[]> }> {
            if (DedicationsDataSource.dedications.keys.length > 0) {
                return Promise.resolve({ dedications: DedicationsDataSource.dedications, perakimDedications: DedicationsDataSource.perakimDedications });
            }

            var settings = { async: true, type: 'GET', url: Conn.remotePath + 'get_dedications.php', data: { 'all': true }, dataType: 'json' };

            return Dsoft.Ajax.commonAjaxCall<{ dedications: Map<number, Dedication>, perakimDedications: Map<number, number[]> }, { dedications: Dedication[], perakimDedications: Array<number[]> }>(settings, (message: { dedications: Dedication[], perakimDedications: Array<number[]> }) => {
                DedicationsDataSource.fillDedications(message.dedications);
                DedicationsDataSource.fillPerakimDedications(Dsoft.GeneralHelper.keyedObjectToArray(message.perakimDedications));
                return { dedications: DedicationsDataSource.dedications, perakimDedications: DedicationsDataSource.perakimDedications };
            });
        }
    }
}