module Tanahpedia {
    export module Entries {
        export module Person {
            export class FamilyTreeNode {
                children: ko.ObservableArray<FamilyTreeNode>;
                gendre: ko.Observable<string>;
                nameAndValue: NameAndValueViewmodel;
                spouses: ko.ObservableArray<FamilyTreeNode>;

                clickedEventHandler: EventHandler;
                selectedEventHandler: EventHandler;

                constructor(nameAndValue: NameAndValueViewmodel, gendre: string, children: FamilyTreeNode[] = [], spouses?: FamilyTreeNode[]) {
                    this.nameAndValue = nameAndValue;
                    this.children = ko.observableArray(typeof children === undefined ? [] : children);
                    this.gendre = ko.observable(gendre);
                    this.spouses = ko.observableArray(typeof spouses === undefined ? [] : spouses);

                    this.clickedEventHandler = new EventHandler();
                    this.selectedEventHandler = new EventHandler();
                }
                isExist(): boolean {
                    return this.nameAndValue.name.length !== 0;
                }
                isKnown(): boolean {
                    return this.nameAndValue.value.length !== 0;
                }
                clicked(event: MouseEvent) {
                    var callback: Function = $.proxy(
                        () => this.clickedEventHandler.fire(new EventArgs(this, { data: this })), this);
                    Dsoft.Ajax.intuitiveClick(event, callback);
                    // an horrible knockout bug:
                    event.preventDefault = undefined;
                    event.stopPropagation = undefined;


                }
                selected(event: MouseEvent) {
                    if ($(event.target).is('a')) return; // if clicked was supposed to be triggered
                    var callback: Function = $.proxy(
                        () => this.selectedEventHandler.fire(new EventArgs(this, { data: this })), this);
                    Dsoft.Ajax.intuitiveClick(event, callback);
                    // an horrible knockout bug:
                    event.preventDefault = undefined;
                    event.stopPropagation = undefined;
                }
                traverse(): Array<FamilyTreeNode> {
                    var result: Array<FamilyTreeNode>;
                    result = [this];
                    result = result.concat(this.spouses());
                    this.children().forEach((child, i, arr) => result = result.concat(child.traverse()));
                    return result;
                }
            }
        }
    }
}