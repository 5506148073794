module AlHaperek {
    export class PerushViewmodel {
        public dataSource: PerushDataSource;

        public id: ko.Observable<number>;
        public hebName: ko.Observable<string>;
        public html: ko.Observable<string>;
        public href: ko.Computed<string>;
        public name: ko.Observable<string>;
        public pesukimNums: Array<number>;
        public safeUrlName: ko.Computed<string>;

        public parshan: ParshanViewmodel;
        public parshan2: ParshanViewmodel;
        public perekViewModel: PerekViewModel;

        $perushText: JQuery;


        public perushLoadedEventHandler: EventHandler;

        constructor(perekViewModel?: PerekViewModel, perush?: Perush) {
            this.init(perekViewModel, perush);
        }
        init(perekViewModel, perush?: Perush) {
            this.perekViewModel = perekViewModel;
            this.html = ko.observable("");
            this.pesukimNums = [];
            if (typeof perush === 'undefined') {
                this.id = ko.observable(-1);
                this.hebName = ko.observable('אין מידע זמין');
                this.name = ko.observable('אין מידע זמין');
                this.parshan = new ParshanViewmodel();
            } else {
                this.id = ko.observable(perush.id);
                this.hebName = ko.observable(perush.hebName);
                this.name = ko.observable(perush.name);
                this.parshan = ParshanViewmodel.modelToViewmodel(ParshanimData.parshanim.find(p => p.name == perush.parshanName));
                if (typeof perush.parshanName2 !== 'undefined' && perush.parshanName2 !== '')
                    this.parshan2 = ParshanViewmodel.modelToViewmodel(ParshanimData.parshanim.find(p => p.name == perush.parshanName2));
            }
            this.href = ko.pureComputed(() => {
                if (typeof this.perekViewModel == 'undefined') {
                    return '';
                }
                return Conn.localPath + this.perekViewModel.perekId() + "/" + this.safeUrlName();
            });
            this.safeUrlName = ko.computed(() => { return encodeURI(this.hebName()); });
            this.dataSource = new PerushDataSource();

            this.perushLoadedEventHandler = new EventHandler();
        }

        load(): Promise<PerushViewmodel> {
            return new Promise<PerushViewmodel>((resolve: (value?: PerushViewmodel | PromiseLike<PerushViewmodel>) => void, reject: (reason: any) => void ) => {
                this.dataSource.loadPerush(this.perekViewModel.toModel(), this.toModel())
                    .then(this.processPerushHtml.bind(this))
                    .then(() => resolve(this));
            });
        }
        toModel(): Perush {
            return new Perush(this.id(), this.hebName(), this.name(), this.parshan.name(), typeof this.parshan2 !== 'undefined' ? this.parshan2.name() : undefined);
        }

        processPerushHtml(perushHtml: string): PerushViewmodel {
            this.pesukimNums = [];
            this.$perushText = $('<div>').html(perushHtml);
            var $pesukimArr = this.$perushText.find(">span>span");
            $pesukimArr.each((index: number, pasuk: Element) => {
                if ($(pasuk).is(':empty') || $(pasuk).text() == "") return;
                $('<span>', { 'class': 'pasuk-num', 'data-pasuk-num': index + 1 }).html((index !== 0 ? " " : "") + Dsoft.GimatryHelper.toLetters(index + 1) + " ").insertBefore(pasuk);
                $('<br>').insertAfter(pasuk);
                this.pesukimNums.push(index + 1);
            });
            $pesukimArr.find(">span:not(:first-child)").prepend(" ");
            this.html(this.$perushText.html());
            this.perushLoadedEventHandler.fire(new EventArgs(this, { data: perushHtml })); // TODO: replace caller to promise
            return this;
        }
        public static mapper(value: Perush, index: number, array: Perush[]): PerushViewmodel { return PerushViewmodel.modelToViewmodel(value); }
        public static modelToViewmodel(model: Perush): PerushViewmodel {
            var vm: PerushViewmodel;
            if (typeof model !== 'undefined')
                vm = new PerushViewmodel(undefined, model);
            else
                vm = new PerushViewmodel();
            return vm;
        }
        public static modelsToViewmodels(models: Perush[]): PerushViewmodel[] {
            var vms: PerushViewmodel[];
            if (typeof models !== 'undefined')
                vms = models.map(PerushViewmodel.mapper);
            else
                vms = [];
            return vms;
        }

    }
}