module App_V3 {
    export module AppAlHaperek {
        export class ArticlesView {
            public perekViewmodel: AlHaperek.PerekViewModel;
            public articlesViewmodel: AlHaperek.ArticlesViewmodel;
            public systemMessagesViewModel: AlHaperek.SystemMessagesViewModel;
            public articlesGroupedByAuthors: ko.ObservableArray<ArticlesGroupedByAuthorViewmodel>;

            constructor(perekViewmodel: AlHaperek.PerekViewModel) {
                this.init(perekViewmodel);

            }
            init(perekViewmodel: AlHaperek.PerekViewModel) {
                this.perekViewmodel = perekViewmodel;
                this.perekViewmodel.loadedThens.push({ onfulfilled: this.processPerek.bind(this) });
                this.articlesViewmodel = new AlHaperek.ArticlesViewmodel();
                this.systemMessagesViewModel = new AlHaperek.SystemMessagesViewModel();
                this.systemMessagesViewModel.load();
                this.articlesGroupedByAuthors = ko.observableArray([]);
            }

            processArticles(articleViewmodels: ko.ObservableArray<AlHaperek.ArticleViewmodel>) {
                this.articlesGroupedByAuthors.removeAll();
                articleViewmodels().forEach((articleVM: AlHaperek.ArticleViewmodel, index: number, array: AlHaperek.ArticleViewmodel[]) => {
                    var groupOfSameAuthorAsArticleVM: ArticlesGroupedByAuthorViewmodel = this.articlesGroupedByAuthors().find((group: ArticlesGroupedByAuthorViewmodel, i: number, groups: ArticlesGroupedByAuthorViewmodel[]) => group.author().id() == articleVM.author().id(), this);
                    if (groupOfSameAuthorAsArticleVM == undefined) {
                        groupOfSameAuthorAsArticleVM = new ArticlesGroupedByAuthorViewmodel(articleVM.author(), [articleVM]);
                        this.articlesGroupedByAuthors.push(groupOfSameAuthorAsArticleVM);
                    } else {
                        groupOfSameAuthorAsArticleVM.articleViewmodels.push(articleVM);
                    }
                });
            }

            selectArticle(articleVM: AlHaperek.ArticleViewmodel): void {
                var content = this.articlesViewmodel.articlesContent[articleVM.id()];
                if (content == "") {
                    articleVM.loadContent();
                } else {
                    articleVM.fillContent(content);
                }
                ResourcesPool.ViewPool.articleView.selectArticle(articleVM);
            }
            selectRabbi(rabbiVM: AlHaperek.RabbiViewmodel) {
                ResourcesPool.ViewPool.rabbiView.selectRabbi(rabbiVM, true);
            }


            processPerek(perekViewModel: AlHaperek.PerekViewModel) {
                this.articlesViewmodel.load(perekViewModel.perekId()).then(this.processArticles.bind(this));
            }

        }
    }
}