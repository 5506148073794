module App_V3 {
    export module AppPreferences {
        export class SubPreferencesView {
            preferencesViewmodel: PreferencesViewmodel;
            constructor(preferencesViewmodel: PreferencesViewmodel) {
                this.preferencesViewmodel = preferencesViewmodel;
            }
            renderBeforeBind() {
            }
        }
    }
}