/// <reference path="../../../view/base-view.ts" />
module App_V3 {
    export module AppAlHaperek {
        export class PerushView extends BaseView {
            public perushVM: ko.Observable<AlHaperek.PerushViewmodel>;
            public embeddedKind: ko.Computed<AppPreferences.PerushEmbeddedKind>;
            public html: ko.Observable<string>;
            referrerPasukNum: number = 0;

            public get name(): string {
                return "perush";
            }

            constructor() {
                super();
                this.html = ko.observable("");
                this.perushVM = ko.observable(new AlHaperek.PerushViewmodel(ResourcesPool.ViewPool.alHaperekView.perekViewmodel));
                this.embeddedKind = ko.pureComputed(() => {
                    return ResourcesPool.ViewmodelPool.preferencesViewmodel.getProperty<AppPreferences.PerushEmbeddedKind>('perushEmbeddedKind').value();
                }, this);
                this.embeddedKind.subscribe(this.setHtml, this);
            }
            public get analyticsRootScreenName(): string {
                return '/929/perush';
            }

            onPageAfterIn(pageData: Framework7.Router.Page) {
                this.perushVM().load();
                ko.reApplyBindings(this, $('#perushPage')[0]);
                this.setAnalyticsScreen(this.perushVM().hebName() + ' על ' + this.perushVM().perekViewModel.perekSource());
            }
            selectPerush(perushVM: AlHaperek.PerushViewmodel, pasukNum?: number) {
                this.html("");
                this.perushVM(perushVM);
                this.referrerPasukNum = pasukNum;
            }
            setHtml() {
                var $perekText = ResourcesPool.ViewPool.alHaperekView.perekTextView.perekTextViewModel.$perekText;
                if (typeof this.perushVM().$perushText === 'undefined' || typeof $perekText === 'undefined') return "";
                var $perekTextDls = $perekText.find('dl');
                var $perushText = this.perushVM().$perushText.clone(true, true);
                var $elems: JQuery = $perushText.find('>span>span');
                var $nextPasuk: JQuery = $('');
                var i = 1;
                for (var $tmpElem = $elems.first(); ($tmpElem.is($elems.first()) && $tmpElem.length !== 0) || $nextPasuk.length !== 0; $tmpElem = $nextPasuk) {
                    $nextPasuk = $tmpElem.next();
                    if ($tmpElem.hasClass('pasuk-num') || $tmpElem.is('br')) {
                        $tmpElem.detach();
                    } else if ($tmpElem.is(':not(:empty)') && $tmpElem.text() != "") {
                        var $dl: JQuery = $('<dl>');
                        if (i == this.referrerPasukNum) {
                            $dl.addClass('referenced');
                            this.referrerPasukNum = 0; 
                        }
                        $dl.append($('<dt>', { 'class': 'pasuk-num' }).html(Dsoft.GimatryHelper.toLetters(i)));
                        var $dd = $('<dd>');
                        if (this.embeddedKind() == AppPreferences.PerushEmbeddedKind.EmbeddedPerush) {
                            $dd.append($('<span>').html($($perekTextDls[i - 1]).find('dd').html()).attr('class', 'embedded-perek-text'));
                        }
                        $dd.append($('<span>').html($tmpElem.html()));
                        $dl.append($dd);
                        $tmpElem.replaceWith($dl);
                        //.insertBefore($dl);
                        i++;
                    } else {
                        i++;
                        $tmpElem.detach();
                    }
                }
                this.html($perushText.html());
            }
            perushLoaded(sender: any, e: { data: string }) {
                this.setHtml();
                var $container = $('#perushContent > article');
                var $referencedPasuk = $('#perushContent > article > span > dl.referenced');
                Dsoft.View.scrollWithJqueryAnim($container, $referencedPasuk);
            }
        }
    }
}