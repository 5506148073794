module Dsoft {
    export module MVVM {
        export class MenuItem {
            name: string;
            itemActivatedEventHandler: EventHandler;
            itemDeactivatedEventHandler: EventHandler;

            constructor(name: string) {
                this.name = name;
                this.itemActivatedEventHandler = new EventHandler();
                this.itemDeactivatedEventHandler = new EventHandler();
            }

        }
        export class MenuViewModel {
            public items: Array<MenuItem>;

            constructor(itemNames?: string[]) {
                this.init(itemNames);
            }
            public init(itemNames?: string[]) {
                this.items = new Array<MenuItem>();
                if (typeof itemNames !== 'undefined') {
                    itemNames.forEach($.proxy(function (value: string, index: number, array: string[]) {
                        this.items.push(new MenuItem(value));
                    }, this));
                }

            }
            public activateItem(name: string) {
                this.items.forEach($.proxy(function (value: MenuItem, index: number, array: MenuItem[]) {
                    if (value.name == name) value.itemActivatedEventHandler.fire(new EventArgs(this, { data: null }));
                    else value.itemDeactivatedEventHandler.fire(new EventArgs(this, { data: null }));
                }, this));
            }
            public getItemByName(name: string) {
                for (var i = 0; i < this.items.length; i++) {
                    if (this.items[i].name == name)
                        return this.items[i];
                }
                return null;
            }
        }
    }
}