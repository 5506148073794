module AlHaperek {
    export class SystemMessagesViewModel {
        public systemMessagesViewModels: ko.ObservableArray<SystemMessageViewModel>;
        public selectedSystemMessageViewModel: ko.Observable<SystemMessageViewModel>;

        public dataSource: SystemMessagesDataSource;

        constructor(selectedSystemMessageViewModel?: SystemMessageViewModel) {
            this.init(selectedSystemMessageViewModel);
        }
        init(selectedSystemMessageViewModel?: SystemMessageViewModel) {
            // ko bugs
            if (typeof selectedSystemMessageViewModel == 'undefined') {
                this.selectedSystemMessageViewModel = ko.observable(new SystemMessageViewModel());
                this.selectedSystemMessageViewModel(null);
            } else {
                this.selectedSystemMessageViewModel = ko.observable(selectedSystemMessageViewModel);
            }
            this.systemMessagesViewModels = ko.observableArray([]);
            this.dataSource = new SystemMessagesDataSource();
        }
        load(): Promise<ko.ObservableArray<SystemMessageViewModel>> {
            return new Promise<ko.ObservableArray<SystemMessageViewModel>>((resolve: (value?: ko.ObservableArray<SystemMessageViewModel>) => void, reject: (reason?: any) => void) => {
                this.dataSource.loadSystemMessages().then((value: SystemMessage[]) => {
                    var systemMessagesViewModels = SystemMessageViewModel.modelsToViewModels(value);
                    this.systemMessagesViewModels(systemMessagesViewModels);
                    resolve(this.systemMessagesViewModels);
                });
            });
        }
    }
}