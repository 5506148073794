/// <reference path="../../../global-settings.ts" />
module Conn {
    export enum ConnType {
        homegroup, local, remote, remoteDev
    }
    export var connectionType: ConnType;
    export var displayedLocalPath: string;
    export var localPath: string;
    export var rabbiImagesPath: string;
    export var perushImagesPath: string;
    export var remotePath: string;
    export var rootPath: string;
    displayedLocalPath = "http://תנך.co.il/929/"; // double // are not allowed. exact address is needed.
    export function init(connType: ConnType): void {
        connectionType = connType;
        switch (connType) {
            case ConnType.homegroup:
                localPath = "http://192.168.42.205/tanah-dev/v2/www/929/"; // double // are not allowed. exact address is needed.
                rabbiImagesPath = "http://192.168.42.205/tanah-dev/v2/server/929/rabbis/";
                perushImagesPath = "http://192.168.42.205/tanah-dev/v2/server/929/perushim/";
                rootPath = "http://192.168.42.205/tanah-dev/v2/www/";
                remotePath = "http://192.168.42.205/tanah-dev/v2/server/929/";
                break;
            case ConnType.local:
                localPath = "http://localhost/tanah-dev/v2/www/929/"; // double // are not allowed. exact address is needed.
                rabbiImagesPath = "http://localhost/tanah-dev/v2/server/929/rabbis/";
                perushImagesPath = "http://localhost/tanah-dev/v2/server/929/perushim/";
                rootPath = "http://localhost/tanah-dev/v2/www/";
                remotePath = "http://localhost/tanah-dev/v2/server/929/";
                break;
            case ConnType.remote:
                localPath = "https://xn--febl3a.co.il/929/";
                rabbiImagesPath = "https://xn--febl3a.co.il/server/929/rabbis/";
                perushImagesPath = "https://xn--febl3a.co.il/server/929/perushim/";
                remotePath = "https://xn--febl3a.co.il/server/929/";
                rootPath = "https://xn--febl3a.co.il/";
                break;
            case ConnType.remoteDev:
                localPath = "https://tanah-preprod.tk/929/";
                rabbiImagesPath = "https://tanah-preprod.tk/server/929/rabbis/";
                perushImagesPath = "https://tanah-preprod.tk/929/perushim/";
                remotePath = "https://tanah-preprod.tk/server/929/";
                rootPath = "https://tanah-preprod.tk/";
                break;
        }
    }
    export function isLocalDev(): boolean {
        return connectionType == ConnType.local || connectionType == ConnType.homegroup;
    }
    init(GlobalSettings.connType);
    
}