/// <reference path="../view/root-view.ts" />
module App_V3 {
    export class TosView extends RootView {
        public get menuButtonAction(): () => void {
            return () => { };
        }

        public get name(): string {
            return "tos";
        }

        constructor() {
            super();
            this.init();
        }

        init() {
            this.underlyingParameters = <Framework7.View.Parameters>{
                name: this.name,
                routes: [],
                url: '/app_tos/'
            };
        }

        public onPageAfterIn(pageData: Framework7.Router.Page) {
            if (!this.isFirstTimePageIn) {
                this.setAnalyticsScreen();
            }
        }
    }
}
