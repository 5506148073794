module AlHaperek {
    export class DedicationViewModel {
        public id: ko.Observable<number>;
        public subject: ko.Observable<string>;
        public types: ko.ObservableArray<DedicationType>;
        public designation: ko.Computed<string>;

        public contentLoadedEventHandler: EventHandler;
        constructor(dedication?: Dedication) {
            this.init(dedication);
        }
        init(dedication?: Dedication) {
            if (typeof dedication !== 'undefined') {
                this.id = ko.observable(dedication.id);
                this.subject = ko.observable(dedication.subject);
                this.types = ko.observableArray(dedication.types);
            } else {
                this.id = ko.observable(-1)
                this.subject = ko.observable("");
                this.types = ko.observableArray([]);
            }
            this.designation = ko.computed(function () {
                var designation = "";
                this.types().forEach((dedicationType: DedicationType, index: number, dedicationTypes: DedicationType[]) => {
                    if (designation == "") {
                        designation += "ל";
                    } else {
                        designation += " ול";
                    }
                    switch (dedicationType) {
                        case DedicationType.MEMORIAL:
                            designation += "עילוי נשמת";
                        case DedicationType.REMEDY:
                            designation += "רפואה שלמה";
                            break;
                        case DedicationType.MATCH:
                            designation += "זיווג הגון";
                            break;
                        case DedicationType.LIVING:
                            designation += "פרנסה טובה";
                            break;
                    }
                });
                return designation;
            }, this);
        }

        public static mapper(value: Dedication, index: number, array: Dedication[]): DedicationViewModel { return DedicationViewModel.modelToViewModel(value); }
        public static modelToViewModel(model: Dedication): DedicationViewModel {
            var vm: DedicationViewModel;
            if (typeof model !== 'undefined')
                vm = new DedicationViewModel(model);
            else
                vm = new DedicationViewModel();
            return vm;
        }
        public static modelsToViewModels(models: Dedication[]): DedicationViewModel[] {
            var vms: DedicationViewModel[];
            if (typeof models !== 'undefined')
                vms = models.map(DedicationViewModel.mapper);
            else
                vms = [];
            return vms;
        }
    }
}