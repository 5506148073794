/// <reference path="../../../../shared/model/pojso/name-and-value.ts" />

module Tanahpedia {
    export module Entries {
        export interface MetaData {
            animals: Shared.NameAndValue[];
            artifacts: Shared.NameAndValue[];
            events: Shared.NameAndValue[];
            persons: Shared.NameAndValue[];
            plants: Shared.NameAndValue[];
            prophecys: Shared.NameAndValue[];
            places: Shared.NameAndValue[];
            wars: Shared.NameAndValue[];
        }
        export class Entry extends Shared.NameAndValue {
            public firstOccurance: string;
            public firstOccuranceHref: string;
            public id: number;
            public type: string;

            constructor(firstOccurance = "", firstOccuranceHref = "#", id = -1, type = "") {
                super();
                this.firstOccurance = firstOccurance;
                this.firstOccuranceHref = firstOccuranceHref;
                this.id = id;
                this.type = type;
            }
        }
    }
}