module AlHaperek {
    export module Parshanim {
        export module ResourcesPool {
            export var constructed: boolean;
            export function ctor(): void {
                ModelPool.ctor();
                ViewmodelPool.ctor();
                ViewPool.ctor();
            }
            export module ModelPool {
                export var rabbis: Rabbi[];
                export function ctor(): void {

                }
            }
            export module ViewmodelPool {
                export var perekViewModels = Array<PerekViewModel>(929);
                function fillPerekViewModels() {
                    for (var i = 1; i <= 929; i++) {
                        perekViewModels[i] = new AlHaperek.PerekViewModel(true, i);
                    }

                    //perekViewModels[1] = new AlHaperek.PerekViewModel(true, 1);
                    //for (var i = 2; i <= 929; i++) {
                    //    perekViewModels[i] = <AlHaperek.PerekViewModel>new Object(perekViewModels[i - 1]);
                    //    perekViewModels[i].loadNext();
                    //}
                }
                export function ctor(): void {
                    fillPerekViewModels();
                }
            }
            export module ViewPool {
                export var indexView: IndexView;
                export var isPhone: boolean;

                export function ctor(): void {

                }
            }

        }
    }
}