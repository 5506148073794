var moshe;
module AlHaperek {
    export class PerekTextDataSource_V2 {
        loadPerekText(perek: Perek): Promise<string> {
            return new Promise((resolve: (value?: string | PromiseLike<string>) => void, reject: (reason) => void) => {
                if (perek.perekId < 1 || perek.perekId > 929) {
                    reject(new Error("Perek is out of range"));
                    return;
                }
                // avoid repeating perek text loading for performance
                if (typeof AlHaperek.PerakimData.perakimText[perek.perekId] !== 'undefined') {
                    // make it "async"
                    //setTimeout($.proxy((perek) => {
                    //    this.perekTextLoadedEventHandler.fire(new EventArgs(this, { data: AlHaperek.PerakimData.perakimText[perek.perekId] }))
                    //}, this, perek), 0);

                    resolve(AlHaperek.PerakimData.perakimText[perek.perekId]);
                    return;
                }
                var settings = { async: true, type: 'GET', url: ResourcesPool_V2.ModelPool.pathToData + "/perakim/v200/" + perek.seferTanachUsName + "/" + perek.perek + ".html", dataType: 'html' };

                resolve(Dsoft.Ajax.minimalAjaxCall<string>(settings, (response: string): string => {
                    AlHaperek.PerakimData.perakimText[perek.perekId] = response;
                    return response;
                }));
            });
        }
    }
}