module AlHaperek {
    export module Rabbis {
        export class RabbisViewmodel {
            private rabbisDataSource: AlHaperek.RabbisDataSource;
            public rabbiViewmodels: ko.ObservableArray<RabbiViewmodel>;
            public filteredRabbiViewmodels: ko.ObservableArray<RabbiViewmodel>;
            public selectedRabbiViewmodel: ko.Observable<RabbiViewmodel>;

            constructor(rabbis?: Rabbi[]) {
                this.init(rabbis);
            }
            init(rabbis?: Rabbi[]) {
                this.rabbiViewmodels = ko.observableArray(RabbiViewmodel.modelsToViewmodels(rabbis));
                this.filteredRabbiViewmodels = ko.observableArray(this.rabbiViewmodels());
                this.selectedRabbiViewmodel = ko.observable(null);
                //this.selectedRabbiViewmodel.subscribe((rVM: RabbiViewmodel) => {
                //    rVM.loadArticles();
                //});
                this.rabbisDataSource = new RabbisDataSource();
            }
            filterRabbis(rabbi: Rabbi[]) {
                this.filteredRabbiViewmodels(this.rabbiViewmodels().filter((rabbiVM: RabbiViewmodel, rabbiVMIndex: number, rabbiVMs: RabbiViewmodel[]) => {
                    return rabbi.some((rabbi: Rabbi, rabbiIndex: number, rabbis: Rabbi[]) => { return rabbi.id == rabbiVM.id(); });
                }));
            }
            load() : Promise<RabbisViewmodel> {
                return new Promise((resolve: (value?: RabbisViewmodel | PromiseLike<RabbisViewmodel>) => void, reject: (reason?: any) => void) => {
                    
                    this.rabbisDataSource.loadRabbis().then(this.processRabbis.bind(this)).then((value: Rabbi[]) => {
                        resolve(this);
                    });
                });
            }
            rabbiArticlesLoaded(sender: any, e: { data: AlHaperek.Article[] }) {
                var rabbiVM = sender as RabbiViewmodel;
            }
            selectRabbi(rabbiVM: RabbiViewmodel) : RabbiViewmodel {
                return this.selectedRabbiViewmodel(rabbiVM);
            }
            selectRabbiById(id : number) : RabbiViewmodel {
                var maybeRabbiVMArr : Array<RabbiViewmodel> = this.rabbiViewmodels().filter((rabbiVM: RabbiViewmodel, index: number, rabbiVMs: RabbiViewmodel[]) => {
                    return id == rabbiVM.id();
                }, this);
                this.selectedRabbiViewmodel((maybeRabbiVMArr.length > 0) ? maybeRabbiVMArr[0] : null); 
                return this.selectedRabbiViewmodel();
            }

            viewRendered() {
                console.log('viewRendered');
            }


            processRabbis(rabbis: Rabbi[]) {
                this.rabbiViewmodels(RabbiViewmodel.modelsToViewmodels(rabbis));
            }
        }
    }
}