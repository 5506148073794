module Tanahpedia {
    export module Entries {
        export module Type {
            export const person = "person";
            export const place = "place";

        }
        export interface EntryViewmodel {
            firstOccurance: ko.Observable<string>;
            firstOccuranceHref: ko.Observable<string>;
            name: ko.Observable<string>;
            type: ko.Observable<string>;
            value: ko.Observable<string>;
            dataSource: EntryDataSource;
            entryLoadedEventHandler: EventHandler;

            load(): void;

            toEntry(): any;

            entryLoaded(sender: any, e: { data: any });
        }
    }
}