module App_V3 {
    export var app: App_V3.Application.App;
    export module ResourcesPool {
        export var constructed: boolean;
        export function ctor(): void {
            ModelPool.ctor();
            ViewmodelPool.ctor();
            ViewPool.ctor();
        }
        export module ModelPool {
            export function ctor(): void {

            }
        }
        export module ViewmodelPool {
            export var preferencesViewmodel: AppPreferences.PreferencesViewmodel;
            export var rabbisViewModel: AlHaperek.Rabbis.RabbisViewmodel;
            export function ctor(): void {
                preferencesViewmodel = new AppPreferences.PreferencesViewmodel();
                preferencesViewmodel.load();
                rabbisViewModel = new AlHaperek.Rabbis.RabbisViewmodel();
                rabbisViewModel.load();
            }
        }
        export module ViewPool {
            export var alHaperekView: AppAlHaperek.AppPerekView;
            export var analytics: Analytics;
            export var articleView: AppAlHaperek.ArticleView;
            export var currentRootView: BaseView;
            export var currentRouter: Framework7.Router.Router;
            export var currentView: BaseView;
            export var contactUsView: ContactUsView;
            export var isPhone: boolean;
            export var mainPanelView: NavigationPanelView;
            export var perushView: AppAlHaperek.PerushView;
            export var preferencesView: AppPreferences.PreferencesView;
            export var preloaderView: PreloaderView;
            export var rabbiView: AppAlHaperek.AppRabbiView;
            export var rabbisView: AppAlHaperek.AppRabbisView;
            export var systemMessagesView: SystemMessagesView;
            export var tosView: TosView;
            export var views: Array<BaseView>;
            export var rootViews: Array<RootView>;

            export function ctor(): void {
                Object.defineProperty(ViewPool, 'currentRouter', { get: () => currentRootView.underlying.router });
                Object.defineProperty(ViewPool, 'rootViews', { get: () => views.filter(v => v instanceof RootView) });

                analytics = new Analytics();

                alHaperekView = new AppAlHaperek.AppPerekView();
                contactUsView = new ContactUsView();
                mainPanelView = new NavigationPanelView();
                preloaderView = new PreloaderView();
                preferencesView = new AppPreferences.PreferencesView();
                rabbisView = new AppAlHaperek.AppRabbisView();
                rabbiView = new AppAlHaperek.AppRabbiView();
                systemMessagesView = new SystemMessagesView();
                tosView = new TosView();
                articleView = new AppAlHaperek.ArticleView();
                perushView = new AppAlHaperek.PerushView();

                // Just semantic. Not a mistake. rootViews is calculated with object types
                views = [alHaperekView, contactUsView, preferencesView, rabbisView, systemMessagesView, tosView];
                views = views.concat([articleView, perushView, rabbiView]);

                currentRootView = alHaperekView;
            }
            export function getViewByName(name: string): BaseView {
                return views.find((v) => v.name == name);
            }
        }

    }
}