module AlHaperek {
    export class SystemMessageViewModel {
        public id: ko.Observable<number>;
        public abstract: ko.Observable<string>;
        public content: ko.Observable<string>;
        public priority: ko.Observable<number>;


        public contentLoadedEventHandler: EventHandler;
        constructor(systemMessage?: SystemMessage) {
            this.init(systemMessage);
        }
        init(systemMessage?: SystemMessage) {
            if (typeof systemMessage !== 'undefined') {
                this.id = ko.observable(systemMessage.id);
                this.abstract = ko.observable(systemMessage.abstract);
                this.content = ko.observable(systemMessage.content);
                this.priority = ko.observable(systemMessage.priority);
            } else {
                this.id = ko.observable(-1)
                this.abstract = ko.observable("");
                this.content = ko.observable("");
                this.priority = ko.observable(0);
            }
        }

        public static mapper(value: SystemMessage, index: number, array: SystemMessage[]): SystemMessageViewModel { return SystemMessageViewModel.modelToViewModel(value); }
        public static modelToViewModel(model: SystemMessage): SystemMessageViewModel {
            var vm: SystemMessageViewModel;
            if (typeof model !== 'undefined')
                vm = new SystemMessageViewModel(model);
            else
                vm = new SystemMessageViewModel();
            return vm;
        }
        public static modelsToViewModels(models: SystemMessage[]): SystemMessageViewModel[] {
            var vms: SystemMessageViewModel[];
            if (typeof models !== 'undefined')
                vms = models.map(SystemMessageViewModel.mapper);
            else
                vms = [];
            return vms;
        }
    }
}