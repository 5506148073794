module AlHaperek {
    export module Rabbis {
        export interface IndexViewConf {
            rabbis?: Rabbi[],
            rabbi?: Rabbi,
            $viewContainer: JQuery
        }
        export class IndexView {
            static datatableOptions: DataTables.Settings = {
                columnDefs: [
                    {
                        searchable: false,
                        targets: [0],
                        visible : false
                    },
                    {
                        orderData: 0,
                        targets: [1]
                    },
                    {
                        orderable: false,
                        searchable: false,
                        targets: [2]
                    },
                    {
                        orderable: false,
                        searchable: false,
                        targets: [3]
                    }
                ],
                info: false,
                language: {
                    processing: "מעבד...",
                    zeroRecords: "לא נמצאו מאמרים התואמים לחיפוש",
                    emptyTable: "עדיין אין מאמרים של הרב",
                    search: "חפש מאמר (ספר/כותרת):",
                },
                
                order: [[1, "asc"]],
                paging: false,
                scrollCollapse: false
            };
            static slideshowOptions: JQuerySlickOptions = {
                arrows: true,
                rtl: true
            };
            static $slideTemplate: JQuery;
            static $dataTableTemplate: JQuery;

            everShowed: boolean = false;
            everGridInited: boolean = false;
            mouseDownInClosePos: boolean;
            rabbi: Rabbi;

            rabbiSearchViewmodel: RabbiSearchViewmodel;
            rabbisViewmodel: RabbisViewmodel;

            public addressLineMgr: AddressLineManager;

            $viewContainer: JQuery;
            $rabbisSlideshowContainer: JQuery;
            $rabbisSlideshow: JQuery;
            $rabbisGrid: JQuery;
            rabbisGridMasonry: Masonry;

            constructor(conf: IndexViewConf) {
                AlHaperek.Rabbis.ResourcesPool.ViewPool.indexView = this;
                this.init(conf);
            }
            init(conf: IndexViewConf) {
                this.rabbi = conf.rabbi;

                this.rabbisViewmodel = new RabbisViewmodel(conf.rabbis);
                this.rabbiSearchViewmodel = new RabbiSearchViewmodel(conf.rabbis);
                this.rabbiSearchViewmodel.resultChangedEventHandler.register((sender: any, e: { data: Rabbi[] }) => {
                    this.rabbisViewmodel.filterRabbis(e.data);
                }, this);
                this.rabbisViewmodel.selectedRabbiViewmodel.subscribe((rVM : RabbiViewmodel) => {
                    this.showRabbi(rVM);
                }, this);
                if ((typeof this.rabbi == 'undefined') || (this.rabbi === null)) {
                    this.addressLineMgr = new AddressLineManager(
                        new AddressLineState(
                            "",
                            this.hideSlideshow.bind(this),
                            true)
                    );
                } else {
                    this.addressLineMgr = new AddressLineManager();
                }


                this.$viewContainer = conf.$viewContainer;
                this.$rabbisSlideshowContainer = this.$viewContainer.find('#rabbisSlideshow');
                this.$rabbisSlideshowContainer.mousedown((ev: JQueryMouseEventObject) => {
                    this.mouseDownInClosePos = (ev.target == this.$rabbisSlideshowContainer[0] || ev.target.className == 'slick-track');
                });
                this.$rabbisSlideshowContainer.click((ev: JQueryMouseEventObject) => {
                    if (this.mouseDownInClosePos &&
                        (ev.target == this.$rabbisSlideshowContainer[0] || ev.target.className == 'slick-track')
                    ) this.hideSlideshow();
                });
                this.$rabbisGrid = this.$viewContainer.find('.grid-gallery');

                ko.applyBindings(this, this.$viewContainer[0]);

                this.rabbisViewmodel.filteredRabbiViewmodels.subscribe(() => {
                    if (!this.everGridInited) { // seems to not work.
                        this.initGrid();
                        this.$rabbisGrid.find('figcaption').removeClass('until-init');
                        this.initGrid();
                        this.initSlides();
                        this.everGridInited = true;
                        if (this.rabbi != null) {
                            this.selectRabbiById(this.rabbi.id);
                        }
                    } else {
                        this.$rabbisGrid.find('figcaption').removeClass('until-init');
                        this.initGrid();
                        this.initSlides();
                    }
                }, this);
                this.rabbisViewmodel.filteredRabbiViewmodels(this.rabbisViewmodel.rabbiViewmodels());

                
            }
            initGrid() {
                this.rabbisGridMasonry = new Masonry('ul.grid', {
                    itemSelector: 'li',
                    resize: true,
                    columnWidth: this.$viewContainer.find('.grid-sizer')[0],
                    originLeft: false
                });
                var $rabbiAnchors: JQuery = this.$rabbisGrid.find('a');
                if (!$rabbiAnchors.data('binded')) {
                    this.$rabbisGrid.find('a').bindToIntuitiveClick((ev: JQueryMouseEventObject) => {
                        var rabbiVM = ko.dataFor(ev.target) as RabbiViewmodel;
                        this.rabbisViewmodel.selectedRabbiViewmodel(rabbiVM);
                        rabbiVM.loadArticles().then(this.processLoadedRabbiVM.bind(this));
                        //this.showSlideshow(ko.dataFor(ev.target) as RabbiViewmodel);
                    });
                    $rabbiAnchors.data('binded', true);
                }
            }
            initSlides() {

                this.$rabbisSlideshow = this.$rabbisSlideshowContainer.find('ul');
                try {
                    if (this.$rabbisSlideshow.hasClass('slick-initialized')) {
                        this.$rabbisSlideshow.slick('unslick');
                        // ---------------- reattach bindings -----------------
                        this.$rabbisSlideshow.empty();
                        this.$rabbisSlideshow.append(IndexView.$slideTemplate);
                        ko.cleanNode(this.$rabbisSlideshow[0]);
                        ko.applyBindings(this, this.$rabbisSlideshow[0]);
                        // ----------------------------------------------------
                    }
                    this.$rabbisSlideshow.slick(IndexView.slideshowOptions);
                    this.$rabbisSlideshow.on('afterChange', $.proxy((event, slick, currentSlide) => {
                        Dsoft.Debounce.debounceWithId($.proxy((currentSlide) => {
                            var $currentSlide: JQuery = this.$rabbisSlideshow.find('li[data-slick-index=' + currentSlide + ']');
                            var rabbiId: number = Number($currentSlide.data('rabbi-id'));
                            if (this.rabbisViewmodel.selectedRabbiViewmodel().id() != rabbiId) {
                                this.selectRabbiById(rabbiId);
                            }
                        }, this, currentSlide), 20, currentSlide);
                    }, this));
                } catch (ex) { console.log(ex); }
            }
            public getCurrentSlideIdx() : number {
                return <number>this.$rabbisSlideshow.slick('slickCurrentSlide');
            }
            hideLoadingContainer(): any {
                this.$rabbisSlideshow.find('.loading-container').hide();
            }
            public hideSlideshow() {
                this.$rabbisSlideshowContainer.removeClass('animated rollIn');
                this.$rabbisSlideshowContainer.addClass('animated rollOut');
                Dsoft.View.allowWindowScroll(window);
                this.addressLineMgr.changeState(
                    new AddressLineState(
                        "",
                        $.proxy(this.hideSlideshow, this),
                        true
                    )
                );
                $(document).prop('title', 'תנ"ך על הפרק - רבנים');
            }
            public isSlideshowShowed() :boolean {
                return this.$rabbisSlideshowContainer.hasClass('animated rollIn');
            }
            public jumpToSlide(slideIdx): void {
                this.$rabbisSlideshow.slick("slickGoTo", slideIdx, true);
            }
            public refreshSlideDataTable($slide: JQuery) {
                var $currentDataTableContainer: JQuery = $slide.find('.data-table-container');
                // ---------- reattach bindings (for datagrid dist init) ----------
                $currentDataTableContainer.empty();
                var $dataTableClone: JQuery = IndexView.$dataTableTemplate.clone();
                $currentDataTableContainer.append($dataTableClone);
                var rabbiVM : RabbiViewmodel = ko.dataFor($slide[0]);
                ko.cleanNode($dataTableClone[0]);
                ko.applyBindings(rabbiVM, $dataTableClone[0]);
                // ----------------------------------------------------------------
                var optionsWithCallback: DataTables.Settings = new Object(IndexView.datatableOptions);
                optionsWithCallback.initComplete = () => {
                    this.hideLoadingContainer();
                };
                $dataTableClone.DataTable(optionsWithCallback);
            }
            selectRabbiById(rabbiId: number): any {
                var rabbiVM = this.rabbisViewmodel.selectRabbiById(rabbiId);
                rabbiVM.loadArticles().then(this.processLoadedRabbiVM.bind(this));
            }
            public processLoadedRabbiVM(rabbiVM: RabbiViewmodel) {
                var $currentSlide: JQuery = this.$rabbisSlideshow.find('li[data-rabbi-id=' + rabbiVM.id() + ']:not(.slick-cloned)');
                this.refreshSlideDataTable($currentSlide);
                this.hideLoadingContainer();
            }
            public showRabbi(rabbiVM: RabbiViewmodel, changeHistory : boolean = true) {
                this.$rabbisSlideshow.find('.loading-container').show();
                if (!this.isSlideshowShowed()) {
                    this.showSlideshow();
                }
                var slideIdx: number = this.rabbisViewmodel.filteredRabbiViewmodels.indexOf(rabbiVM);
                if (!this.everShowed || this.getCurrentSlideIdx() !== slideIdx) {
                    this.jumpToSlide(slideIdx);
                }
                this.everShowed = true;
                if (changeHistory) {
                    var addressLineState = new AddressLineState(
                        rabbiVM.name(),
                        this.showRabbi.bind(this,rabbiVM,false),
                        true
                    )
                    if (this.addressLineMgr.states.length == 0) {
                        this.addressLineMgr.replaceState(addressLineState);
                    } else {
                        this.addressLineMgr.changeState(addressLineState);
                    }
                }
                $(document).prop('title', 'תנ"ך על הפרק - ' + rabbiVM.name());
            }
            public showSlideshow() {
                this.$rabbisSlideshowContainer.show();
                this.$rabbisSlideshowContainer.removeClass('animated rollOut');
                this.$rabbisSlideshowContainer.addClass('animated rollIn');
                this.$rabbisSlideshowContainer.find('.hide-button').unbind('click');
                this.$rabbisSlideshowContainer.find('.hide-button').click((ev) => {
                    this.hideSlideshow();
                });
                Dsoft.View.preventWindowScroll(window);
            }
        }
    }
}