module AlHaperek {
    export module Rabbis {
        export module ResourcesPool {
            export var constructed: boolean;
            export function ctor(): void {
                ModelPool.ctor();
                ViewmodelPool.ctor();
                ViewPool.ctor();
            }
            export module ModelPool {
                export var rabbis: Rabbi[];
                export function ctor(): void {

                }
            }
            export module ViewmodelPool {
                export function ctor(): void {

                }
            }
            export module ViewPool {
                export var indexView: IndexView;
                export var isPhone: boolean;

                export function ctor(): void {

                }
            }

        }
    }
}