module AlHaperek {
    export module Parshanim {
        export class ParshanSearchViewmodel {
            private parshanim: Parshan[];

            public searchExpression: ko.Observable<string>;
            
            public resultChangedEventHandler: EventHandler;
            constructor(parshanim: Parshan[]) {
                this.init(parshanim);
            }
            expressionChanged(newExpr: string) {
                var result: Parshan[];
                result = this.parshanim.filter((rabbi: Parshan, index: number, parshanim: Parshan[]) => {
                    const regexStr = '(?=.*' + newExpr.trim().split(/\s/).join(')(?=.*') + ')';
                    const searchRegEx = new RegExp(regexStr, 'gi');
                    return rabbi.name.replace('הרב','').replace("ר'",'').replace('ז"ל','').match(searchRegEx) !== null;
                });

                this.resultChangedEventHandler.fire(new EventArgs(this, { data: result }));
            }
            init(parshanim: Parshan[]) {
                this.parshanim = parshanim;
                this.searchExpression = ko.observable('');
                this.searchExpression.subscribe(this.expressionChanged, this);
                this.resultChangedEventHandler = new EventHandler();
            }
        }
    }
}