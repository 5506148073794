/// <reference path="../view/root-view.ts" />
module App_V3 {
    export class SystemMessagesView extends RootView {
        systemMessagesViewModel: AlHaperek.SystemMessagesViewModel;

        public get menuButtonAction(): () => void {
            return () => { };
        }

        public get name(): string {
            return "systemMessages";
        }
        public get analyticsRootScreenName(): string {
            return '/system_messages/';
        }

        constructor() {
            super();
            this.init();
        }

        init() {
            this.underlyingParameters = <Framework7.View.Parameters>{
                name: this.name,
                routes: [],
                url: '/app_system_messages/'
            };
            this.systemMessagesViewModel = new AlHaperek.SystemMessagesViewModel();
            this.systemMessagesViewModel.load();
        }

        public onPageAfterIn(pageData: Framework7.Router.Page) {
            if (this.isFirstTimePageIn) {
                this.bind();
            } else {
                this.setAnalyticsScreen();
            }
        }
    }
}
