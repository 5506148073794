module App_V3 {
    export module AppAlHaperek {
        export class AppPerekTextView {
            private perekTextBaseScaleFactor: number = 1;
            private perekTextLastScaleFactor: number = 1;

            private $perekTextView: JQuery;
            private $perekTextViewPerekText: JQuery;

            public perekTextViewModel: AlHaperek.PerekTextViewModel;
            public perekViewmodel: AlHaperek.PerekViewModel;
            public get $pasukOptionsPopover(): JQuery {
                return $('.popover.pasuk-options');
            }

            //public pasukOptionsPopover: Framework7.Popover.Popover;

            constructor(perekViewmodel: AlHaperek.PerekViewModel) {
                this.init(perekViewmodel);
            }
            init(perekViewmodel: AlHaperek.PerekViewModel) {
                this.perekViewmodel = perekViewmodel;
                this.perekViewmodel.loadedThens.push({ onfulfilled: this.processPerek.bind(this) });
                this.perekTextViewModel = new AlHaperek.PerekTextViewModel(this.perekViewmodel);
            }
            attachEvents() {
                this.$pasukOptionsPopover.off("popover:close", this.onPasukOptionsPopoverClose.bind(this))
                this.$pasukOptionsPopover.on("popover:close", this.onPasukOptionsPopoverClose.bind(this))
            }
            matchSelectors() {
                this.$perekTextView = $('#perekTextView');
                this.$perekTextViewPerekText = this.$perekTextView.find('.perek-text');
            }
            processPerek(perek: AlHaperek.Perek) {
                this.perekTextViewModel.load().then(this.processText.bind(this));
            }
            processText(perekText: string) {
                var spans: HTMLCollection = this.perekTextViewModel.$perekText[0].children;
                var nextSpan: Element;
                var i = 1;
                for (var span = spans[0]; span != null; span = nextSpan) {
                    nextSpan = span.nextElementSibling;
                    if (span.className == 'pasuk-num') {
                        span.parentElement.removeChild(span);
                    } else {
                        var $dl: JQuery = $('<dl>', { 'data-pasuk-num' : i });
                        $dl.append($('<dt>', { 'class': 'pasuk-num' }).html(Dsoft.GimatryHelper.toLetters(i)));
                        $dl.append($('<dd>').html(span.innerHTML));
                        span.parentElement.replaceChild($dl[0], span);
                        i++;
                    }
                }
                this.perekTextViewModel.textAsHtml(this.perekTextViewModel.$perekText.html());
                //this.$perekTextViewPerekText.find('*').each(function (index: number, elem: Element) {
                //    var $el = $(elem);
                //    $el.data('originalFontSize', parseFloat($el.css('fontSize')));
                //});

                //console.log('perkTextViewPerekLoaded');
                if (typeof this.$perekTextView !== 'undefined') {
                    this.$perekTextView.find('article').animate({ scrollTop: 0 }, "slow");

                    this.$perekTextView.off('taphold');
                    this.$perekTextView.on('taphold', this.on$perekTextViewTapHold.bind(this));
                }

            }
            showPasukPopover(): any {
                App_V3.app.underlying.popover.open('.popover.pasuk-options', this.$perekTextView.find('dl.selected')[0]);
            }
            unselectPesukim(): any {
                this.$perekTextView.find('dl.selected').removeClass('selected');
            }

            on$perekTextViewTapHold(ev: JQueryEventObject): any {
                var $ddOrDt = $(ev.target);
                var $dl = $ddOrDt.parent('dl');
                var pasukNum = Number($dl.data('pasuk-num'));
                $dl.addClass('selected');
                this.perekViewmodel.selectedPasukViewModel.load(pasukNum, ResourcesPool.ViewPool.alHaperekView.perekExplainView.perushimView.perushimViewmodel.perushViewmodels());
                this.showPasukPopover();
            }
            onPasukOptionsPopoverLinkClick(perushVM: AlHaperek.PerushViewmodel) {
                ResourcesPool.ViewPool.perushView.selectPerush(perushVM, this.perekViewmodel.selectedPasukViewModel.pasukNum);
                App_V3.app.hidePopovers();
                // TODO: make it work with the href
                var url = "/app_929/perush/" + perushVM.perekViewModel.perekId() + '/' + perushVM.id();
                ResourcesPool.ViewPool.alHaperekView.underlying.router.navigate(url);
                //(<Framework7.Router.Router>app.underlying["router"]).navigate(url);
            }            
            public onPasukOptionsPopoverClose(popover: Framework7.Popover.Popover) {
                this.unselectPesukim();
            }

        }
    }
}