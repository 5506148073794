module Dsoft {
    export module HebrewHelper {
        export function hebrewComperator(a: string, b: string) {
            return removeNiqqud(a) > removeNiqqud(b);
        }
        export function removeNiqqud(str: string) {
            //return str.replace(/[\u0591-\u05C7]/g, '');
            return str.replace('', '');
        }
    }
}