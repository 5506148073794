module AlHaperek {
    export class Article {
        public id: number;
        public abstract: string;
        public authorId: number;
        public name: string;
        public perekId: number;
        public priority: number;


        constructor(id: number, abstract: string, authorId: number, name: string, perekId : number, priority: number) {
            this.id = id;
            this.abstract = abstract;
            this.authorId = authorId;
            this.name = name;
            this.perekId = perekId;
            this.priority = priority;
        }
    }
}