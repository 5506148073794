module Tanahpedia {
    export module Entries {
        export class EntryExtraPageView {
            pageIdx: number;

            entryViewmodel: EntryViewmodel;


            public personView: Person.BookPersonView;
            private $entryExtraPageElem: JQuery;

            public entryLoadedEventHandler: EventHandler;


            constructor(entryViewmodel: EntryViewmodel, $entryExtraPageElem: JQuery) {
                this.init(entryViewmodel, $entryExtraPageElem);
            }
            init(entryViewmodel: EntryViewmodel, $entryExtraPageElem: JQuery) {
                this.entryViewmodel = entryViewmodel;
                this.$entryExtraPageElem = $entryExtraPageElem;

                this.pageIdx = Number(this.$entryExtraPageElem.attr('data-page-idx'));

                switch (this.entryViewmodel.type()) {
                    case Type.person:
                        this.personView = new Person.BookPersonView(this.pageIdx, <Person.EntryViewmodel>this.entryViewmodel );
                        break;
                    default:
                }
                

                this.entryViewmodel.entryLoadedEventHandler.register(this.entryLoaded, this);

                ko.applyBindings(this, this.$entryExtraPageElem[0]);
            }

            entryLoaded(sender: any, e: { data: Entry }) {
                var entry = e.data;

            }

        }

    }
}