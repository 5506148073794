
module Tanahpedia {
    export module Entries {
        export class ImageMetadataViewmodel {
            credit: ko.Observable<string>;
            height: ko.Observable<number>;
            license: ko.Observable<string>;
            licenseLink: ko.Observable<string>;
            link: ko.Observable<string>;
            name: ko.Observable<string>;
            title: ko.Observable<string>;
            width: ko.Observable<number>;
            constructor(imageMetadata : ImageMetadata = new ImageMetadata()) {
                this.init(imageMetadata);
            }
            init(imageMetadata: ImageMetadata = new ImageMetadata()) {
                this.credit = ko.observable(imageMetadata.credit);
                this.height = ko.observable(imageMetadata.height);
                this.license = ko.observable(imageMetadata.license);
                this.licenseLink = ko.observable(imageMetadata.licenseLink);
                this.link = ko.observable(imageMetadata.link);
                this.name = ko.observable(imageMetadata.name);
                this.title = ko.observable(imageMetadata.title);
                this.width = ko.observable(imageMetadata.width);
            }
        }
    }
}