///<reference path="event-args.ts"/>
interface EventHandlerListener {
    id: number;
    callback: Function;
    thisPtr: any;
}
class EventHandler {
    listeners: Array<EventHandlerListener>;
    private currentId: number;
    constructor() {
        this.currentId = 0;
        this.listeners = new Array<EventHandlerListener>();
    }

    register(callback: Function, thisPtr: any) : number {
        this.listeners.push({id: this.currentId,
            callback: callback, thisPtr: thisPtr
        });
        this.currentId++;
        return this.currentId - 1;
    }
    unregister(registration: number) : void {
        for (var i = 0; i < this.listeners.length; i++) {
            if (this.listeners[i].id == registration) {
                var removedObject = this.listeners.splice(i, 1);
                removedObject = null;
                break;
            }
        }
    }
    fire(eventArgs: EventArgs) {
        for (var i = 0; i < this.listeners.length; i++) {
            this.listeners[i].callback.apply(this.listeners[i].thisPtr, [eventArgs.sender, eventArgs.e]);
        }
    }
}