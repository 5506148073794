module Tanahpedia {
    export module Entries {
        export module Person {
            export class ReincarnationViewmodel {
                public into: Array<NameAndValueViewmodel>;
                public of: Array<NameAndValueViewmodel>;
                public sparkedInto: Array<NameAndValueViewmodel>;
                public sparkOf: Array<NameAndValueViewmodel>;


                public changedEventHandler: EventHandler;

                constructor(personEntryReincarnation: Person.Reincarnation) {
                    this.changedEventHandler = new EventHandler();
                    if (typeof personEntryReincarnation == 'undefined')
                        personEntryReincarnation = new Person.Reincarnation();
                    this.into = NameAndValueViewmodel.modelsToViewmodels(personEntryReincarnation.into);
                    this.of = NameAndValueViewmodel.modelsToViewmodels(personEntryReincarnation.of);
                    this.sparkedInto = NameAndValueViewmodel.modelsToViewmodels(personEntryReincarnation.sparkedInto);
                    this.sparkOf = NameAndValueViewmodel.modelsToViewmodels(personEntryReincarnation.sparkOf);

                }
                public toModel() {
                    return ReincarnationViewmodel.viewmodelToModel(this);
                }


                public static viewmodelToModel(viewmodel: ReincarnationViewmodel): Person.Reincarnation {
                    var model = new Person.Reincarnation();

                    model.into = viewmodel.into.map(NameAndValueViewmodel.reverseMapper);
                    model.of = viewmodel.of.map(NameAndValueViewmodel.reverseMapper);
                    model.sparkedInto = viewmodel.sparkedInto.map(NameAndValueViewmodel.reverseMapper);
                    model.sparkOf = viewmodel.sparkOf.map(NameAndValueViewmodel.reverseMapper);

                    return model;
                }
            }
        }
    }
}