    /// <reference path="../scripts/typings/framework7/framework7.d.ts" />
/// <reference path="../scripts/typings/dsoft/dsoft.knockout.d.ts" />

module App_V3 {
    export module Application {
        export class App {
            public backButtonAction: () => void = Application.Actions.defaultbackButtonAction;
            public isOnNotificationOpenAttached: boolean = false;
            //public menuButtonAction: () => void = Application.Actions.defaultMenuButtonAction;
            public paused: boolean = false;
            public underlying: Framework7;
            constructor() {
                var options: Framework7.Framework7Params = <Framework7.Framework7Params>{
                    id: 'com.tanah.daily929',
                    name: 'תנ"ך על הפרק',
                    on: Application.Underlying.on,
                    panel: Application.Underlying.panel,
                    picker: Application.Underlying.picker,
                    root: '#app',
                    routes: Application.Underlying.routes,
                    rtl: true,
                    theme: 'md',
                    touch: Application.Underlying.touch
                };


                var font = new FontFaceObserver('Material Design Icons');
                font.load(null).then(() => {
                    ResourcesPool.ViewPool.preloaderView.fontLoaded(true);
                }, () => {
                    ResourcesPool.ViewPool.preloaderView.fontLoaded(true);
                });

                this.underlying = new Framework7(options);
            }

            public initialize() {

                ResourcesPool.ViewPool.mainPanelView.underlying = App_V3.app.underlying.panel;
                ResourcesPool.ViewPool.mainPanelView.bind($('#app>.panel'));
                ResourcesPool.ViewPool.rootViews.forEach((view) => view.create());

                this.setRootFontSize(ResourcesPool.ViewmodelPool.preferencesViewmodel.getProperty<number>('fontSize').value());

                this.attachEvents();
            }
            attachEvents(): any {
                if (typeof (navigator['app']) !== 'undefined') {
                    navigator['app'].overrideButton("menubutton", true);
                }
                $(document).on('backbutton', () => { this.backButtonAction(); });
                $(document).on('menubutton', () => { this.onMenuButtonAction(); });
                $(document).on('keyup', (event) => { if (event.keyCode == 192) this.backButtonAction(); });
                $(document).on('keyup', (event) => { if (event.keyCode == 220) this.onMenuButtonAction(); });
                $(document).on('deviceready', () => { this.onDeviceReady(); });
                $(document).on('pause', () => { this.paused = true; });
                $(document).on('resume', () => { this.paused = false; });


                ResourcesPool.ViewPool.mainPanelView.$el.on('panel:closed', (ev) => {
                    this.backButtonAction = Application.Actions.defaultbackButtonAction;
                    //this.menuButtonAction = Application.Actions.defaultMenuButtonAction;
                });

            }
            public hidePopovers(): any {
                app.underlying.popover.close('.popover.modal-in');
            }

            setRootFontSize(fontSizeInPx: number) {
                $('html').attr('style', 'font-size:' + fontSizeInPx + 'px !important');
            }
            onDeviceReady() {
                // disable text zoom
                if (typeof (window.MobileAccessibility) !== 'undefined') {
                    window.MobileAccessibility.setTextZoom(100, () => console.log("Text zoom was reset to 100%"));
                }
            }
            onMenuButtonAction(): any {
                ResourcesPool.ViewPool.currentView.menuButtonAction();
            }
            onNotificationOpen(data: CordovaFirebasePlugin.NotificationData) {
                if (data.tap) {
                    var perekToLoadAsInt: number = Number((<AppAlHaperek.DailyNotificationData>data).perekToLoad);
                    if (perekToLoadAsInt >= 1 && perekToLoadAsInt <= 929) {
                        ResourcesPool.ViewPool.alHaperekView.loadPerekById(perekToLoadAsInt);
                    }
                }
            }
            onPageAfterIn(pageData: Framework7.Router.Page) {
                this.backButtonAction = Application.Actions.defaultbackButtonAction;
                //this.menuButtonAction = Application.Actions.defaultMenuButtonAction;

                // attach firebase onNotificationOpen event just once.
                if (!this.isOnNotificationOpenAttached && typeof (window.FirebasePlugin) !== 'undefined') {
                    window.FirebasePlugin.onNotificationOpen(this.onNotificationOpen, () => { });
                    this.isOnNotificationOpenAttached = true;
                }

                var view = ResourcesPool.ViewPool.getViewByName(pageData.name);
                if (!view.isFirstTimePageIn || view == ResourcesPool.ViewPool.alHaperekView) {
                    ResourcesPool.ViewPool.currentView = view;
                    if (view instanceof RootView) {
                        ResourcesPool.ViewPool.currentRootView = view as RootView;
                    }
                }

                view.onPageAfterIn(pageData);
                //console.log('pageAfterIn: ' + pageData.name);

                if (typeof view !== 'undefined') {
                    view.isFirstTimePageIn = false;
                }
            }
            onPageBeforeRemove(pageData: Framework7.Router.Page) {
                var view = ResourcesPool.ViewPool.getViewByName(pageData.name);
                view.onPageBeforeRemove(pageData);
            }
            onPageBeforeOut(pageData: Framework7.Router.Page) {
                //console.log('pageBeforeOut: ' + pageData.name);
            }
            onPageMounted(pageData: Framework7.Router.Page) {
                //console.log('pageMounted: ' + pageData.name);
            }
        }
    }
}