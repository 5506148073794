module Dsoft {
    export module MVVM {
        export enum Layers {
            VIEW, VIEWMODEL
        }
        // works only bentayim when containing a view which is child, grandChild etc, and that view must contain resources as siblings, childs, father, granfather etc. maybe more?
        export function resolveUri(additional: string, original: string): string {
            // take over double // meyutarim, bentaiym doesnt seem to help.
            additional = new String(additional.replace('://', ':-[)')).toString();
            additional = additional.replace(new RegExp('//'), '/');
            additional = additional.replace(':-[)', '://');

            var result = "";
            var folders = additional.split('/').filter(function (folder) { return (folder != '.'); });
            folders.pop();
            var pathParts = original.split('/').filter(function (folder) { return (folder.length != 0) && (folder != '.'); })
            var doubleDotsCount = pathParts.filter(function (folder) { return folder == '..'; }).length;
            if (doubleDotsCount >= folders.length) {
                pathParts = pathParts.reverse();
                for (var i = 0; i < folders.length; i++) {
                    pathParts.pop();
                }
                pathParts = pathParts.reverse();
            } else {
                var upRemoved = 0;
                pathParts = pathParts.filter(function (folder) { if (folder != '..') return true; else { upRemoved++; return false; } }).reverse();
                folders = folders.slice(0, folders.length - upRemoved);
                var levels = new Number(folders.length);
                for (var i = 0; i < levels; i++) {
                    pathParts.push(folders.pop());
                }
                pathParts = pathParts.reverse();
            }

            $(pathParts).each(function (i, part) {
                result += part;
                if (i != pathParts.length - 1) result += '/';
            });
            return result;
        }
        export function getExternalViewHeadAndBody(viewUrl: string, callback: (result: { $head: JQuery; $body: JQuery }) => void): void {
            $.get(viewUrl, $.proxy(function (htmlAsText) {
                var result = { $head: $('<head>'), $body: $('<body>') };
                var $viewHead: JQuery;
                var $viewBody: JQuery;


                $viewHead = $(htmlAsText.substring(htmlAsText.indexOf('<head>') + 6, htmlAsText.indexOf('</head>')));
                $viewBody = $(htmlAsText.substring(htmlAsText.indexOf('<body>') + 6, htmlAsText.indexOf('</body>')));
                $viewHead.each(function () {
                    var $this = $(this);
                    if ($this.is('link') || $this.is('script')) {
                        var uri = ($(this).is('link') ? $(this).attr('href') : $(this).attr('src'));
                        if (uri != '' && uri != undefined) {
                            //console.log('uri: "' + uri + '"');
                            var modifiedUri = MVVM.resolveUri(viewUrl, uri);
                            //console.log(modifiedUri);

                            if ($(this).is('link')) {
                                $(this).attr('href', modifiedUri);
                            } else {
                                $(this).attr('src', modifiedUri);
                            }
                        }
                        result.$head.append(this);
                    }
                });
                result.$body.append($viewBody);
                this.callback(result);
            }, { this: this, callback: callback }));
        }
        export function appendExternalView($document: JQuery, viewUrl: string, callback: Function): void {
            MVVM.getExternalViewHeadAndBody(viewUrl, $.proxy(function (result: { $head: JQuery; $body: JQuery }) {
                var $head = this.$document.find('head');
                var $body = this.$document.find('body');
                $head.append(document.createComment('Loading Started From: "' + this.$document.attr('documentURI') + this.viewUrl + '" With Dsoft.MVVM'));
                $head.append(result.$head.contents());
                $head.append(document.createComment('Loading Finished From: "' + this.$document.attr('documentURI') + this.viewUrl + '" With Dsoft.MVVM'));
                $body.append(document.createComment('Loading Started From: "' + this.$document.attr('documentURI') + this.viewUrl + '" With Dsoft.MVVM'));
                $body.append(result.$body.contents());
                $body.append(document.createComment('Loading Finished From: "' + this.$document.attr('documentURI') + this.viewUrl + '" With Dsoft.MVVM'));
                this.callback();
            }, { this: this, $document: $document, viewUrl: viewUrl, callback: callback }));
        }
        export function appendExternalViewHeadAndFillBodyIn($document: JQuery, $container: JQuery, viewUrl: string, callback: Function): void {
            MVVM.getExternalViewHeadAndBody(viewUrl, $.proxy(function (result: { $head: JQuery; $body: JQuery }) {
                var $head = this.$document.find('head');
                var $body = this.$document.find('body');
                $head.append(document.createComment('Loading Started From: "' + this.$document.attr('documentURI') + this.viewUrl + '" With Dsoft.MVVM'));
                // the following combina cannot be replaced by: $head.append(result.$head.contents()); because of IE bugs.
                result.$head.contents().each(function () {
                    var $this = $(this);
                    if ($this.is('link')) {
                        $('<link>')
                            .appendTo($head)
                            .attr({ type: 'text/css', rel: 'stylesheet' })
                            .attr('href', $this.attr('href'));
                    } else {
                        $head.append(this);
                    }
                });
                $head.append(document.createComment('Loading Finished From: "' + this.$document.attr('documentURI') + this.viewUrl + '" With Dsoft.MVVM'));

                $container.html(result.$body.html());
                $container.prepend($(document.createComment('Loading Started From: "' + this.$document.attr('documentURI') + this.viewUrl + '" With Dsoft.MVVM')));
                $container.append($(document.createComment('Loading Finished From: "' + this.$document.attr('documentURI') + this.viewUrl + '" With Dsoft.MVVM')));

                this.callback();
            }, { this: this, $document: $document, viewUrl: viewUrl, callback: callback }));
        }
    }
}