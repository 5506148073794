module AlHaperek {
    export module Parshanim {
        export class AddressLineState {
            parshanSafeName: string;
            callback: Function;
            cyclic: boolean;
            guid: number;
            static counter: number = 0;
            constructor(parshanSafeName?: string, callback?: Function, cyclic: boolean = false) {
                this.callback = callback;
                this.cyclic = cyclic;
                this.guid = AddressLineState.counter++;
                this.parshanSafeName = parshanSafeName;
            }
            isParshanNameLegal() {
                return (typeof this.parshanSafeName !== 'undefined' && this.parshanSafeName != "");
            }
            translateToRelativePath() {
                var relativePath: string = "";
                var SEPARATOR = "/";
                if (!this.isParshanNameLegal()) {
                    relativePath = "";
                } else {
                    relativePath = this.parshanSafeName.toString();
                }
                return relativePath;
            }
        }
        export class AddressLineManager {
            currentState: AddressLineState;
            originalWindowLocation: string;
            states: AddressLineState[] = [];
            wait: boolean = false;
            constructor(state?: AddressLineState) {
                var SEPARATOR = "/";

                this.originalWindowLocation = window.location.href.substr(0, window.location.href.indexOf('929/parshanim') + '929/parshanim'.length);


                if (typeof state !== 'undefined') {
                    this.states[state.guid] = state;
                    window.history.replaceState(state.guid, 'Title', this.originalWindowLocation + SEPARATOR + state.translateToRelativePath());
                }

                $(window).bind('popstate', $.proxy(this.onPopState, this));
            }
            changeState(state: AddressLineState) {
                if (state.cyclic && this.wait) {
                    this.wait = false;
                    return;
                }
                var SEPARATOR = "/";
                this.states[state.guid] = state;
                window.history.pushState(state.guid, 'Title', this.originalWindowLocation + SEPARATOR + state.translateToRelativePath());
                if(typeof ga !== 'undefined') ga('send', 'pageview', location.pathname);
            }
            replaceState(state: AddressLineState) {
                if (state.cyclic && this.wait) {
                    this.wait = false;
                    return;
                }
                var SEPARATOR = "/";
                this.states[state.guid] = state;
                window.history.replaceState(state.guid, 'Title', this.originalWindowLocation + SEPARATOR + state.translateToRelativePath());
            }
            onPopState(e: JQueryEventObject) {
                if(typeof ga !== 'undefined') ga('send', 'pageview', location.pathname);
                var guid = e.originalEvent['state'];
                var state: AddressLineState = this.states[guid];
                if (state.cyclic)
                    this.wait = true;
                state.callback();
            }
        }
    }
}