/// <reference path="../../../shared/model/pojso/name-and-value.ts" />
module Tanahpedia {
    export module Entries {
        export class MetaDataCategory extends Shared.NameAndValue {
            constructor(name: string, value: string, metaDatas: Array<Shared.NameAndValue>, offset?: number) {
                super(name, value);
                this.metaDatas = metaDatas;
            }
            metaDatas: Array<Shared.NameAndValue>;
            offset: number;
        }
        export class EntriesMetaDataViewmodel {
            private getOffset: any;

            private metaData: EntriesMetaData;

            public categories: Array<MetaDataCategory>;
            public entriesCategoriesOffsets: Dsoft.DS.Map<MetaDataCategory, number>;
            public metaDataViewmodels: Array<EntryMetaDataViewmodel>;
            constructor(metaData: EntriesMetaData) {
                this.init(metaData);
            }

            init(metaData: EntriesMetaData) {
                this.metaData = metaData;
                this.metaDataViewmodels = new Array<EntryMetaDataViewmodel>();

                var getOffset = this.getOffset = $.proxy(function (metaDataCategory: MetaDataCategory) { return this.entriesCategoriesOffsets.get(metaDataCategory); }, this);
                var getOffsetPlusLength = $.proxy(function (metaDataCategory: MetaDataCategory) {
                    return this.getOffset(metaDataCategory) + (typeof metaDataCategory.metaDatas !== 'undefined' ? metaDataCategory.metaDatas.length : 0);
                }, this);
                var animals = metaData.animals;
                var artifacts = metaData.artifacts;
                var events = metaData.events;
                var persons = metaData.persons;
                var places = metaData.places;
                var plants = metaData.plants;
                var prophecys = metaData.prophecys;
                var wars = metaData.wars;


                var categories = this.categories = new Array<MetaDataCategory>();
                var personsCategory = new MetaDataCategory('persons', 'אִישִׁים', persons);
                var eventsCategory = new MetaDataCategory('events', 'אֵרוּעִים', events);
                var placesCategory = new MetaDataCategory('places', 'אֲתַרִים', places);
                var animalsCategory = new MetaDataCategory('animals', 'בַּעֲלֵי חַיִּים', animals);
                var artifactsCategory = new MetaDataCategory('artifacts', 'חֲפָצִים', artifacts);
                var warsCategory = new MetaDataCategory('wars', 'מִלְחָמוֹת', wars);
                var prophecysCategory = new MetaDataCategory('prophecys', 'נְבוּאוֹת', prophecys);
                var plantsCategory = new MetaDataCategory('plants', 'צְמָחִים', plants);

                categories = this.categories = [personsCategory, eventsCategory, placesCategory, animalsCategory, artifactsCategory, warsCategory, prophecysCategory, plantsCategory];

                var entriesCategoriesOffsets = this.entriesCategoriesOffsets = new Dsoft.DS.Map<MetaDataCategory, number>();
                for (var i = 0; i < categories.length; i++) {
                    var offset = (i == 0 ? 0 : getOffsetPlusLength(categories[i - 1]));
                    categories[i].offset = offset;
                    entriesCategoriesOffsets.set(categories[i], offset);
                }

                categories.forEach(function (value: MetaDataCategory, index: number, array: MetaDataCategory[]) {
                    this.moveCategoryToMetaDataViewmodels(value.metaDatas, value.offset, value.name.substr(0, value.name.length - 1));
                }, this);

            }
            
            getIndexByMetaData(entryMetaDataViewmodel: EntryMetaDataViewmodel): number {
                return Dsoft.DS.getIndexOfElement(this.metaDataViewmodels, entryMetaDataViewmodel);
            }
            getIndexByNameAndType(name: string, type: string): number {
                for (var i = 0; i < this.metaDataViewmodels.length; i++) {
                    if (this.metaDataViewmodels[i].name === name && this.metaDataViewmodels[i].type === type)
                        return i;
                }
                return i;
            }
            getMetaDataByIndex(index: number): EntryMetaDataViewmodel {
                return this.metaDataViewmodels[index];
            }

            getMetaDataByNameAndType(name: string, type: string): EntryMetaDataViewmodel {
                return this.getMetaDataByName(name);

                //for (var i = 0; i < this.metaDataViewmodels.length; i++) {
                //    if (this.metaDataViewmodels[i].name === name && this.metaDataViewmodels[i].type === type)
                //        return this.metaDataViewmodels[i];
                //}
                //return undefined;

                // For Efficiency Consider Advance this:
                //var tmpCategories = this.categories.filter(function (value: MetaDataCategory, index: number, array: MetaDataCategory[]) {
                //    return type === value.name.substr(0, value.name.length - 1);
                //});
                //if (tmpCategories.length < 1) {
                //    console.log('bad type');
                //    return undefined;
                //}
                //var category = tmpCategories[0];
                //var categoryOffset = this.entriesCategoriesOffsets.get(category);
                //for (var i = 0; i < category.metaDatas.length; i++) {
                //    if (category.metaDatas[i].name == name)
                //        break;
                //}

                //return this.metaDataViewmodels[index];
            }
            getMetaDataByName(name: string): EntryMetaDataViewmodel {
                for (var i = 0; i < this.metaDataViewmodels.length; i++) {
                    if (this.metaDataViewmodels[i].name === name)
                        return this.metaDataViewmodels[i];
                }
                return undefined;
            }
            //getCategoryByIndex(index: number) : MetaDataCategory {
            //    var metaData = this.metaData;

            //    var getOffset = this.getOffset;
            //    var getCategoryByMetaDatas = $.proxy(function (metaDatas) {
            //        return this.categories.filter(function (value: MetaDataCategory, index: number, array: MetaDataCategory[]) {
            //            return value.metaDatas == metaDatas;
            //        }).pop();
            //    }, this);
            //    if (index >= getOffset(metaData.persons) && index < getOffset(metaData.events)) {
            //        return getCategoryByMetaDatas(metaData.persons);
            //    }
            //    if (index >= getOffset(metaData.events) && index < getOffset(metaData.places)) {
            //        return getCategoryByMetaDatas(metaData.events);
            //    }
            //    if (index >= getOffset(metaData.places) && index < getOffset(metaData.animals)) {
            //        return getCategoryByMetaDatas(metaData.places);
            //    }
            //    if (index >= getOffset(metaData.animals) && index < getOffset(metaData.artifacts)) {
            //        return getCategoryByMetaDatas(metaData.animals);
            //    }
            //    if (index >= getOffset(metaData.artifacts) && index < getOffset(metaData.wars)) {
            //        return getCategoryByMetaDatas(metaData.artifacts);
            //    }
            //    if (index >= getOffset(metaData.wars) && index < getOffset(metaData.prophecys)) {
            //        return getCategoryByMetaDatas(metaData.wars);
            //    }
            //    if (index >= getOffset(metaData.prophecys)) {
            //        return getCategoryByMetaDatas(metaData.prophecys);
            //    }
            //    return null;
            //}
            isMetaDataExist(name: string, type?: string): boolean {
                return this.getMetaDataByName(name) !== undefined;
            }

            get totalCount(): number {
                return this.metaDataViewmodels.length;
            }


            private moveCategoryToMetaDataViewmodels(categoryMetaDatas: Array<Shared.NameAndValue>, offset: number, type: string): void {
                if (typeof categoryMetaDatas === 'undefined') return;
                categoryMetaDatas.forEach(function (value: Shared.NameAndValue, index: number, array: Shared.NameAndValue[]) {
                    this.metaDataViewmodels.push(new EntryMetaDataViewmodel(value.name, value.value, index + offset, type));
                }, this);
            }
        }
    }
}