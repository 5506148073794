module AlHaperek {
    export class SystemMessagesDataSource {
        static systemMessages: SystemMessage[] = [];

        loadSystemMessages(): Promise<SystemMessage[]> {
            if (SystemMessagesDataSource.systemMessages.length > 0) {
                return Promise.resolve(SystemMessagesDataSource.systemMessages);
            }

            var settings = { async: true, type: 'GET', url: Conn.remotePath + 'get_system_messages.php', data: { 'all': true }, dataType: 'json' };

            return Dsoft.Ajax.commonAjaxCall<SystemMessage[], SystemMessage[]>(settings, (message: SystemMessage[]) => {
                SystemMessagesDataSource.systemMessages = message;
                return message;
            });
        }
    }
}