module App_V3 {
    export module Application {
        export module Actions {
            export var defaultbackButtonAction: () => void = () => {
                if ($("#app>.popover.modal-in").length) {
                    App_V3.app.underlying.popover.close('.popover.modal-in');
                    return;
                }
                if ($("#app>.sheet-modal.modal-in").length) {
                    App_V3.app.underlying.sheet.close('.sheet-modal.modal-in');
                    return;
                }
                // there is history
                if (ResourcesPool.ViewPool.currentRouter.history.length > 1) {
                    // go back
                    ResourcesPool.ViewPool.currentRouter.back();
                } else { // there isn't history
                    // open main panel
                    ResourcesPool.ViewPool.mainPanelView.underlying.open();
                    // set back button action to exit app
                    App_V3.app.backButtonAction = () => {
                        navigator['app'].exitApp();
                    }
                }
            };
            //export var defaultMenuButtonAction: () => void = () => {
            //    if ($("#app>.modal-in").length) { // there is already an opened modal
            //        return;
            //    }
            //    var mainPopoverOpenerSelector = '.page.page-current .popover-open.more-options-btn';
            //    var $mainPopoverOpener = $(mainPopoverOpenerSelector);
            //    if (!$mainPopoverOpener.length) { // nothing to open
            //        return;
            //    }
            //    if ($("#app>.panel-active").length) {
            //        App_V3.app.underlying.panel.close();
            //    }
            //    var popoverSelector = $mainPopoverOpener.attr('data-popover');
            //    App_V3.app.underlying.popover.open(popoverSelector, mainPopoverOpenerSelector, true);
            //    App_V3.app.backButtonAction = defaultbackButtonAction;
            //};
        }
    }
}