//module Tanahpedia {
//    export module Entries {
//        export module Place {
//            export class RecognitionViewmodel {

//                public polygon: ko.Observable<PolygonViewmodel>;
//                public changedEventHandler: EventHandler;

//                constructor(recognition: Recognition = new Recognition()) {
//                    this.changedEventHandler = new EventHandler();
//                    this.polygon = ko.observable(PolygonViewmodel.modelToViewmodel(recognition.polygon, { handler: this.changed, owner: this }));

//                    this.polygon.subscribe(this.changed, this);
//                }
//                public toModel(): Recognition {
//                    return RecognitionViewmodel.viewmodelToModel(this);
//                }
//                private changed() {
//                    this.changedEventHandler.fire(new EventArgs(this, { data: null }));
//                }

//                public static reverseMapper(value: RecognitionViewmodel, index: number, array: RecognitionViewmodel[]): Recognition { return RecognitionViewmodel.viewmodelToModel(value); }
//                public static viewmodelToModel(viewmodel: RecognitionViewmodel): Recognition {
//                    return new Recognition(viewmodel.polygon().toModel());
//                }
//                public static viewmodelsToModels(models: RecognitionViewmodel[]): Recognition[] {
//                    if (typeof models !== 'undefined')
//                        return models.map(RecognitionViewmodel.reverseMapper);
//                    else
//                        return [];
//                }
//                public static mapper(value: Recognition, index: number, array: Recognition[]): RecognitionViewmodel { return RecognitionViewmodel.modelToViewmodel(value); }
//                public static modelToViewmodel(model: Recognition, handlerDetails?: { handler: Function; owner: any }): RecognitionViewmodel {
//                    var vm = new RecognitionViewmodel(model);
//                    if (typeof handlerDetails !== 'undefined') {
//                        vm.changedEventHandler.register(handlerDetails.handler, handlerDetails.owner);
//                    }
//                    return vm;
//                }
//                public static modelsToViewmodels(models: Recognition[], handlerDetails?: { handler: Function; owner: any }): RecognitionViewmodel[] {
//                    var vms: RecognitionViewmodel[];
//                    if (typeof models !== 'undefined')
//                        vms = models.map(RecognitionViewmodel.mapper);
//                    else
//                        vms = [];
//                    for (var i = 0; i < vms.length; i++) {
//                        vms[i].changedEventHandler.register(handlerDetails.handler, handlerDetails.owner);
//                    }
//                    return vms;
//                }

//            }
//        }
//    }
//}