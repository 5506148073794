module Tanahpedia {
    export module Entries {
        export module Person {
            export class FamilyTreeView {
                public loadedByNodeClick: boolean = false;

                public personEntryViewmodel: Person.EntryViewmodel;
                public localPersonEntryViewmodel: Person.EntryViewmodel;
                public historyPersonVM: Person.EntryViewmodel;
                public rootNode: ko.Observable<FamilyTreeNode> = ko.observable(new FamilyTreeNode(new NameAndValueViewmodel("", ""), ""));
                public indexBetweenSiblings: ko.Observable<number> = ko.observable(0);

                constructor(personVM?: Person.EntryViewmodel) {
                    this.init(personVM);
                }
                init(personVM?: Person.EntryViewmodel) {
                    if (typeof personVM !== 'undefined') {
                        this.personEntryViewmodel = personVM;
                        this.entryLoaded();
                    } else {
                        this.personEntryViewmodel = new Person.EntryViewmodel();
                    }
                    personVM.entryLoadedEventHandler.register(this.entryLoaded, this);

                    this.historyPersonVM = new Person.EntryViewmodel();
                    personVM.entryLoadedEventHandler.register(this.historyEntryLoaded, this);
                }
                fillTree(personEntryViewmodel: Person.EntryViewmodel) {
                    this.localPersonEntryViewmodel = personEntryViewmodel.clone();
                    var familyViewmodel: FamilyViewmodel = this.localPersonEntryViewmodel.family;
                    // Init root node with father
                    this.rootNode(new FamilyTreeNode(familyViewmodel.father, Person.Gendre.MALE));
                    // Add mother
                    this.rootNode().spouses([new FamilyTreeNode(familyViewmodel.mother, Person.Gendre.FEMALE)]);
                    // Init person itself node
                    var thePersonNode = new FamilyTreeNode(new NameAndValueViewmodel(this.localPersonEntryViewmodel.name(), this.localPersonEntryViewmodel.value()), this.localPersonEntryViewmodel.gendre());
                    // Add person's wives / husbands
                    if (this.localPersonEntryViewmodel.gendre() == 'זכר') {
                        thePersonNode.spouses(familyViewmodel.wives.map((wife, i, arr) => new FamilyTreeNode(wife, Person.Gendre.FEMALE)));
                    } else {
                        thePersonNode.spouses(familyViewmodel.husbands.map((husband, i, arr) => new FamilyTreeNode(husband, Person.Gendre.MALE)));
                    }
                    // Add person's childs
                    familyViewmodel.children.forEach((child, i, arr) =>
                        thePersonNode.children.push(new FamilyTreeNode(child, ''))
                    );
                    // Add person's siblings and person itself
                    this.indexBetweenSiblings(familyViewmodel.indexBetweenSiblings);
                    // Validate indexBetweenSiblings and set it to 1 if not valid.
                    if ([undefined, null, 0].indexOf(this.indexBetweenSiblings()) !== -1) this.indexBetweenSiblings(1);
                    this.rootNode().children.removeAll();
                    var siblingsNodes = familyViewmodel.siblings.map((sibling, i, arr) => new FamilyTreeNode(sibling, ''));
                    siblingsNodes.slice(0, this.indexBetweenSiblings() - 1).forEach((biggerSibling, i, arr) => this.rootNode().children.push(biggerSibling), this);
                    this.rootNode().children.push(thePersonNode);
                    siblingsNodes.slice(this.indexBetweenSiblings() - 1).forEach((littleSibling, i, arr) => this.rootNode().children.push(littleSibling), this);

                    // attachEvents
                    this.rootNode().traverse().forEach((value: FamilyTreeNode, index: number, array: FamilyTreeNode[]) => {
                        value.clickedEventHandler.register(this.onNodeClicked, this);
                        value.selectedEventHandler.register(this.onNodeSelected, this);
                    }, this);
                }
                entryLoaded() {
                    //if (typeof (this.personEntryViewmodel.name()) === 'undefined') {
                    //    this.loadedByNodeClick = false;
                    //    return;
                    //}
                    this.fillTree(this.personEntryViewmodel);
                    if (this.loadedByNodeClick) {
                        var historyPreviousCallback: Function = <Function>$.proxy((entryName: string) => {
                            this.personEntryViewmodel.name(entryName);
                            this.personEntryViewmodel.load();
                        }, this, this.personEntryViewmodel.name());
                        ResourcesPool_V2.ViewPool.indexView.addressLineMgr.changeState(new AddressLineState(this.localPersonEntryViewmodel.name(), Entries.Type.person, historyPreviousCallback, false));
                        this.loadedByNodeClick = false;
                    }
                }
                historyEntryLoaded() {
                    //this.fillTree(this.historyPersonVM.family);
                }
                historyPreviousCallback(previousEntryName: string) : void {
                    this.historyPersonVM.name(previousEntryName);
                    this.historyPersonVM.load();
                }
                onNodeClicked(sender: any, e: { data: FamilyTreeNode }): void {
                    var entryToLoadName = e.data.nameAndValue.name;
                    if (!ResourcesPool_V2.ViewmodelPool.entriesMetaDataViewmodel.isMetaDataExist(entryToLoadName))
                        return;
                    this.personEntryViewmodel.name(entryToLoadName);
                    this.loadedByNodeClick = true;
                    this.personEntryViewmodel.load();
                }
                onNodeSelected(sender: any, e: { data: FamilyTreeNode }): void {
                    var entryToLoadName = e.data.nameAndValue.name;
                    if (!ResourcesPool_V2.ViewmodelPool.entriesMetaDataViewmodel.isMetaDataExist(entryToLoadName))
                        return;
                    this.personEntryViewmodel.name(entryToLoadName);
                    this.localPersonEntryViewmodel.entryLoadedEventHandler = new EventHandler();
                    this.localPersonEntryViewmodel.entryLoadedEventHandler.register(() => this.fillTree(this.localPersonEntryViewmodel), this);
                    this.personEntryViewmodel.load();
                }
            }
        }
    }
}