module App_V3 {
    export module AppAlHaperek {
        export class PerekExplainView {
            public perekViewmodel: AlHaperek.PerekViewModel;

            public articlesView: App_V3.AppAlHaperek.ArticlesView;
            public perushimView: App_V3.AppAlHaperek.PerushimView;

            private $selectedArticleContent: JQuery;
            private $selectedArticleContentSection: JQuery;
            private $selectedPerushContent: JQuery;
            private $selectedPerushContentSection: JQuery;

            constructor(perekViewmodel: AlHaperek.PerekViewModel) {
                this.init(perekViewmodel);
            }
            init(perekViewmodel: AlHaperek.PerekViewModel) {

                this.perekViewmodel = perekViewmodel;
                this.articlesView = new App_V3.AppAlHaperek.ArticlesView(perekViewmodel);
                this.perushimView = new App_V3.AppAlHaperek.PerushimView(perekViewmodel);

            }
        }
    }
}