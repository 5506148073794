module Tanahpedia {
    export module Entries {
        export interface ResponsiveIndexViewConf {
            entriesMetaData: EntriesMetaData,
            entry: Entry,
            entryExtended : string,
            entryName: string
            entryType: string
        }
        export class ResponsiveIndexView {

            //public addressLineMgr: AddressLineManager;
            entriesMetaData: EntriesMetaData;
            entry: Entry;
            entryExtended: string;
            entryName: string;
            entryType: string;

            entriesMetadataViewmodel: EntriesMetaDataViewmodel;
            entryViewmodel: EntryViewmodel;
            entryExtendedViewmodel: EntryExtendedViewmodel;

            public addressLineMgr: AddressLineManager;
            public bookView: BookView;
            public syncView: SyncView;

            constructor(conf: ResponsiveIndexViewConf) {
                Tanahpedia.Entries.ResourcesPool_V2.ViewPool.indexView = this;
                this.init(conf);
            }
            //init(perekId?: number, articleId?: number) {
            init(conf: ResponsiveIndexViewConf) {
                this.entriesMetaData = ResourcesPool_V2.ModelPool.entriesMetaData = conf.entriesMetaData;
                this.entry = conf.entry;
                this.entryExtended = conf.entryExtended;
                this.entryName = conf.entryName;
                this.entryType = conf.entryType;

                var addressLineChanged: Function = <Function>$.proxy(this.load, this, this.entryName, this.entryType);
                //var addressLineChanged: Function = <Function>$.proxy(function (entryName, entryType) {
                //    this.load(entryName, entryType);

                //    if (!ResourcesPool_V2.ViewPool.isPhone) {
                //        this.bookView.perekId(perekId);
                //    }
                //}, this, this.entryName, this.entryType);
                this.addressLineMgr = new AddressLineManager(new AddressLineState(this.entryName, this.entryType, addressLineChanged, true));

                


                this.entriesMetadataViewmodel = ResourcesPool_V2.ViewmodelPool.entriesMetaDataViewmodel = new EntriesMetaDataViewmodel(this.entriesMetaData);
                switch (this.entryType){
                    case 'person':
                        this.entryViewmodel = new Person.EntryViewmodel();
                        (<Person.EntryViewmodel>this.entryViewmodel).fillWithModel(<Person.Entry>this.entry);
                        break;
                }
                this.entryExtendedViewmodel = new EntryExtendedViewmodel(this.entryViewmodel);
                this.entryExtendedViewmodel.setHtml(this.entryExtended);
                this.syncView = new SyncView(this.entryViewmodel, this.entryExtendedViewmodel);

                if (!ResourcesPool_V2.ViewPool.isPhone) {
                    //this.syncView.hiddenEventHandler.register(function (sender: SyncView, e: { data: any }) {
                    //    if (this.bookView.perekId() != sender.perekViewmodel.perekId() || sender.articlesViewmodel.selectedArticleViewmodel() != null) {
                    //        this.bookView.updatePerekId(sender.perekViewmodel.perekId(), true);
                    //    }
                    //}, this);

                    // debug not book
                    this.bookView = new BookView(this.entriesMetadataViewmodel);
                    var metaDataIdx = this.entriesMetadataViewmodel.getIndexByNameAndType(this.entryName, this.entryType);
                    var pageIdx = this.bookView.getPageIdxByEntryMetaDataViewmodelIdx(metaDataIdx);
                    if (!ResourcesPool_V2.ViewPool.isPhone) {
                        this.bookView.pageToPageIdx(pageIdx);
                    }
                }
            }
            load(name: string, type?: string) {
                if (typeof type === 'undefined') {
                    var entryMetaDataVM = this.entriesMetadataViewmodel.getMetaDataByName(name);
                    type = entryMetaDataVM.type;
                }
                this.entryViewmodel.name(name);
                this.entryViewmodel.type(type);
                this.entryViewmodel.load();
                this.entryExtendedViewmodel.load();
                var metaDataIdx = this.entriesMetadataViewmodel.getIndexByNameAndType(name, type);
                var pageIdx = this.bookView.getPageIdxByEntryMetaDataViewmodelIdx(metaDataIdx);
                if (!ResourcesPool_V2.ViewPool.isPhone) {
                    this.bookView.pageToPageIdx(pageIdx);
                }
            }
        }
    }
}