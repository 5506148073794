module Tanahpedia {

    export class ResponsiveIndexView {
        public metadata: Entries.MetaData;


        constructor(metadata: Entries.MetaData) {
            this.init(metadata);

        }
        init(metadata: Entries.MetaData) {
            this.metadata = metadata;

            ko.applyBindings(this, document.getElementById('body'));
        }
    }
}