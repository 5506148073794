module Dsoft {
    export module Ajax {
        var debounceIds = new Array<any>();
        export function intuitiveClick(e: MouseEvent | JQueryMouseEventObject, onClick: Function, onOtherClicks: Function = null) {
            if (!e.ctrlKey && !e.shiftKey && e.which != 2 && e.which != 3) {
                e.preventDefault();
                onClick(e);
            } else if (onOtherClicks)  {
                onOtherClicks(e);
            }
        }
        export interface CommonServerResponse<T> {
            message: T, success: boolean, errors: string[]
        }
        export interface DebounceSettings {
            enabled: boolean,
            id?: any
        }
        export var defaultDebounceSettings: DebounceSettings = {
            enabled: false
        };
        export var defaultProcessMsgCallback = (message: any): any => message;
        export function commonAjaxCall<T, U>(settings: JQueryAjaxSettings, processMsgCallback: (message: U) => T = defaultProcessMsgCallback, debounceSettings : DebounceSettings = defaultDebounceSettings): Promise<T> {
            var executor = (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => {
                var promisedProcessData = (data: CommonServerResponse<U>): U => {
                    var message = null;
                    if (data.success) {
                        message = data.message;
                        resolve(processMsgCallback(message));
                    }
                    else {
                        reject(data.errors);
                    }
                    return message;
                }

                var doneCallback = (data: CommonServerResponse<U>, textStatus: string, jqXHR: JQueryXHR): U => {
                    return promisedProcessData(data);
                };

                var failCallback = (jqXHR: JQueryXHR, textStatus: string, errorThrown: any): void => {
                    try {
                        var result = <CommonServerResponse<U>>(JSON.parse(jqXHR.responseText.substring(jqXHR.responseText.indexOf('{'))));
                        doneCallback(result, textStatus, jqXHR);
                    } catch (ex) {
                        reject();
                    }
                };

                $.ajax(settings).then<U>(doneCallback, failCallback);
            };
            var promise = new Promise(executor);

            if (debounceSettings.enabled) {
                return Dsoft.Debounce.debouncedPromise(promise, debounceSettings.id);
            } else {
                return promise;
            }
        }

        export function minimalAjaxCall<T>(settings: JQueryAjaxSettings, processResponseCallback: (response: T) => T = (response: T): T => response ): Promise<T> {
            return new Promise((resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => {
                var doneCallback = (data: T, textStatus: string, jqXHR: JQueryXHR): T => {
                    try {
                        resolve(processResponseCallback(data));
                    } catch (e) {
                        reject(new Error("error while processing response [" + textStatus + "]"));
                    }
                    return data;
                }
                var failCallback = (jqXHR: JQueryXHR, textStatus: string, errorThrown: any): void => {
                    reject(new Error("error loading [" + textStatus + "]"));
                };
                $.ajax(settings).then<T>(doneCallback, failCallback);
            });
        }

        //export function commonDoneCallback<T>(data: { success: boolean, message: T }) {
        //    if (data.success) {
        //        resolve(data.message as AlHaperek.Article);
        //    };
        //}
        //export function commonCheckFail<T>(jqXHR, textStatus, errorThrown, commonDoneCallback: (data: { success: boolean, message: T }) => Promise<T>) => Promise <T>{
        //    try {
        //        var result = JSON.parse(jqXHR.responseText.substring(jqXHR.responseText.indexOf('{')));
        //        doneCallback(result);
        //    } catch (ex) { }
        //}
    }

}
interface JQuery {
    bindToIntuitiveClick(onClick: Function, onOtherClicks?: Function): JQuery;
}
(function ($) {
    $.fn.bindToIntuitiveClick = function (onClick: Function, onOtherClicks: Function = null): JQuery {
        this.bind('click', $.proxy(function (e: JQueryMouseEventObject) {
            Dsoft.Ajax.intuitiveClick(e, this.onClick, this.onOtherClicks);
        }, { onClick: onClick, onOtherClicks: onOtherClicks }));
        return this;
    };

})(jQuery);
