module AlHaperek {
    export class PerekPageView {
        private perekId: number;

        private $enlargeBtn: JQuery;
        private $perekPageElem: JQuery;

        public perekLoadedEventHandler: EventHandler;

        public perekTextViewmodel: PerekTextViewModel;
        public perekViewmodel: PerekViewModel;

        constructor(perekViewmodel: PerekViewModel, perekId : number, $perekPageElem : JQuery) {
            this.init(perekViewmodel, perekId, $perekPageElem);
        }
        init(perekViewmodel: PerekViewModel, perekId: number, $perekPageElem: JQuery) {
            this.perekId = perekId;
            this.$perekPageElem = $perekPageElem;
            this.$enlargeBtn = this.$perekPageElem.find('.enlarge-btn');
            this.perekViewmodel = perekViewmodel;
            this.perekViewmodel.loadedThens.push({
                onfulfilled:
                (perekViewModel: PerekViewModel) => this.loadText()
            });
            this.perekTextViewmodel = new PerekTextViewModel(this.perekViewmodel);

            ko.applyBindings(this, this.$perekPageElem[0]);


            this.perekLoadedEventHandler = new EventHandler();
        }
        loadText() {
            this.perekTextViewmodel.load().then(this.perekTextLoaded.bind(this));
        }
        scrollToPasuk(pasukNum: number) {
            this.$perekPageElem = $('#page-' + this.$perekPageElem.data('page-idx'));
            $.scrollWithJqueryAnim(this.$perekPageElem.find('.perek-text'), $("#perekView_" + this.perekId + "_" + "Pasuk_" + pasukNum));
        }
        perekTextLoaded(perekText: string) {
            this.perekTextViewmodel.$perekText.find('.pasuk-num a').each($.proxy(function (index: number, elem: Element) {
                var $pasukNumA = $(elem);
                $pasukNumA.attr('id', 'perekView_' + this.perekId + '_' + 'Pasuk_' + (index + 1)).data('dest', '#perekExtraView_' + this.perekId + '_' + 'Pasuk_' + (index + 1));
            },this));
            this.perekTextViewmodel.textAsHtml(this.perekTextViewmodel.$perekText.html());

            this.reattachNativeEvents();
            //this.$perekPageElem.find('.perek-text .pasuk-num a').each($.proxy(function (index: number, elem: Element) {
            //    var $pasukNumAnchor = $(elem);
            //    $pasukNumAnchor.bindToIntuitiveClick($.proxy(AlHaperek.ResourcesPool_V2.ViewPool.indexView.bookView.doublePagesViews[this.perekId].perekExtraPageView.scrollToPasukPerush, ResourcesPool_V2.ViewPool.indexView.bookView.doublePagesViews[this.perekId].perekExtraPageView, $pasukNumAnchor.data('pasuk-num')));
            //}, this));
        }
        reattachNativeEvents() {
            this.$enlargeBtn.unbind('click');
            this.$enlargeBtn.bind('click', $.proxy(function () {
                ResourcesPool_V2.ViewPool.indexView.syncView.show();
                ResourcesPool_V2.ViewPool.indexView.syncView.load(this.perekId);
            }, this));
            this.$perekPageElem.find('.perek-text .pasuk-num a').each($.proxy(function (index: number, elem: Element) {
                var $pasukNumAnchor = $(elem);
                $pasukNumAnchor.unbind('click');
                if (typeof AlHaperek.ResourcesPool_V2.ViewPool.indexView.bookView !== 'undefined' && typeof AlHaperek.ResourcesPool_V2.ViewPool.indexView.bookView.doublePagesViews[this.perekId] !== 'undefined') {
                    $pasukNumAnchor.bindToIntuitiveClick($.proxy(AlHaperek.ResourcesPool_V2.ViewPool.indexView.bookView.doublePagesViews[this.perekId].perekExtraPageView.scrollToPasukPerush, ResourcesPool_V2.ViewPool.indexView.bookView.doublePagesViews[this.perekId].perekExtraPageView, $pasukNumAnchor.data('pasuk-num')));
                }
            }, this));
        }
    }

}