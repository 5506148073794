//module Tanahpedia {
//    export module Entries {
//        export module Place {
//            export class PolygonViewmodel {
//                public cords: ko.ObservableArray<CordViewmodel>;
//                public changedEventHandler: EventHandler;
//                public cordRemovedEventHandler: EventHandler;
//                constructor(polygon: Polygon = new Polygon()) {
//                    this.changedEventHandler = new EventHandler();
//                    this.cordRemovedEventHandler = new EventHandler();
//                    this.cords = ko.observableArray(CordViewmodel.modelsToViewmodels(polygon.cords, { handler: this.changed, owner: this }));


//                    this.cords.subscribe(this.changed, this);
//                    this.cords.subscribe($.proxy(function (changes) {
//                        var change = changes[0];
//                        if (change.status == 'deleted')
//                            this.cordRemoved(change.value);
//                    }, this), null, "arrayChange");

//                }
//                public toModel(): Polygon {
//                    return PolygonViewmodel.viewmodelToModel(this);
//                }

//                private changed() {
//                    this.changedEventHandler.fire(new EventArgs(this, { data: null }));
//                }
//                public static viewmodelToModel(viewmodel: PolygonViewmodel): Polygon {
//                    return new Polygon(CordViewmodel.viewmodelsToModels(viewmodel.cords()));
//                }
//                public static modelToViewmodel(model: Polygon, handlerDetails?: { handler: Function; owner: any }): PolygonViewmodel {
//                    var vm = new PolygonViewmodel(model);

//                    if (typeof handlerDetails !== 'undefined') {
//                        vm.changedEventHandler.register(handlerDetails.handler, handlerDetails.owner);
//                    }
//                    return vm;
//                }
//            }
//        }
//    }
//}