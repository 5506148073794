module Dsoft {
    export module GeneralHelper {
        export function closestNumberInArray(num: number, arr: any[]) {
            return arr.reduce(function (prev, curr) {
                return (Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev);
            });
        }
        export function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
        export function keyedObjectToArray(keyedObject: object) {
            var array: any[] = [];
            Object.keys(keyedObject).forEach((key: string, index: number, keys: string[]) => {
                array[key] = keyedObject[key];
            });
            
            return array;
        }
        export function callOnFirstUserInteraction(callback: any) {
            var callbackWithSelfUnbind = function (e: { data: { callback: any, callbackWithSelfUnbind: any } }) {
                e.data.callback();
                $("body,html").unbind("touchstart touchmove scroll mousedown DOMMouseScroll mousewheel keyup", e.data.callbackWithSelfUnbind);
            };
            $("body,html").bind("touchstart touchmove scroll mousedown DOMMouseScroll mousewheel keyup", { callback: callback, callbackWithSelfUnbind: callbackWithSelfUnbind }, callbackWithSelfUnbind);
        }
        export function isSpecialKeyAlsoPressed(e: KeyboardEvent | JQueryKeyEventObject): boolean {
            return e.altKey || e.ctrlKey || e.metaKey || e.shiftKey;
        }
        export function getMapPlainEntries<K, V>(map: Map<K, V>): [K, V][] {
            var serializedMap: [K, V][] = [];
            map.forEach((property, name, entries) => serializedMap.push([name, property]));

            return serializedMap;
        }
        export interface Then<T> {
            onfulfilled?: ((value: T) => any | PromiseLike<any>) | undefined | null;
            onrejected?: ((reason: any) => any | PromiseLike<any>) | undefined | null;
        }
        export function chainThensToPromise<T>(promise: Promise<T>, thens: Then<T>[]): Promise<T> {
            for (var then of thens) {
                promise.then(then.onfulfilled, then.onrejected);
            }
            return promise;
        }
        Promise.prototype.thens = function thens<T>(thens: Then<T>[]): Promise<T> {
            return chainThensToPromise(this, thens);
        }
    }
}
interface Promise<T> {
    thens(thens: Dsoft.GeneralHelper.Then<T>[]): Promise<T>;
}