module Dsoft {
    export module Storage {
        export function setObject<T>(key: string, object: T, storage = localStorage) {
            storage.setItem(key, JSON.stringify(object));
        }
        export function getObject<T>(key: string, storage = localStorage) {
            return <T>JSON.parse(localStorage.getItem(key));
        }
    }
}
