//module Tanahpedia {
//    export module Entries {
//        export module Place {
//            export class CordViewmodel {

//                public xCord: ko.Observable<number>;
//                public yCord: ko.Observable<number>;
//                public changedEventHandler: EventHandler;
//                constructor(xCord: number = 0, yCord: number = 0) {
//                    this.xCord = ko.observable(xCord);
//                    this.yCord = ko.observable(yCord);
//                    this.changedEventHandler = new EventHandler();

//                    this.xCord.subscribe(this.changed, this);
//                    this.yCord.subscribe(this.changed, this);
//                }
//                public toModel(): Cord {
//                    return CordViewmodel.viewmodelToModel(this);
//                }
//                private changed() {
//                    this.changedEventHandler.fire(new EventArgs(this, { data: null }));
//                }

//                public static reverseMapper(value: CordViewmodel, index: number, array: CordViewmodel[]): Cord { return CordViewmodel.viewmodelToModel(value); }
//                public static viewmodelToModel(viewmodel: CordViewmodel): Cord {
//                    return new Cord(viewmodel.xCord(), viewmodel.yCord());
//                }
//                public static viewmodelsToModels(models: CordViewmodel[]): Cord[] {
//                    if (typeof models !== 'undefined')
//                        return models.map(CordViewmodel.reverseMapper);
//                    else
//                        return [];
//                }
//                public static mapper(value: Cord, index: number, array: Cord[]): CordViewmodel { return CordViewmodel.modelToViewmodel(value); }
//                public static modelToViewmodel(model: Cord, handlerDetails?: { handler: Function; owner: any }): CordViewmodel {
//                    var vm: CordViewmodel;
//                    if (typeof model !== 'undefined')
//                        vm = new CordViewmodel(model.xCord, model.yCord);
//                    else
//                        vm = new CordViewmodel();
//                    if (typeof handlerDetails !== 'undefined') {
//                        vm.changedEventHandler.register(handlerDetails.handler, handlerDetails.owner);
//                    }
//                    return vm;
//                }
//                public static modelsToViewmodels(models: Cord[], handlerDetails?: { handler: Function; owner: any }): CordViewmodel[] {
//                    var vms: CordViewmodel[];
//                    if (typeof models !== 'undefined')
//                        vms = models.map(CordViewmodel.mapper);
//                    else
//                        vms = [];
//                    for (var i = 0; i < vms.length; i++) {
//                        vms[i].changedEventHandler.register(handlerDetails.handler, handlerDetails.owner);
//                    }
//                    return vms;
//                }

//            }
//        }
//    }
//}