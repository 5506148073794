class Rabbi {
    public id: number;
    public details: string;
    public name: string;

    constructor(id: number, details: string, name: string) {
        this.id = id;
        this.details = details;
        this.name = name;
    }
}