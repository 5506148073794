module App_V3 {
    export module AppPreferences {
        export class PropertyViewmodel<T> {
            private filledInternally: boolean = false;
            public name: string;
            public value: ko.Observable<T>;
            public fireBaseProperty: string = "";

            public valueChangedEventHandler: EventHandler;

            constructor(name: string, value: T, fireBaseProperty = "") {
                this.filledInternally = false; // keep it.
                this.name = name;
                this.value = ko.observable(value);
                this.fireBaseProperty = fireBaseProperty;
                this.valueChangedEventHandler = new EventHandler();

                this.value.subscribe((newValue: T) => {
                    this.valueChangedEventHandler.fire(new EventArgs(this, { data: newValue }));
                }, this);
            }
            public fill(property: Property<T>) {
                this.filledInternally = true;
                this.value(property.value);
                this.filledInternally = false; // keep it.
            }
            public resetToDefault() {
                this.value((new Preferences()).getProperty<T>(this.name).value);
            }

            public static modelToViewmodel<T>(name: string, model: Property<T>): PropertyViewmodel<T> {
                var vm: PropertyViewmodel<T> = null;
                if (typeof model !== 'undefined')
                    vm = new PropertyViewmodel<T>(name, model.value, model.fireBaseProperty);
                return vm;
            }
        }

        export class PreferencesViewmodel {
            public modelPropsMap: Map<string, Property<any>>;

            constructor(preferences: Preferences = new Preferences()) {

                var modelPropNames = Object.getOwnPropertyNames(preferences);

                this.modelPropsMap = new Map<string, Property<any>>(modelPropNames.map((propName, i, propNames) =>
                    <[string, Property<any>]>[propName, preferences[propName]])
                );

                for (var [name, property] of this.modelPropsMap) {
                    var propertyVM = PropertyViewmodel.modelToViewmodel(name, property);
                    propertyVM.valueChangedEventHandler.register(this.propertyChanged, this);
                    Object.defineProperty(this, name, { writable: false, value: propertyVM });
                }
            }
            public load() {
                var loadedMapPlainEntries = Dsoft.Storage.getObject<[string, Property<any>][]>("preferences");
                if (loadedMapPlainEntries == null) return;
                var currentPlainEntries = Dsoft.GeneralHelper.getMapPlainEntries(this.modelPropsMap);
                var intersectedModelPropsMap = loadedMapPlainEntries.filter((loadedEntry, i, loadedEntries) =>
                    $.inArray(loadedEntry[0], currentPlainEntries.map((currentEntry, i, currentEntries) =>
                        currentEntry[0])
                    ) !== - 1
                );
                for (var [name, property] of intersectedModelPropsMap) {
                    (<PropertyViewmodel<any>>this[name]).fill(property);
                    this.modelPropsMap.set(name, property);
                    //this.propertyVMsMap.get(name).fillValue(property.value);
                }
            }
            getProperty<T>(name: string): PropertyViewmodel<T> {
                return <PropertyViewmodel<T>>this[name];
            }
            propertyChanged(sender: PropertyViewmodel<any>, e: { data: any }) {
                this.modelPropsMap.get(sender.name).value = e.data;
                Dsoft.Storage.setObject("preferences", Dsoft.GeneralHelper.getMapPlainEntries(this.modelPropsMap));
                if (sender.fireBaseProperty != "" && typeof (window.FirebasePlugin) !== 'undefined') {
                    var value: any;
                    if (typeof e.data == 'boolean') value = e.data ? 1 : 0;
                    else value = e.data;
                    window.FirebasePlugin.setUserProperty(sender.fireBaseProperty, value, (e) => console.log(e), (e) => console.log(e));
                }
            }
        }
    }
}