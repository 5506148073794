module Tanahpedia {
    export module Entries {
        export module Person {
            export class FamilyViewmodel {
                public children: Array<NameAndValueViewmodel>;
                public father: NameAndValueViewmodel;
                public husbands: Array<NameAndValueViewmodel>;
                public indexBetweenSiblings: number;
                public mother: NameAndValueViewmodel;
                public siblings: Array<NameAndValueViewmodel>;
                public wives: Array<NameAndValueViewmodel>;


                constructor(personEntryFamily: Family) {
                    if (typeof personEntryFamily == 'undefined')
                        personEntryFamily = new Family();
                    this.children = NameAndValueViewmodel.modelsToViewmodels(personEntryFamily.children);
                    this.father = NameAndValueViewmodel.modelToViewmodel(personEntryFamily.father);
                    this.husbands = NameAndValueViewmodel.modelsToViewmodels(personEntryFamily.husbands);
                    this.indexBetweenSiblings = personEntryFamily.indexBetweenSiblings;
                    this.mother = NameAndValueViewmodel.modelToViewmodel(personEntryFamily.mother);
                    this.siblings = NameAndValueViewmodel.modelsToViewmodels(personEntryFamily.siblings);
                    this.wives = NameAndValueViewmodel.modelsToViewmodels(personEntryFamily.wives);

                }

                public toModel() {
                    return FamilyViewmodel.viewmodelToModel(this);
                }

                public static viewmodelToModel(viewmodel: FamilyViewmodel): Family {
                    var model = new Family();

                    model.children = viewmodel.children.map(NameAndValueViewmodel.reverseMapper);
                    model.father = viewmodel.father.toModel();
                    model.husbands = viewmodel.husbands.map(NameAndValueViewmodel.reverseMapper);
                    model.indexBetweenSiblings = viewmodel.indexBetweenSiblings;
                    model.mother = viewmodel.mother.toModel();
                    model.siblings = viewmodel.siblings.map(NameAndValueViewmodel.reverseMapper);
                    model.wives = viewmodel.wives.map(NameAndValueViewmodel.reverseMapper);

                    return model;
                }
            }
        }
    }
}