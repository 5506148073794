module Tanahpedia {
    export module Entries {
        export class EntryExtendedViewmodel {
            static count = 0;

            entryViewmodel: EntryViewmodel;
            html: ko.Observable<string>;
            $tempElem: JQuery;
            dataSource: EntryDataSource;

            entryExtendedLoadedEventHandler: EventHandler;
            constructor(entryViewmodel: EntryViewmodel) {
                this.init(entryViewmodel);
            }
            init(entryViewmodel: EntryViewmodel) {
                this.entryViewmodel = entryViewmodel;
                this.html = ko.observable("!!!");

                this.entryExtendedLoadedEventHandler = new EventHandler();

                this.dataSource = new EntryDataSource();
                this.dataSource.entryExtendedLoadedEventHandler.register(this.entryExtendedLoaded, this);

            }

            load() {
                this.dataSource.loadEntryExtended(this.entryViewmodel.name(), this.entryViewmodel.type());
            }

            generateFootNotes(): void {
                var c = EntryExtendedViewmodel.count++;
                this.$tempElem.filter('a[id^=r], a[id^=fn]').each((index: number, elem: Element) => {
                    elem.id += '_' + c;
                });
                //var $ftns = this.$tempElem.find('a[name^=_ftn]:not([name ^= _ftnref])');
                //var $ftnrefs = this.$tempElem.find('a[name^=_ftnref]');
                //this.$tempElem.find('h2').each((index: number, elem: Element) => {
                //    var $h2 = $(elem);
                //    if ($h2.text() == "הערות") {
                //        var $footnotesContainer = $h2.next('div');
                //        $footnotesContainer.addClass('footnotes-container');
                //    }
                //});
                //$ftns.each((index: number, ftn: Element) => {
                //    var $ftn = $(ftn);
                //    index++;
                //    $ftn.removeAttr('name').removeAttr('href').attr({ id: 'fn' + index + '_' + c, 'data-dest': '#r' + index + '_' + c });
                //    $ftn.html($ftn.text());
                //});
                //$ftnrefs.each((index: number, ftnref: Element) => {
                //    var $ftnref = $(ftnref);
                //    index++;
                //    $ftnref.replaceWith($('<sup>').append($('<a>', { id: 'r' + index + '_' + c, 'data-dest': '#fn' + index + '_' + c }).html("[" + index + "]")));
                //});

                this.html(this.$tempElem.html());
            }

            setHtml(html: string) {
                this.html(html);
                this.$tempElem = $('<article>').html(html);
                if (html != '') {
                    this.generateFootNotes();
                }
            }

            entryExtendedLoaded(sender: any, e: { data: string }) {
                var html = e.data;
                this.setHtml(html);
                this.entryExtendedLoadedEventHandler.fire(new EventArgs(sender, e));
            }
        }
    }
}