/// <reference path="../view/root-view.ts" />
module App_V3 {
    export module AppPreferences {
        export class PreferencesView extends RootView {
            public preferencesViewmodel: PreferencesViewmodel;

            public alHaperekPreferencesView: AlHaperekPreferencesView;
            public generalPreferencesView: GeneralPreferencesView;

            public get menuButtonAction(): () => void {
                return () => { };
            }
            public get name(): string {
                return "preferences";
            }

            constructor() {
                super();
                this.init();
            }

            init() {
                this.underlyingParameters = <Framework7.View.Parameters>{
                    name: this.name,
                    routes: [],
                    url: '/app_preferences/'
                };
                this.preferencesViewmodel = ResourcesPool.ViewmodelPool.preferencesViewmodel;
                this.alHaperekPreferencesView = new AlHaperekPreferencesView(this.preferencesViewmodel);
                this.generalPreferencesView = new GeneralPreferencesView(this.preferencesViewmodel);
            }

            public onPageAfterIn(pageData: Framework7.Router.Page) {
                if (!this.isFirstTimePageIn) {
                    this.setAnalyticsScreen();
                    this.bind();
                }
            }
        }
    }
}
