module Tanahpedia {
    export module Entries {
        export class EntryPageView {
            pageIdx: number;

            entryViewmodel: EntryViewmodel;
            entryExtendedViewmodel: EntryExtendedViewmodel;

            private $enlargeBtn: JQuery;
            private $entryPageElem: JQuery;
            private $entryExtended: JQuery;

            //public entryLoadedEventHandler: EventHandler;


            constructor(entryViewmodel : EntryViewmodel, $entryPageElem: JQuery) {
                this.init(entryViewmodel, $entryPageElem);
            }
            init(entryViewmodel: EntryViewmodel, $entryPageElem: JQuery) {
                this.entryViewmodel = entryViewmodel;
                this.$entryPageElem = $entryPageElem;

                this.pageIdx = Number(this.$entryPageElem.attr('data-page-idx'));

                this.entryExtendedViewmodel = new EntryExtendedViewmodel(this.entryViewmodel);
                this.$enlargeBtn = this.$entryPageElem.find('.enlarge-btn');
                this.$entryExtended = this.$entryPageElem.find('.entry-extended');
                this.entryViewmodel.entryLoadedEventHandler.register(this.entryLoaded, this);
                this.entryExtendedViewmodel.entryExtendedLoadedEventHandler.register(this.entryExtendedLoaded, this);

                ko.applyBindings(this, this.$entryPageElem[0]);

                //this.entryLoadedEventHandler = new EventHandler();
            }

            attachAnchorsEvents() {
                console.log('retaching' + this.pageIdx);
                var $allAnchorsWithHref: JQuery = this.$entryExtended.find('a[href]:not([href=""])');
                var $footNotesAndFootRefs = this.$entryExtended.find('a[data-dest^="#r"], a[data-dest^="#fn"]');
                //var $internalLinks = $allAnchorsWithHref.filter('a[href^="./"]');
                var $internalLinks = $allAnchorsWithHref.filter('a:not([href*="/"])');
                console.log($internalLinks.length);
                $footNotesAndFootRefs.bindToIntuitiveClick($.proxy(function (e: JQueryMouseEventObject) {
                    e.preventDefault();
                    e.stopPropagation();
                    var $a = $(e.target);
                    Dsoft.View.scrollWithJqueryAnim(this.$entryExtended, $($a.data('dest')));
                    return false;
                }, this));
                $internalLinks.unbind('click');
                console.log($internalLinks);

                $internalLinks.bindToIntuitiveClick($.proxy(function (e: JQueryMouseEventObject) {
                    var $a = $(e.target);
                    var href = $a.attr('href');
                    //var possibleEntryName = href.substr(href.indexOf('./') + 2);
                    var possibleEntryName = href;
                    if (ResourcesPool_V2.ViewPool.indexView.entriesMetadataViewmodel.isMetaDataExist(possibleEntryName)) {
                        ResourcesPool_V2.ViewPool.indexView.load(possibleEntryName);
                    }
                }, this));
            }

            load() {
                this.entryExtendedViewmodel.load();
            }

            reattachNativeEvents() {
                this.$enlargeBtn = this.$entryPageElem.find('.enlarge-btn');
                this.$entryExtended = this.$entryPageElem.find('.entry-extended');
                this.$enlargeBtn.unbind('click');
                
                this.$enlargeBtn.bind('click', $.proxy(function () {
                    ResourcesPool_V2.ViewPool.indexView.syncView.show();
                    ResourcesPool_V2.ViewPool.indexView.load(this.entryViewmodel.name(), this.entryViewmodel.type());
                }, this));

                this.attachAnchorsEvents();
            }
            entryLoaded(sender: any, e: { data: Entry }) {
                var entry = e.data;

                //this.entryLoadedEventHandler.fire(new EventArgs(this, { data: entry }));
            }
            entryExtendedLoaded(sender: any, e: { data: string }) {
                var html = e.data;
                //if (html !== '') {
                this.attachAnchorsEvents();
            }
        }

    }
}