class NameAndValueViewmodel {

    public name: string;
    public value: string;
    constructor(name?: string, value?: string) {
        this.name = typeof name !== 'undefined' ? name : '';
        this.value = typeof value !== 'undefined' ? value : '';
    }
    public toModel(): Shared.NameAndValue{
        return NameAndValueViewmodel.viewmodelToModel(this);
    }
    public static reverseMapper(value: NameAndValueViewmodel, index: number, array: NameAndValueViewmodel[]): Shared.NameAndValue{ return NameAndValueViewmodel.viewmodelToModel(value); }
    public static viewmodelToModel(viewmodel: NameAndValueViewmodel) {
        var model = new Shared.NameAndValue();
        model.name = viewmodel.name;
        model.value = viewmodel.value;
        return model;
    }
    public static viewmodelsToModels(models: NameAndValueViewmodel[]): Shared.NameAndValue[] {
        if (typeof models !== 'undefined')
            return models.map(NameAndValueViewmodel.reverseMapper);
        else
            return [];
    }
    public static mapper(value: Shared.NameAndValue, index: number, array: Shared.NameAndValue[]): NameAndValueViewmodel { return NameAndValueViewmodel.modelToViewmodel(value); }
    public static modelToViewmodel(model: Shared.NameAndValue): NameAndValueViewmodel {
        var vm: NameAndValueViewmodel;
        if (typeof model !== 'undefined')
            vm = new NameAndValueViewmodel(model.name, model.value);
        else
            vm = new NameAndValueViewmodel();
        return vm;
    }
    public static modelsToViewmodels(models: Shared.NameAndValue[]) : NameAndValueViewmodel[] {
        var vms: NameAndValueViewmodel[];
        if (typeof models !== 'undefined')
            vms = models.map(NameAndValueViewmodel.mapper);
        else
            vms = [];
        return vms;
    }



    public static comperator(a: Shared.NameAndValue, b: Shared.NameAndValue) {
        return Dsoft.HebrewHelper.hebrewComperator(a.value, b.value);
    }

}