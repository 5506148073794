module Tanahpedia {
    export class EntriesDataSource {
        entriesLoadedEventHandler: EventHandler;
        entryInsertedEventHandler: EventHandler;
        entryRemovedEventHandler: EventHandler;
        constructor() {
            this.entriesLoadedEventHandler = new EventHandler();
            this.entryInsertedEventHandler = new EventHandler();
            this.entryRemovedEventHandler = new EventHandler();
        }

        loadEntriesAsync(type: string) {
            var doneCallback = $.proxy(function (data) {
                if (data.success) {
                    this.entriesLoadedEventHandler.fire(new EventArgs(this, { data: data.message }));
                }
            }, this);
            var checkFail = $.proxy(
                function (jqXHR, textStatus, errorThrown) {
                    try {
                        var result = JSON.parse(jqXHR.responseText.substring(jqXHR.responseText.indexOf('{')));
                        this.doneCallback(result);
                    } catch (ex) { }
                }
                , { context: this, doneCallback: doneCallback });
            $.ajax({
                type: 'GET', url: Conn.remotePath + 'get_entries.php', data: { 'type': type }, dataType: 'json'
            }).done(doneCallback).fail(checkFail);
        }
    }

}