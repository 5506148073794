var moshe;
module AlHaperek {
    export class PerushDataSource {
        loadPerush(perek: Perek, perush: Perush): Promise<string> {
            return new Promise((resolve: (value?: string | PromiseLike<string>) => void, reject: (reason) => void) => {
                if (perek.perekId < 1 || perek.perekId > 929) {
                    reject(new Error("Perek is out of range"));
                    return;
                }
                if (typeof AlHaperek.PerushimData.sefarimPerakimPerushimContent[perek.seferTanachUsName] === 'undefined') {
                    AlHaperek.PerushimData.sefarimPerakimPerushimContent[perek.seferTanachUsName] = [];
                }
                if (typeof AlHaperek.PerushimData.sefarimPerakimPerushimContent[perek.seferTanachUsName][perek.perek] === 'undefined') {
                    AlHaperek.PerushimData.sefarimPerakimPerushimContent[perek.seferTanachUsName][perek.perek] = [];
                }
                // avoid repeating perek perush loading for performance
                if (typeof AlHaperek.PerushimData.sefarimPerakimPerushimContent[perek.seferTanachUsName][perek.perek][perush.id] !== 'undefined') {
                    //// make it "async"
                    //setTimeout($.proxy((perek) => {
                    //    this.perushLoadedEventHandler.fire(new EventArgs(this, { data: PerushimData.sefarimPerakimPerushimContent[perek.seferTanachUsName][perek.perek][perush.id] }))
                    //}, this, perek), 0);
                    resolve(PerushimData.sefarimPerakimPerushimContent[perek.seferTanachUsName][perek.perek][perush.id]);
                    return;
                }
                var settings = { async: true, type: 'GET', url: ResourcesPool_V2.ModelPool.pathToData + "/perushim/v201/" + perek.seferTanachUsName + "/" + perek.perek + "/" + perush.name + ".html", dataType: 'html' };

                resolve(Dsoft.Ajax.minimalAjaxCall<string>(settings, (response: string): string => {
                    PerushimData.sefarimPerakimPerushimContent[perek.seferTanachUsName][perek.perek][perush.id] = response;
                    return response;
                }));
            });
        }
    }
}