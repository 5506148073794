/// <reference path="../../view/base-view.ts" />
module App_V3 {
    export module AppAlHaperek {
        export class AppRabbiView extends BaseView { 
            public rabbiVM: ko.Observable<AlHaperek.RabbiViewmodel>;
            public get name(): string {
                return "rabbi";
            }
            public get analyticsRootScreenName(): string {
                return '/929/rabbi';
            }

            constructor() {
                super();
                this.rabbiVM = ko.observable();
            }
            onPageAfterIn(pageData: Framework7.Router.Page) {
                if (pageData.direction !== 'backward') {
                    ko.applyBindings(ResourcesPool.ViewPool.rabbiView, $('#rabbiPage')[0]);
                }
                this.setAnalyticsScreen(this.rabbiVM().name());
            }
            selectArticle(articleVM: AlHaperek.ArticleViewmodel) {
                articleVM.author(this.rabbiVM());
                articleVM.loadContent();
                ResourcesPool.ViewPool.articleView.selectArticle(articleVM);
            }
            selectRabbi(rabbiVM: AlHaperek.RabbiViewmodel, andLoadArticles: boolean = false) {
                this.rabbiVM(rabbiVM);
                if (andLoadArticles) {
                    rabbiVM.loadArticles();
                }
            }
            onPageBeforeRemove(pageData: Framework7.Router.Page) {
                this.unbind();
                //ko.cleanNode($('#rabbiPage')[0]);
            }
        }
    }
}