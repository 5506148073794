module App_V3 {
    export module Application {
        export module Underlying {
            export const routes = <Framework7.Router.RouteParameters[]>[
                {
                    name: 'alHaperek',
                    path: '/app_929/',
                    url: './929/perek-view.html'
                },
                {
                    path: '/929/:perekId/'
                },
                {
                    name: 'article',
                    path: '/app_929/article/:articleId',
                    async: (routeTo, routeFrom, resolve, reject) => {
                        var router = ResourcesPool.ViewPool.currentRouter;
                        if (router.history.every((route) => route.indexOf('article') === -1)) {
                            resolve({ url: './929/perek-explain/articles/article-view.html' });
                        } else {
                            reject();
                            router.back();
                        }
                    }

                },
                {
                    name: 'perush',
                    path: '/app_929/perush/:perekId/:perushId',
                    url: './929/perek-explain/perushim/perush-view.html'
                },
                {
                    name: 'parshanim',
                    path: '/app_929/parshanim/',
                    url: './929/parshanim/parshanim-view.html'
                },
                {
                    name: 'rabbis',
                    path: '/app_929/rabbis/',
                    url: './929/rabbis/rabbis-view.html'
                },
                {
                    name: 'rabbi',
                    path: '/app_929/rabbi/:rabbiId',
                    async: (routeTo, routeFrom, resolve, reject) => {
                        var router = ResourcesPool.ViewPool.currentRouter;
                        if (router.history.every((route) => route.indexOf('rabbis') !== -1 || route.indexOf('rabbi') === -1)) {
                            resolve({ url: './929/rabbis/rabbi-view.html' });
                        } else {
                            reject();
                            router.back();
                        }
                    }
                },
                {
                    name: 'articleLink',
                    path: '(.*)/929/:perekId/:articleId',
                    async: (routeTo, routeFrom, resolve, reject) => {
                        // similar to article paging
                        var perekId = parseInt(routeTo.params['perekId'].toString());
                        var articleId = parseInt(routeTo.params['articleId'].toString());
                        reject();
                        var articleVM = new AlHaperek.ArticleViewmodel();
                        articleVM.load(articleId);
                        articleVM.perekId(perekId);
                        articleVM.loadContent();
                        ResourcesPool.ViewPool.articleView.selectArticle(articleVM);
                        var router = ResourcesPool.ViewPool.currentRouter;
                        // paged from another article
                        if (routeFrom.name == 'article' || routeFrom.name == 'articleLink') {
                            app.onPageAfterIn(<Framework7.Router.Page>{ name: 'article', pageFrom: { name: 'article' } });
                            // paged from any other view (still cannot happen)
                        } else {
                            router.navigate('/app_929/article/');
                        }
                    }
                },
                {
                    path: '/app_contact-us/',
                    url: './contact-us/contact-us-view.html'
                },
                {
                    path: '/app_preferences/',
                    url: './preferences/preferences-view.html'
                },
                {
                    path: '/app_system_messages/',
                    url: './system-messages/system-messages-view.html'
                },
                {
                    path: '/app_tos/',
                    url: './tos/tos-view.html'
                },
                {
                    path: '(.*)',
                    url: './pages/404.html',
                },
            ];
        }
    }
}