module WebSite {
    export class Contact {
        sending: boolean = false;;

        $contactFormContainer: JQuery;
        $email: JQuery;
        $message: JQuery;
        $name: JQuery;
        $phone: JQuery;
        $submit: JQuery;
        $sending: JQuery;
        $sendingFailed: JQuery;
        $sendingSuccefull: JQuery;
        mailSentEventHandler: EventHandler;
        mailWasNotSentEventHandler: EventHandler;

        constructor() {
            this.init();
        }

        init() {
            this.$contactFormContainer = $('.contact-form');
            this.$email = this.$contactFormContainer.find('input[name=email]');
            this.$message = this.$contactFormContainer.find('textarea[name=message]');
            this.$name = this.$contactFormContainer.find('input[name=name]');
            this.$phone = this.$contactFormContainer.find('input[name=phone]');
            this.$submit = this.$contactFormContainer.find('input[name=submit]');
            this.$sending = this.$contactFormContainer.find('.sending').first();
            this.$sendingFailed = this.$contactFormContainer.find('.sending.failed');
            this.$sendingSuccefull = this.$contactFormContainer.find('.sending.succefull');
            this.$submit.bind('click', $.proxy(function (e : JQueryEventObject) {
                e.stopPropagation();
                if (!this.sending) {
                    this.sending = true;
                    this.$submit.hide();
                    this.$sending.show();
                    this.sendMail(this.$email.val(), this.$message.val(), this.$name.val(), this.$phone.val());
                }
                return false;
            }, this));
            this.mailSentEventHandler = new EventHandler();
            this.mailSentEventHandler.register(this.mailSent, this);
            this.mailWasNotSentEventHandler = new EventHandler();
            this.mailWasNotSentEventHandler.register(this.mailWasNotSent, this);
        }


        sendMail(email: string, message: string, name: string, phone: string): void {
            var doneCallback = $.proxy(function (data) {
                if (data.success) {
                    try {
                        if (data.message) {
                            this.mailSentEventHandler.fire(new EventArgs(this, { data: data.message }));
                        } else {
                            this.mailWasNotSentEventHandler.fire(new EventArgs(this, { data: data.message }));
                        }
                    } catch (ex) { }
                }
            }, this);
            var checkFail = $.proxy(
                function (jqXHR, textStatus, errorThrown) {
                    try {
                        var result = JSON.parse(jqXHR.responseText.substring(jqXHR.responseText.indexOf('{')));
                        this.doneCallback(result);
                    } catch (ex) { }
                }
                , { context: this, doneCallback: doneCallback });
            $.ajax({
                type: 'POST', url: 'https://xn--febl3a.co.il/server/' + 'email.php', data: { 'email': email, 'message': message, 'name': name, 'phone': phone }, dataType: 'json'
            }).done(doneCallback).fail(checkFail);
        }

        mailSent() {
            this.sending = false;
            this.$sending.hide();
            this.$sendingSuccefull.show();

        }
        mailWasNotSent() {
            this.sending = false;
            this.$sending.hide();
            this.$sendingFailed.show();
        }
    }
}