module Dsoft {
    export module Mobility {
        export enum Orientation {
            PORTRAIT, LANDSCAPE, UNKNOWN
        }
        export function getScreenOrientation(): Orientation {
            var orientation;
            var orientation = screen['orientation'] || screen['mozOrientation'] || screen['msOrientation'];

            if (typeof orientation !== 'undefined' && String(orientation).toLowerCase().indexOf('landscape') !== -1) {
                return Orientation.LANDSCAPE;
            } else if (typeof orientation !== 'undefined' && String(orientation).toLowerCase().indexOf('portrait') !== -1) {
                return Orientation.PORTRAIT;
            } else if (typeof orientation !== 'undefined' && typeof orientation['type'] !== 'undefined' && String(orientation['type']).toLowerCase().indexOf('landscape') !== -1) {
                return Orientation.LANDSCAPE;
            } else if (typeof orientation !== 'undefined' && typeof orientation['type'] !== 'undefined' && String(orientation['type']).toLowerCase().indexOf('portrait') !== -1) {
                return Orientation.PORTRAIT;
            } else if (Math.abs(Number(window.orientation)) === 90) { // window.orientation - not mistake
                return Orientation.PORTRAIT;
            } else if (screen.width < screen.height) {
                return Orientation.PORTRAIT
            } else if (screen.width > screen.height) {
                return Orientation.LANDSCAPE;
            } else {
                return Orientation.UNKNOWN;
            }
        }
        export interface PinchHandlerOptions {

        }
        export interface PinchHandlerEventArgs extends EventArgs {
            e: { data: { scale: number } };
        }
        export class PinchHandler {
            baseScaleFactor: number = 1;
            currentScaleFactor: number = 1;
            $container: JQuery;
            constructor($container: JQuery, options: PinchHandlerOptions = {}) {
                this.init($container);
            }
            init($container: JQuery) {
                this.$container = $container;
                $container.find('*').each(function (index: number, elem: Element) {
                    var $el = $(elem);
                    $el.data('originalFontSize', parseFloat($el.css('fontSize')));
                });
            }
            refresh() {
                this.$container.find('*').each(function (index: number, elem: Element) {
                    var $el = $(elem);
                    $el.data('originalFontSize', parseFloat($el.css('fontSize')));
                });
            }
            onPinch(sender: any, e: { data: { scale: number } }) {
                var scale: number = e.data.scale;
                var factor = 1;
                if (Math.abs(scale - 1) < 0.05) {
                    this.baseScaleFactor = this.currentScaleFactor; // maybe opposite?
                } else {
                    this.currentScaleFactor = scale * this.baseScaleFactor;
                }
                this.$container.find('*').each($.proxy(function (index: number, elem: Element) {
                    var $elem: JQuery = $(elem);
                    $elem.css('fontSize', $elem.data('originalFontSize') * Number(String(this.currentScaleFactor).substr(0, 3)));
                }, this));
            }
        }
        //export function getScreenDimensionsOrientationIndependent(): ClientRect {
        //    var result: ClientRect = <ClientRect>new Object();
        //    var orientaion: Orientation = getScreenOrientation();
        //    switch (orientaion) {
        //        case Orientation.PORTRAIT:
        //            result.width = screen.height;
        //            result.height = screen.width;
        //            break;
        //        case Orientation.LANDSCAPE:
        //        case Orientation.UNKNOWN:
        //        default:
        //            result.width = screen.width;
        //            result.height = screen.height;
        //            break;
        //    }
        //    return result;
        //}
    }
}