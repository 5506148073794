module Tanahpedia {
    export module Entries {
        export module Place {
            export class Cord {
                xCord: number;
                yCord: number;
                constructor(xCord: number = 0, yCord: number = 0) { this.xCord = xCord; this.yCord = yCord; }
            }
            export class Polygon {
                cords: Cord[];
                constructor(cords: Cord[] = []) {
                    this.cords = cords;
                }
            }
            export class Recognition {
                polygon: Polygon;
                constructor(polygon: Polygon = new Polygon()) { this.polygon = polygon; }
            }
            export class Entry extends Entries.Entry {
                public recognitions: Recognition[];

                constructor(recognitions: Recognition[] = []) {
                    super();
                    this.recognitions = recognitions;
                }
            }
        }
    }
}