/// <reference path="../view/root-view.ts" />
module App_V3 {
    export class ContactUsView extends RootView {
        public get analyticsRootScreenName(): string {
            return '/contact';
        }
        public get menuButtonAction(): () => void {
            return () => { };
        }
        public get name(): string {
            return "contactUs";
        }

        constructor() {
            super();
            this.init();
        }

        init() {
            this.underlyingParameters = <Framework7.View.Parameters>{
                name: 'contactUs',
                routes: [],
                url: '/app_contact-us/'
            };
        }

        public onPageAfterIn(pageData: Framework7.Router.Page) {
            if (!this.isFirstTimePageIn) {
                this.setAnalyticsScreen();
            }
        }
    }
}
