module Tanahpedia {
    export module Entries {
        export module Animal {
//            export class Entry extends Entries.Entry {

//                constructor() {
//                    super();
//                    console.log("dudu");
//                }
//            }
        }
    }
}