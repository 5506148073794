module Tanahpedia {
    export module Entries {
        export module Person {
            export class PersonTimelineView {
                public static typeToIcon = { 'birth': 'hospital-building', 'death': 'hotel', 'normal': 'bookmark', 'undefined': 'bookmark' };

                public personEntryViewmodel: Person.EntryViewmodel;

                public lifePoints: ko.ObservableArray<LifePointViewmodel>;

                constructor(personVM?: Person.EntryViewmodel) {
                    this.init(personVM);
                }
                init(personVM?: Person.EntryViewmodel) {
                    if (typeof personVM !== 'undefined') {
                        this.personEntryViewmodel = personVM;
                        personVM.entryLoadedEventHandler.register(function () { this.lifePoints(this.personEntryViewmodel.life.lifePoints); }, this);
                    } else {
                        this.personEntryViewmodel = new Person.EntryViewmodel();
                    }
                    this.lifePoints = ko.observableArray(this.personEntryViewmodel.life.lifePoints);
                    //ko.cleanNode($timelineView[0]);
                    //ko.applyBindings(this, $timelineView[0]); // deleted this.lifePoints
                    //var timelineAnimate;
                    //timelineAnimate = function (elem) {
                    //    return $(".timeline.animated .timeline-row").each(function (i) {
                    //        var bottom_of_object, bottom_of_window;
                    //        bottom_of_object = $(this).position().top + $(this).outerHeight();
                    //        bottom_of_window = $(window).scrollTop() + $(window).height();
                    //        if (bottom_of_window > bottom_of_object) {
                    //            return $(this).addClass("active");
                    //        }
                    //    });
                    //};
                    //timelineAnimate();
                    //return $(window).scroll(function () {
                    //    return timelineAnimate();
                    //});
                }
            }
        }
    }
}