module Tanahpedia {
    export module Entries {
        export module Person {
            export class SyncPersonView {

                personVM: Person.EntryViewmodel

                public timelineView: PersonTimelineView;
                public familyTreeView: FamilyTreeView;

                constructor(personVM: Person.EntryViewmodel) {
                    this.init(personVM);
                }

                init(personVM: Person.EntryViewmodel) {
                    this.personVM = personVM;
                    this.timelineView = new PersonTimelineView(this.personVM);
                    this.familyTreeView = new FamilyTreeView(this.personVM);
                }
            }
        }
    }
}