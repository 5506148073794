module Tanahpedia {
    export module Entries {
        export class ImagesMetadataDataSource {
            imagesMetadataLoadedEventHandler: EventHandler;
            constructor() {
                this.imagesMetadataLoadedEventHandler = new EventHandler();
            }

            loadImagesMetadataAsync(entryName : string) {
                var doneCallback = $.proxy(function (data) {
                    if (data.success) {
                        this.imagesMetadataLoadedEventHandler.fire(new EventArgs(this, { data: data.message }));
                    }
                }, this);
                var checkFail = $.proxy(
                    function (jqXHR, textStatus, errorThrown) {
                        try {
                            var result = JSON.parse(jqXHR.responseText.substring(jqXHR.responseText.indexOf('{')));
                            this.doneCallback(result);
                        } catch (ex) { }
                    }
                    , { context: this, doneCallback: doneCallback });
                $.ajax({
                    type: 'GET', url: Conn.remoteEntriesPath + 'get_entry_images_meta_data.php', data: { name: entryName }, dataType: 'json'
                }).done(doneCallback).fail(checkFail);
            }
        }
    }
}