//module Tanahpedia {
//    export module Entries {
//        export module ResourcesPool {
//            export class CrawlerPart {
//                //public articlesCount = 0;
//                //public abstractsLoadedCount = 0;
//                //public contentLoaded = false;
//                //public textLoaded = false;
//                //public isFinished(): boolean {
//                //    if (Viewmodel929Pool.articleId !== undefined)
//                //        return (this.abstractsLoadedCount >= this.articlesCount && this.textLoaded && this.contentLoaded);
//                //    else
//                //        return (this.abstractsLoadedCount >= this.articlesCount && this.textLoaded);

//                //}
//            }
//            export class ModelPool {
//                static entryExtended;
//                static entryName;
//                static personEntry;
//                static placeEntry;
//                static ctor() {
//                    ModelPool.entryExtended = "";
//                    ModelPool.entryName = "";
//                    ModelPool.personEntry = new Person.Entry();
//                    ModelPool.placeEntry = new Place.Entry();
//                }
//            }
//            export class ViewmodelPool {
//                public static recogitionsAndGoogleMapPolygonsMap: Dsoft.DS.Map<Entries.Place.RecognitionViewmodel, google.maps.Polygon>;
//                static crawlerPartsFinished = new Array<CrawlerPart>();
//                static crawling = true;
//                static entryExtendedViewmodel: EntryExtendedViewmodel;
//                static personEntryViewmodel: Person.EntryViewmodel;
//                static placeEntryViewmodel: Place.EntryViewmodel;

//            }

//            export var analyticsViewmodel: MobileAppAnalyticsViewmodel;
//            export var constructed: boolean;
//            export function ctor(): void {
//                ModelPool.ctor();

//                analyticsViewmodel = new MobileAppAnalyticsViewmodel();
//                ViewmodelPool.recogitionsAndGoogleMapPolygonsMap = new Dsoft.DS.Map<Entries.Place.RecognitionViewmodel, google.maps.Polygon>();

//                ViewmodelPool.personEntryViewmodel = new Person.EntryViewmodel();
//                ViewmodelPool.placeEntryViewmodel = new Place.EntryViewmodel();
//                ViewmodelPool.entryExtendedViewmodel = new EntryExtendedViewmodel();
//                constructed = true;
//            }
//        }
//    }
//}