module AlHaperek {
    export class ArticleViewmodel {
        public id: ko.Observable<number>;
        public abstract: ko.Observable<string>;
        public author: ko.Observable<RabbiViewmodel>;
        public content: ko.Observable<string>;
        public name: ko.Observable<string>;
        public perekId: ko.Observable<number>;
        public priority: ko.Observable<number>;

        public additionalHeb: ko.Computed<string>;
        public bookHeb: ko.Computed<string>;
        public href: ko.Computed<string>;
        public perekHeb: ko.Computed<string>;
        public perekSource: ko.Computed<string>;
        public shortAbstract: ko.Computed<string>;

        public dataSource: ArticleDataSource;
        constructor(article?: Article, perekId?: number) {
            this.init(article, perekId);

        }
        init(article?: Article, perekId?: number) {

            if (typeof article !== 'undefined') {
                this.id = ko.observable(article.id);
                this.abstract = ko.observable(article.abstract);
                this.author = ko.observable(new RabbiViewmodel(article.authorId));
                this.name = ko.observable(article.name);
                this.perekId = ko.observable(typeof (perekId) !== 'undefined' ? perekId : article.perekId);
                this.priority = ko.observable(article.priority);
            } else {
                this.id = ko.observable(0);
                this.abstract = ko.observable("");
                this.author = ko.observable(new RabbiViewmodel());
                this.name = ko.observable("");
                this.perekId = ko.observable(-1);
                this.priority = ko.observable(0);
            }
            this.content = ko.observable("");
            this.additionalHeb = ko.pureComputed(() => {
                return Dsoft.GimatryHelper.toLetters(PerakimData.perakim.additional[this.perekId() - 1]);
            }, this);
            this.bookHeb = ko.pureComputed(() => {
                var sefer = SefarimData.sefarim[PerakimData.perakim.seferId[this.perekId() - 1]];
                return typeof sefer !== 'undefined' ? sefer.name : '';
            }, this);
            this.perekSource = ko.pureComputed({
                owner: this,
                read: () => { return this.bookHeb() + ' ' + (this.additionalHeb() != null && this.additionalHeb() != '' ? this.additionalHeb() + ' ' : '') + this.perekHeb(); }
            });
            this.href = ko.pureComputed(() => {
                return Conn.localPath + this.perekId() + "/" + this.id();
            });
            this.shortAbstract = ko.pureComputed(() => {
                var $tmpDiv: JQuery = $('<div>').html(this.abstract());
                $tmpDiv.remove('h2');
                return $tmpDiv.find('h1').html();
            }, this);
            this.perekHeb = ko.pureComputed(() => {
                return Dsoft.GimatryHelper.toLetters(PerakimData.perakim.perek[this.perekId() - 1]);
            }, this);

            this.dataSource = new ArticleDataSource();
        }
        fillContent(content: string) {
            if (this.content() != content) {
                this.content(content);
            }
        }
        load(id?: number) {
            this.id(id);
            this.dataSource.loadArticle(id).then(this.articleLoaded.bind(this));
        }
        loadContent() {
            this.dataSource.loadArticleContent(this.id(), this.perekId()).then(this.contentLoaded.bind(this));
        }
        articleLoaded(article: AlHaperek.Article) {
            this.abstract(article.abstract);
            this.author(new RabbiViewmodel(article.authorId));
            this.name(article.name);
            this.perekId(article.perekId);
            this.priority(article.priority);
        }
        contentLoaded(articleContent: string) {
            this.fillContent(articleContent);
        }

        public static mapper(value: Article, index: number, array: Article[]): ArticleViewmodel { return ArticleViewmodel.modelToViewmodel(value); }
        public static modelToViewmodel(model: Article): ArticleViewmodel {
            var vm: ArticleViewmodel;
            if (typeof model !== 'undefined')
                vm = new ArticleViewmodel(model);
            else
                vm = new ArticleViewmodel();
            return vm;
        }
        public static modelsToViewmodels(models: Article[]): ArticleViewmodel[] {
            var vms: ArticleViewmodel[];
            if (typeof models !== 'undefined')
                vms = models.map(ArticleViewmodel.mapper);
            else
                vms = [];
            return vms;
        }
    }
}