module App_V3 {
    export class Analytics {
        constructor() {
            if (Conn.isLocalDev()) return;

            window['ga'] = window['ga'] || function () { (ga.q = ga.q || []).push(arguments) }; ga.l = +new Date;

            //window['ga_debug'] = { trace: true };

            var fields = {
                trackingId: 'UA-58443405-2',
                // we store and provide the clientId ourselves in localstorage since there are no
                // cookies in Cordova
                clientId: localStorage.getItem('ga:clientId'),
                storage: 'none'
            };
            ga('create', fields);

            // prevent tasks that would abort tracking
            ga('set', {
                // don't abort if the protocol is not http(s)
                checkProtocolTask: null,
                // don't expect cookies to be enabled
                checkStorageTask: null
            });

            // a callback function to get the clientId and store it ourselves
            ga(function (tracker) {
                localStorage.setItem('ga:clientId', tracker.get('clientId'));
            });
        }
        setAnalyticsScreen(screenName: string) {
            if (Conn.isLocalDev()) {
                console.log('screen: ' + screenName);
                return;
            }
            ga('send', {
                hitType: 'screenview',
                screenName: screenName,
                appName: '929 - תנך.co.il'
            });
        }
    }
}