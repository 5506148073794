module Tanahpedia {
    export module Entries {
        export module Person {
            export class BookPersonView {
                pageIdx: number;
                personVM: Person.EntryViewmodel

                public timelineView: PersonTimelineView;
                public familyTreeView: FamilyTreeView;


                constructor(pageIdx : number, personVM: Person.EntryViewmodel) {
                    this.init(pageIdx, personVM);
                }

                init(pageIdx: number, personVM: Person.EntryViewmodel) {
                    this.pageIdx = pageIdx;
                    this.personVM = personVM;
                    //this.$timelineView = $('.page[data-page-idx=' + pageIdx + ']' + ' .timeline-view');
                    this.timelineView = new PersonTimelineView(this.personVM);
                    this.familyTreeView = new FamilyTreeView(this.personVM);
                }
            }
        }
    }
}