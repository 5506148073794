/// <reference path="../../../shared/model/pojso/name-and-value.ts" />
module Tanahpedia {
    export module Entries {
        export class EntryMetaDataViewmodel extends Shared.NameAndValue {
            index: number;
            type: string;
            constructor(name?: string, value?: string, index?: number, type?: string) {
                super(name, value);
                this.index = index;
                this.type = type;
            }
        }
    }
}