/// <reference path="../../scripts/typings/knockout/knockout.d.ts" />
/// <reference path="sub-preferences-view.ts" />
module App_V3 {
    export module AppPreferences {
        export class GeneralPreferencesView extends SubPreferencesView {
            private fontSizeValObservable: ko.Observable<number>;
            public fontSizeRangeSelector: string;

            constructor(preferencesViewmodel: PreferencesViewmodel) {
                super(preferencesViewmodel);
                this.fontSizeRangeSelector = '#preferencesView #generalPreferencesView #fontSizeRange';
                this.fontSizeValObservable = this.preferencesViewmodel.getProperty<number>('fontSize').value;
                this.fontSizeValObservable.subscribe((newValue : number) => {
                    app.setRootFontSize(newValue);
                    app.underlying.range.setValue(this.fontSizeRangeSelector, newValue);
                });
            }

            renderBeforeBind() {
                App_V3.app.underlying.range.create(
                    {
                        el: '#preferencesView #generalPreferencesView #fontSizeRange',
                        max: 60,
                        min: 6,
                        on: {
                            change: (ev) => this.fontSizeValObservable(ev.value as number)
                        },
                        step: 1,
                        value: this.fontSizeValObservable()
                    }
                );
                app.underlying.range.setValue(this.fontSizeRangeSelector, this.fontSizeValObservable());
                
            }
        }
    }
}