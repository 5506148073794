module Dsoft {
    export module StringHelper {
        export function stripVowels(rawString) {
            var newString = '';
            for (var j = 0; j < rawString.length; j++) {
                if (rawString.charCodeAt(j) < 1425
                    || rawString.charCodeAt(j) > 1479)
                { newString = newString + rawString.charAt(j); }
            }
            return (newString);
        }
        export function hashCode(str: string) {
            var hash = 0;
            if (str.length == 0) {
                return hash;
            }
            for (var i = 0; i < str.length; i++) {
                var char = str.charCodeAt(i);
                hash = ((hash << 5) - hash) + char;
                hash = hash & hash; // Convert to 32bit integer
            }
            return hash;
        }
    }
}