module App_V3 {
    export module AppAlHaperek {
        export class PerushimView {
            public perekViewmodel: AlHaperek.PerekViewModel;
            public perushimViewmodel: AlHaperek.PerushimViewmodel;

            constructor(perekViewmodel: AlHaperek.PerekViewModel) {
                this.init(perekViewmodel);
            }
            init(perekViewmodel: AlHaperek.PerekViewModel) {
                this.perekViewmodel = perekViewmodel;
                this.perekViewmodel.loadedThens.push({ onfulfilled: this.processPerek.bind(this) });
                this.perushimViewmodel = new AlHaperek.PerushimViewmodel(perekViewmodel);
            }
            processPerek(perekViewModel: AlHaperek.PerekViewModel) {
                this.perushimViewmodel.load().then(this.processPerushim.bind(this));
            }
            processPerushim(perushViewmodels: ko.ObservableArray<AlHaperek.PerushViewmodel>) {
                perushViewmodels().forEach((perushVM: AlHaperek.PerushViewmodel, index: number, array: AlHaperek.PerushViewmodel[]) => {
                    perushVM.perushLoadedEventHandler.register(ResourcesPool.ViewPool.perushView.perushLoaded, ResourcesPool.ViewPool.perushView);
                });
            }

            selectPerush(perushVM: AlHaperek.PerushViewmodel): void {
                ResourcesPool.ViewPool.perushView.selectPerush(perushVM);
            }

        }
    }
}