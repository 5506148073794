module AlHaperek {
    export class NavbarView {
        constructor() {
            ko.applyBindings(this, $('.navbar')[0]);
        }
        tanahSefarimAction() {
            ResourcesPool_V2.ViewPool.indexView.syncView.hide();
            ResourcesPool_V2.ViewPool.indexView.bookView.pageToToc();
        }
    }
}