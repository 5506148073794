module GlobalSettings {
    export enum ConnType {
        homegroup, local, remote, remoteDev
    }
    export enum LogLevel {
        run,
        debug,
        debugWithStack
    }
    export const connType: ConnType = ConnType.remote;
    export const logLevel: LogLevel = LogLevel.run;
    export const cacheEnabled: boolean = true;

    export function log(str: string, _logLevel : LogLevel = logLevel){
        if (_logLevel == LogLevel.debug || _logLevel == LogLevel.debugWithStack) {
            console.log(str);
        }
        if (_logLevel == LogLevel.debugWithStack) {
            console.log((new Error()).stack);
        }
    }
}