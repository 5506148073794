class Perush {
    public id: number;
    public hebName: string;
    public name: string;
    public parshanName: string;
    public parshanName2: string;

    constructor(id: number, hebName: string, name: string, parshanName: string, parshanName2?: string) {
        this.id = id;
        this.hebName = hebName;
        this.name = name;
        this.parshanName = parshanName;
        this.parshanName2 = parshanName2;
    }
}