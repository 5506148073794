module Tanahpedia {
    export module Entries {
        export module Person {
            export const Gendre: { MALE: string, FEMALE: string } = { MALE: 'זכר' , FEMALE: 'נקבה' };
            export class LifePoint {
                public date: string;
                public html: string;
                public place: Shared.NameAndValue;
                public subPlace: Shared.NameAndValue;
                public title: string;
                public type: string;
                public year: number;
            }
            export class Life {
                public length: number;
                public lifePoints: LifePoint[];
                constructor(length: number = null, lifePoints: LifePoint[] = []) {
                    this.length = length;
                    this.lifePoints = lifePoints;
                }
            }
            export class Family {
                public children: Shared.NameAndValue[];
                public father: Shared.NameAndValue;
                public husbands: Shared.NameAndValue[];
                public indexBetweenSiblings: number;
                public mother: Shared.NameAndValue;
                public siblings: Shared.NameAndValue[];
                public wives: Shared.NameAndValue[];

                constructor(children = [], father = new Shared.NameAndValue, husbands = [], mother = new Shared.NameAndValue, siblings = [], wives = []) {
                    this.children = children;
                    this.father = father;
                    this.husbands = husbands;
                    this.mother = mother;
                    this.siblings = siblings;
                    this.wives = wives;
                }
            }
            export class Reincarnation {
                of: Shared.NameAndValue[];
                into: Shared.NameAndValue[];
                sparkOf: Shared.NameAndValue[];
                sparkedInto: Shared.NameAndValue[];

                constructor(of = [], into = [], sparkOf = [], sparkedInto = []) {
                    this.of = of;
                    this.into = into;
                    this.sparkOf = sparkOf;
                    this.sparkedInto = sparkedInto;
                }
            }
            export class Entry extends Tanahpedia.Entries.Entry {

                public family: Family;
                public gendre: string;
                public life: Life;
                public reincarnation: Reincarnation;


                constructor(family = new Family, gendre = "", life = new Life, reincarnation = new Reincarnation) {
                    super();
                    this.family = family;
                    this.gendre = gendre;
                    this.life = life;
                    this.reincarnation = reincarnation;
                }
            }
        }
    }
}