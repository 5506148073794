module AlHaperek {
    export class SystemMessage {
        public id: number;
        public abstract: string;
        public content: string;
        public priority: number;

        constructor(id:number, abstract: string, content: string, priority: number) {
            this.id = id;
            this.abstract = abstract;
            this.content = content;
            this.priority = priority;
        }
    }
}