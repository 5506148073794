module Tanahpedia {
    export module Entries {
        export class AddressLineState {
            entryName: string;
            callback: Function;
            cyclic: boolean;
            guid: number;
            entryType: string;
            text: string;
            static counter: number = 0;
            constructor(entryName?: string, entryType?: string, callback?: Function, cyclic: boolean = false) {
                this.callback = callback;
                this.cyclic = cyclic;
                this.entryName = entryName;
                this.entryType = entryType;
                this.guid = AddressLineState.counter++;
            }
            translateToRelativePath() {
                var relativePath: string = "";
                var SEPARATOR = "/";
                if (typeof this.entryName == 'undefined') {
                    relativePath = "";
                } else {
                    relativePath = this.entryName.toString();
                } 
                return relativePath;
            }
        }
        export class AddressLineManager {
            currentState: AddressLineState;
            originalWindowLocation: string;
            states: AddressLineState[] = [];
            wait: boolean = false;
            constructor(state: AddressLineState) {
                var SEPARATOR = "/";

                this.originalWindowLocation = window.location.href.substr(0, window.location.href.indexOf('pedia') + 5);

                this.states[state.guid] = state;
                window.history.replaceState(state.guid, 'Title', this.originalWindowLocation + SEPARATOR + state.translateToRelativePath());

                $(window).bind('popstate', $.proxy(this.onPopState, this));
            }
            changeState(state: AddressLineState) {
                if (state.cyclic && this.wait) {
                    this.wait = false;
                    return;
                }
                var SEPARATOR = "/";
                this.states[state.guid] = state;
                window.history.pushState(state.guid, 'Title', this.originalWindowLocation + SEPARATOR + state.translateToRelativePath());
            }

            onPopState(e: JQueryEventObject) {
                var guid = e.originalEvent['state'];
                var state: AddressLineState = this.states[guid];
                if (state.cyclic)
                    this.wait = true;
                state.callback();
            }
        }
    }
}