/// <reference path="../../view/root-view.ts" />
module App_V3 {
    export module AppAlHaperek {
        export class AppRabbisView extends RootView {
            public firstLoad: boolean;
            public rabbisViewModel: AlHaperek.Rabbis.RabbisViewmodel;
            picker: Framework7.Picker.Picker;

            public get analyticsRootScreenName(): string {
                return '/929/rabbis';
            }
            public get menuButtonAction(): () => void {
                return () => { };
            }
            public get name(): string {
                return "rabbis";
            }

            constructor() {
                super();
                this.init();
            }
            init() {
                this.rabbisViewModel = ResourcesPool.ViewmodelPool.rabbisViewModel;
                this.underlyingParameters = <Framework7.View.Parameters>{
                    name: this.name,
                    routes: [],
                    stackPages: true,
                    url: '/app_929/rabbis/'
                };
            }
            onPageAfterIn(pageData: Framework7.Router.Page) {
                if (!this.isFirstTimePageIn) {
                    this.setAnalyticsScreen();
                }
                this.bind();
            }
            selectRabbi(rabbiVM: AlHaperek.RabbiViewmodel) {
                ResourcesPool.ViewPool.rabbiView.selectRabbi(rabbiVM, true);
            }
        }
    }
}