module AlHaperek {
    //export interface SyncViewConf {
    //    articleId?: number,
    //    articles?: ArticleViewmodel[],
    //    perekId?: number
    //}
    export class SyncView {
        firstLoad: boolean = true;
        hidden: boolean = false;
        hiddenThens: Dsoft.GeneralHelper.Then<any>[] = [];
        $articleContent :JQuery;
        get selectedArticleId(): number {
            return this.articlesViewmodel.selectedArticleViewmodel() != null ? this.articlesViewmodel.selectedArticleViewmodel().id() : null;
        }

        $selectedArticle: JQuery;
        $selectedPerush: JQuery;
        $syncView: JQuery;
        $syncViewReadableSection: JQuery;
        articlesViewmodel: ArticlesViewmodel;
        perekViewmodel: PerekViewModel;
        perekTextViewmodel: PerekTextViewModel;
        perushimViewmodel: PerushimViewmodel;

        constructor(articleViewModels: ArticleViewmodel[], selectedArticleViewModel: ArticleViewmodel, perushViewModels: PerushViewmodel[], selectedPerushViewModel: PerushViewmodel) {
            this.init(articleViewModels, selectedArticleViewModel, perushViewModels, selectedPerushViewModel);
        }
        init(articleViewModels: ArticleViewmodel[], selectedArticleViewModel: ArticleViewmodel, perushViewModels: PerushViewmodel[], selectedPerushViewModel: PerushViewmodel) {
            if (ResourcesPool_V2.ViewPool.isPhone) {
                $('#goToBookBtnContainer').detach();
            }

            this.perekViewmodel = new PerekViewModel();
            this.perekTextViewmodel = new PerekTextViewModel(this.perekViewmodel);

            this.perushimViewmodel = new PerushimViewmodel(this.perekViewmodel, perushViewModels, selectedPerushViewModel);
            this.articlesViewmodel = new ArticlesViewmodel(this.perekViewmodel, articleViewModels, selectedArticleViewModel);

            this.$syncView = $('#syncView');
            this.$articleContent = this.$syncView.find('.article-content');
            this.$selectedArticle = this.$syncView.find('.selected-article');
            this.$selectedPerush = this.$syncView.find('.selected-perush');
            this.$syncViewReadableSection = this.$syncView.children().first();
            this.attachEvents();

            ko.applyBindings(this, this.$syncView[0]);
        }

        attachEvents() {
            if (!ResourcesPool_V2.ViewPool.isPhone) {
                this.$syncView.on('click', (e) => {
                    if (this.$syncView.is(e.target))
                        this.hide();
                });

                $('#goToBookBtnContainer').bind("click", (e: JQueryMouseEventObject) => {
                    this.hide();
                });
            }
            this.$syncView.find('.next-btn').bindToIntuitiveClick(() => {
                this.perekViewmodel.loadNext().then(this.processPerek.bind(this));
            });
            this.$syncView.find('.previous-btn').bindToIntuitiveClick(() => {
                this.perekViewmodel.loadPrevious().then(this.processPerek.bind(this));;
            });
            this.$syncView.find('.today-btn').bindToIntuitiveClick(() => {
                this.perekViewmodel.loadByDateTime().then(this.processPerek.bind(this));;
            });
        }
        hide(): Promise<any> {
            return new Promise<any>((resolve: (value?: any | PromiseLike<any>) => void, reject: (reason?: any) => void) => {
                this.$syncViewReadableSection.slideUp(300);
                setTimeout(this.$syncView.fadeOut.bind(this.$syncView), 300);
                this.hidden = true;
                resolve();
            }).thens(this.hiddenThens);
        }

        loadToday() {
            this.perekViewmodel.loadByDateTime().then(this.processPerek.bind(this));
        }
        load(perekId: number, articleId?: number, perushId?: number): Promise<SyncView> {
            if (this.hidden) {
                this.show(true);
            }
            return new Promise<SyncView>((resolve: (value?: SyncView | PromiseLike<SyncView>) => void, reject: (reason?:any) => void) => {
                this.perekViewmodel.loadByPerekId(perekId).then(
                    (perekViewModel: PerekViewModel) => {
                        this.processPerek(perekViewModel, articleId, perushId).then(() => {
                            resolve(this)
                        });
                    }
                );
            });
        }
        scrollTop() {
            this.$syncViewReadableSection.animate({ scrollTop: 0 }, "slow");
        }
        scrollToSelectedPerush() {
            $.scrollWithJqueryAnim(this.$syncViewReadableSection, this.$selectedPerush);
        }
        scrollToSelectedArticle() {
            $.scrollWithJqueryAnim(this.$syncViewReadableSection, this.$selectedArticle);
        }
        scrollToPasuk(pasukNum: number) {
            $.scrollWithJqueryAnim(this.$syncViewReadableSection, $('#syncViewPasuk_' + pasukNum));
        }
        scrollToPasukPerush(pasukNum: number) {
            $.scrollWithJqueryAnim(this.$syncViewReadableSection, $('#syncViewPerushPasuk_' + pasukNum));
        }
        show(withEffects: boolean = true) {
            if (withEffects) {
                this.$syncView.fadeIn(300)
                setTimeout($.proxy(function () {
                    this.$syncViewReadableSection.slideDown();
                }, this), 300);
            } else {
                this.$syncView.fadeIn(0);
                this.$syncViewReadableSection.slideDown(0);
            }
            this.hidden = false;
        }
        showArticleById(articleId: number) {
            this.articlesViewmodel.articleViewmodels().forEach((value: ArticleViewmodel, index: number, array: ArticleViewmodel[]) => {
                if (value.id() == articleId)
                    this.showArticle(value);
            });
        }
        showArticle(articleVm: ArticleViewmodel) {
            this.articlesViewmodel.selectedArticleViewmodel(articleVm);
            var articleVm = this.articlesViewmodel.selectedArticleViewmodel();
            articleVm.loadContent();
            this.scrollToSelectedArticle();
            var callback: () => Promise<any> = this.historyCallback.bind(this, articleVm.perekId(), articleVm.id());
            ResourcesPool_V2.ViewPool.indexView.addressLineMgr.changeState(new AddressLineState(articleVm.perekId(), articleVm.id(), undefined, callback, true));
            Dsoft.View.changePageTitle('תנ"ך על הפרק - ' + this.perekViewmodel.perekSource() + " - " + $('<div>').html(articleVm.abstract()).text() + " / " + articleVm.author().name());
        }
        showPerush(perushVm: PerushViewmodel) {
            this.perushimViewmodel.selectedPerushViewmodel(perushVm);
            perushVm.load();
            this.scrollToSelectedPerush();
            var callback: () => Promise<any> = this.historyCallback.bind(this, perushVm.perekViewModel.perekId(), null, perushVm.id());
            ResourcesPool_V2.ViewPool.indexView.addressLineMgr.changeState(new AddressLineState(perushVm.perekViewModel.perekId(), undefined, perushVm.hebName(), callback, true));
            Dsoft.View.changePageTitle('תנ"ך על הפרק - ' + this.perekViewmodel.perekSource() + " - " + perushVm.hebName() + " / " + perushVm.parshan.name() + (typeof perushVm.parshan2 !== 'undefined' ? " & " + perushVm.parshan2.name() : ''));
        }
        showPerushById(perushId: number) {
            this.perushimViewmodel.perushViewmodels().forEach($.proxy(function (value: PerushViewmodel, index: number, array: PerushViewmodel[]) {
                if (value.id() == perushId)
                    this.showPerush(value);
            }, this));
        }
        historyCallback(perekId: number, articleId?: number, perushId?: number): Promise<any> {
            return new Promise((resolve: (value?: any | PromiseLike<any>) => void, reject: (reason?: any) => void) => {
                this.load(perekId, articleId, perushId).then((syncView: SyncView) => {
                    resolve();
                });
            });
        }
        onArticleAbstractClick(articleVm: ArticleViewmodel, event: MouseEvent) {
            var callback: Function = $.proxy(function () {
                this.context.showArticle(this.articleVm)
            }, { context: this, articleVm: articleVm });
            Dsoft.Ajax.intuitiveClick(event, callback);
            // an horrible knockout bug:
            event.preventDefault = undefined;
            event.stopPropagation = undefined;
        }
        onPerushAbstractClick(perushVm: PerushViewmodel, event: MouseEvent) {
            var callback: Function = $.proxy(function () {
                this.context.showPerush(this.perushVm)
            }, { context: this, perushVm: perushVm });
            Dsoft.Ajax.intuitiveClick(event, callback);
            // an horrible knockout bug:
            event.preventDefault = undefined;
            event.stopPropagation = undefined;
        }
        processPerek(perekViewModel: PerekViewModel, articleId?: number, perushId?: number): Promise<any> {
            GlobalSettings.log("sync-view.ts: perekLoaded(perekId:" + perekViewModel.perekId() + ")", GlobalSettings.LogLevel.debugWithStack);
            var promises: Promise<any>[] = [];

            var processPerekTextPromise = this.perekTextViewmodel.load().then(this.processPerekText.bind(this));
            var processPerushimPromise = this.perushimViewmodel.load(perekViewModel).then(this.processPerekPerushim.bind(this));
            promises.push(processPerekTextPromise, processPerushimPromise);

            if (!this.firstLoad) {
                var articlesLoadedPromise = this.articlesViewmodel.load(perekViewModel.perekId());
                promises.push(articlesLoadedPromise);
            }

            if (typeof articleId !== 'undefined' && articleId != null) {
                if (this.firstLoad) {
                    this.showArticle(this.articlesViewmodel.selectedArticleViewmodel());
                } else {
                    articlesLoadedPromise.then(this.showArticleById.bind(this, articleId));
                }
            } else if (typeof perushId !== 'undefined' && perushId != null) {
                if (this.firstLoad) {
                    this.showPerush(this.perushimViewmodel.selectedPerushViewmodel());
                } else {
                    processPerushimPromise.then(this.showPerushById.bind(this, perushId));
                }
            } else {
                if (!this.firstLoad) {
                    $(document).prop('title', 'תנ"ך על הפרק - ' + this.perekViewmodel.perekSource());
                    this.articlesViewmodel.selectedArticleViewmodel(null);
                }
                this.scrollTop();
                var callback: () => Promise<any> = this.historyCallback.bind(this, perekViewModel.perekId());
                ResourcesPool_V2.ViewPool.indexView.addressLineMgr.changeState(new AddressLineState(perekViewModel.perekId(), undefined, undefined, callback, true));
            }
            this.firstLoad = false;
            return Promise.all(promises);
        }
        processPerekText(perekText: string) {
            //return new Promise((resolve: (value?: any | PromiseLike<any>) => void, reject: (reason?: any) => void) => {
            this.perekTextViewmodel.$perekText.find('.pasuk-num').each(function (index: number, elem: Element) {
                var $pasukNumA = $(elem);
                $pasukNumA.attr('id', 'syncViewPasuk_' + (index + 1)).data('dest', '#syncViewPerushPasuk_' + (index + 1));
            });
            this.perekTextViewmodel.textAsHtml(this.perekTextViewmodel.$perekText.html());

            this.$syncViewReadableSection.find('.perek-text-section .pasuk-num a').each($.proxy(function (index: number, elem: Element) {
                var $pasukNumAnchor = $(elem);
                $pasukNumAnchor.bindToIntuitiveClick($.proxy(this.scrollToPasukPerush, this, $pasukNumAnchor.data('pasuk-num')));
            }, this));
            //    resolve();
            //});
        }
        processPerekPerushim(perushViewmodels: ko.ObservableArray<PerushViewmodel>): void {
            //return new Promise((resolve: (value?: any | PromiseLike<any>) => void, reject: (reason?: any) => void) => {
            perushViewmodels().forEach($.proxy(function (value: PerushViewmodel, index: number, array: PerushViewmodel[]) {
                value.perushLoadedEventHandler.register(function perushLoaded(sender: any, e: { data: string }) {
                    var perushVm = <PerushViewmodel>sender;
                    perushVm.$perushText.find('.pasuk-num').each(function (index: number, elem: Element) {
                        var $pasukNum = $(elem);
                        var n: number = <number>$pasukNum.data('pasuk-num');
                        var $pasukNumA = $('<a>', { 'id': 'syncViewPerushPasuk_' + n, 'data-dest': '#syncViewPasuk_' + n, 'data-pasuk-num': n }).html($pasukNum.html().trim());
                        $pasukNum.html('').append($pasukNumA, '.', '&nbsp;');
                    });
                    perushVm.html(perushVm.$perushText.html());

                    this.$syncViewReadableSection.find('.selected-perush .pasuk-num a').each($.proxy(function (index: number, elem: Element) {
                        var $pasukNumAnchor = $(elem);
                        $pasukNumAnchor.bindToIntuitiveClick($.proxy(this.scrollToPasuk, this, $pasukNumAnchor.data('pasuk-num')));
                    }, this));
                }, this);
            }, this));
            //});
        }
    }
}