module Dsoft {
    export module DomHelper {
        export function removeWhitespacesNodesOf(node: Node): void {
            var nodeChilds = node.childNodes;
            var N = Number(nodeChilds.length);
            for (var i = N; i >= 0; i--) {
                if (((typeof nodeChilds[i]) !== 'undefined') && nodeChilds[i].nodeType == 3) {
                    node.removeChild(nodeChilds[i]);
                }
            }
        }
        export function concatNeighboursOfSameClass(node: Node, separator: string = " ") {
            if (node.childNodes.length < 2) return;
            for (var tmpNode = node.childNodes[0]; tmpNode != null; ) {
                var $tmpNode = $(tmpNode);
                var nextNode = tmpNode.nextSibling;
                var $nextNode = $(nextNode);
                if ($tmpNode.hasClass($nextNode.attr('class'))) { // consider use nextNode.classList.join(" ")
                    $tmpNode.html($tmpNode.html() + separator + $nextNode.html());
                    node.removeChild(nextNode);
                } else {
                    tmpNode = <ChildNode>tmpNode.nextSibling;
                }
            }
        }
    }
}